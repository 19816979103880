import { DatePicker, DatePickerProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import formatNumber from "src/Commom/FormatNumber";
import Loading from "src/Commom/Loading";
import { reportGeneral } from "src/Components/Features/user/userSlice";
import { selectLoading } from "src/Components/Redux/selector";
import { useAppDispatch } from "src/Components/Redux/store";

export default function Accumulative() {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [reportDailyBonus, setReportDailyBonus] = useState([]);
  const [reportAccumulative, setReportAccumulative] = useState([]);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    getGeneral();
  }, []);

  const getGeneral = async () => {
    const params = {};
    const resp: any = await dispatch(reportGeneral(params));
    console.log("resp report-----", resp);
    setReportDailyBonus(resp?.payload?.reportDailyBonus);
    setReportAccumulative(resp?.payload?.reportAccumulative);
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (item, r, index) => <div>{index + 1}</div>,
    },
    {
      title: "Ngày",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{`${item?._id?.day}/${item?._id?.month}/${item?._id?.year}`}</div>
      ),
    },
    {
      title: "Tổng trả thưởng",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{formatNumber(item?.totalBonus)}đ</div>
      ),
    },
    {
      title: "Người giới thiệu",
      dataIndex: "",
      key: "3",
      render: (item) => (
        <div className="fw-semibold">
          {formatNumber(item?.totalBonusForRef)}đ
        </div>
      ),
    },
  ];

  const columns2: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (item, r, index) => <div>{index + 1}</div>,
    },
    {
      title: "Gói tích luỹ",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{`${item?._id?.timeDeposit}`} tháng</div>
      ),
    },
    {
      title: "Số lượng gói",
      dataIndex: "",
      key: "2",
      render: (item) => <div className="fw-semibold">{item?.total}</div>,
    },
    {
      title: "Gói đang mở",
      dataIndex: "",
      key: "3",
      render: (item) => <div className="fw-semibold">{item?.total}</div>,
    },
    {
      title: "Tổng tích luỹ",
      dataIndex: "",
      key: "3",
      render: (item) => (
        <div className="fw-semibold">{formatNumber(item?.totalAmount)}đ</div>
      ),
    },
    {
      title: "Tổng tích luỹ đang mở",
      dataIndex: "",
      key: "3",
      render: (item) => (
        <div className="fw-semibold">
          {formatNumber(item?.totalAmountOpen)}đ
        </div>
      ),
    },
  ];

  return (
    <div className="p-3">
      <div className="fw-semibold fs-4 pb-3">Danh sách trả thưởng</div>

      {isLoading ? (
        <Loading />
      ) : (
        <Table columns={columns} dataSource={reportDailyBonus} />
      )}
      <div className="fw-semibold fs-4 pt-5 pb-3">Danh sách tích luỹ</div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table columns={columns2} dataSource={reportAccumulative} />
      )}
    </div>
  );
}
