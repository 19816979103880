import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Screens/Login";
import Home from "./Screens/Home";
import Order from "./Screens/Order";
import MenuLeft from "./Screens/MenuLeft";
import Header from "./Screens/Header";
import { user_infor } from "./Components/Storage";
import Category from "./Screens/Category";
import Product from "./Screens/Product";
import Customer from "./Screens/Customer";
import TeamModel from "./Screens/TeamModel";
import Manager from "./Screens/Manager";
import Promo from "./Screens/Promo";
import News from "./Screens/News";
import Investment from "./Screens/Investment";
import Accumulative from "./Screens/Accumulative";
import Bank from "./Screens/Bank";
import Wallet from "./Screens/Wallet";
import ReportAccumulative from "./Screens/Report/Accumulative";
import ReportCustomer from "./Screens/Report/Customer";
import ReportOrder from "./Screens/Report/Order";
import ReportProduct from "./Screens/Report/Product";
import ReportWallet from "./Screens/Report/Wallet";
import Config from "./Screens/Config";
import { useSelector } from "react-redux";
import { userInfor } from "./Components/Redux/selector";
import CustomerDetail from "./Screens/Customer/Screens/CustomerDetail";
import ProductEdit from "./Screens/Product/Screens/ProductEdit";
import ProductAdd from "./Screens/Product/Screens/ProductAdd";
import OrderDetail from "./Screens/Order/Screens/OrderDetail";
import WalletDetail from "./Screens/Wallet/Screens/WalletDetail";
import AddNews from "./Screens/News/Screens/AddNews";
import Edit from "./Screens/Manager/Screens/Edit";
import ManagerAdd from "./Screens/Manager/Screens/Add";
import "./App.css";
import Cookies from "js-cookie";
import { setClientToken } from "./Controller/Client";
import UpdateNews from "./Screens/News/Screens/UpdateNews";

export default function App() {
  const userInfo = useSelector(userInfor);
  const accessToken = Cookies.get("access_token");

  if (accessToken) {
    setClientToken(accessToken);
  }

  return (
    <div className="container-app">
      {userInfo?.accessToken || accessToken ? (
        <>
          <Header />
          <div className="row mt-2 mb-4">
            <MenuLeft />
            <main className="col-md-10">
              <Routes>
                <Route index path="/home" element={<Home />} />
                <Route index path="/order" element={<Order />} />
                <Route index path="/order/detail" element={<OrderDetail />} />
                <Route index path="/category" element={<Category />} />
                <Route index path="/product" element={<Product />} />
                <Route index path="/product/update" element={<ProductEdit />} />
                <Route index path="/product/add" element={<ProductAdd />} />
                <Route index path="/customer" element={<Customer />} />
                <Route
                  index
                  path="/customer/detail"
                  element={<CustomerDetail />}
                />
                <Route index path="/team-model" element={<TeamModel />} />
                <Route index path="/manager" element={<Manager />} />
                <Route index path="/promo" element={<Promo />} />
                <Route index path="/news" element={<News />} />
                <Route index path="/news/add" element={<AddNews />} />
                <Route index path="/news/update" element={<UpdateNews />} />
                <Route index path="/investment" element={<Investment />} />
                <Route index path="/accumulative" element={<Accumulative />} />
                <Route index path="/bank" element={<Bank />} />
                <Route index path="/wallet" element={<Wallet />} />
                <Route index path="/wallet/detail" element={<WalletDetail />} />
                <Route
                  index
                  path="/report/accumulative"
                  element={<ReportAccumulative />}
                />
                <Route
                  index
                  path="/report/customer"
                  element={<ReportCustomer />}
                />
                <Route index path="/report/order" element={<ReportOrder />} />
                <Route
                  index
                  path="/report/product"
                  element={<ReportProduct />}
                />
                <Route index path="/report/wallet" element={<ReportWallet />} />
                <Route index path="/config" element={<Config />} />
                <Route index path="/manager/edit" element={<Edit />} />
                <Route index path="/manager/add" element={<ManagerAdd />} />
              </Routes>
            </main>
          </div>
        </>
      ) : (
        <Routes>
          <Route index path="/*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
}
