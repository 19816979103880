import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import formatNumber from "src/Commom/FormatNumber";
import { transactionDetail } from "src/Components/Features/wallet/WalletSlice";

export default function WalletDetail() {
  const dispatch = useDispatch<any>();
  const { state } = useLocation();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const resp: any = await dispatch(transactionDetail(state.id));
    console.log("resp", resp);
    setData(resp?.payload?.transaction);
  };

  const getType = (type: string) => {
    switch (type) {
      case "payCommissions":
        return "Trả thưởng hoa hồng";
      case "Deposit":
        return "Nạp tiền";
      case "WithDraw":
        return "Rút tiền";
      default:
        break;
    }
  };
  return (
    <div className="p-4">
      <div className="fw-bolder fs-4">Chi tiết giao dịch</div>
      <div className="row mt-4">
        <div className="col-md-6 col-12 bg-white rounded shadow p-4">
          <div className="fw-bolder fs-4 pb-0">Thông tin giao dịch</div>
          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Mã giao dịch:</div>
            <div className="fw-bolder">{data?.transactionCode}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Loại giao dịch:</div>
            <div className="fw-bolder">{getType(data?.type)}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tài khoản nguồn:</div>
            <div>
              <div className="fw-bolder">{data?.userInfo?.fullName}</div>
              <div className="fw-bolder">{data?.userInfo?.email}</div>
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tài khoản đích:</div>
            <div>
              <div className="fw-bolder">{data?.bankCode}</div>
              <div className="fw-bolder">{data?.bankNumber}</div>
              <div className="fw-bolder">{data?.accountName}</div>
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Nội dung:</div>
            <div className="fw-bolder">{data?.content}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tình trạng giao dịch:</div>
            <div className="fw-bolder">{data?.status}</div>
          </div>

          <div className="fw-bolder fs-4 pb-0">Thời gian</div>
          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Ngày tạo:</div>
            <div className="fw-bolder">
              {moment(data?.createdAt).format("DD/MM/YYYY hh:mm")}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Ngày cập nhật:</div>
            <div className="fw-bolder">
              {moment(data?.dayApproved).format("DD/MM/YYYY hh:mm")}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Người xác nhận:</div>
            <div>
              <div className="fw-bolder">{data?.userApproved?.fullName}</div>
              <div className="fw-bolder">{data?.userApproved?.email}</div>
            </div>
          </div>
        </div>

        <div className="col-md-5 col-12 bg-white rounded shadow p-4 ms-4">
          <div className="fw-bolder fs-4 pb-0">Giá trị giao dịch</div>
          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Số tiền:</div>
            <div className="fw-bolder">{formatNumber(data?.total)}đ</div>
          </div>
          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tiền phí:</div>
            <div className="fw-bolder">{formatNumber(data?.withdrawFee)}đ</div>
          </div>
        </div>
      </div>
    </div>
  );
}
