import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userInterface } from "src/Constant/Interface";
import { setClientToken } from "src/Controller/Client";
import Users from "src/Controller/Users";

const userInitialState: any = {
  dataLogin: {},
};

export const login = createAsyncThunk(
  "user/login",
  async (params: userInterface) => {
    const resp = await Users.login(params);
    return resp;
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (params: userInterface) => {
    const resp = await Users.forgotPassword(params);
    return resp;
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (params: userInterface) => {
    const resp = await Users.register(params);
    return resp;
  }
);

export const verifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async (params: userInterface) => {
    const resp = await Users.verifyEmail(params);
    return resp;
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (params: userInterface) => {
    const resp = await Users.updateProfile(params);
    return resp;
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (params: userInterface) => {
    const resp = await Users.changePassword(params);
    return resp;
  }
);

export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async (params: userInterface) => {
    const resp = await Users.refreshToken(params);
    return resp;
  }
);

export const gennerateRef = createAsyncThunk("user/gennerateRef", async () => {
  const resp = await Users.gennerateRef();
  return resp;
});

export const detailAdmin = createAsyncThunk("user/detailAdmin", async () => {
  const resp = await Users.detailAdmin();
  return resp;
});

export const uploadImage = createAsyncThunk(
  "user/uploadImage",
  async (params: any) => {
    const resp = await Users.uploadImage(params);
    return resp;
  }
);

export const listBankVN = createAsyncThunk("user/listBank", async () => {
  const resp = await Users.listBank();
  return resp;
});

export const reportGeneral = createAsyncThunk(
  "user/reportGeneral",
  async (params: any) => {
    const resp = await Users.reportGeneral(params);
    return resp;
  }
);

const user = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setUserLogin(state, payload) {
      state.dataLogin = payload;
    },
    logOut: (state) => {
      state.dataLogin = {};
      setClientToken(null);
    },
  },
});

const { actions, reducer } = user;
export const { setUserLogin, logOut } = actions;
export default reducer;
