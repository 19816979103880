import React, { useEffect, useState } from "react";
import { Dropdown, Input, MenuProps, Pagination, Select, Table } from "antd";
import { SearchProps } from "antd/es/input";
import moment from "moment";
import formatNumber from "src/Commom/FormatNumber";
import { ColumnsType } from "antd/es/table";
import { DataType } from "src/Constant/Interface";
import { useAppDispatch } from "src/Components/Redux/store";
import {
  AdminList,
  AdminLock,
  AdminResetPassword,
  AdminUnlock,
} from "src/Components/Features/Admin/adminSlice";
import { CheckOutlined, LockOutlined, MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Manager() {
  const { Search } = Input;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);

  useEffect(() => {
    getData(1, "", "");
  }, []);

  const getData = async (page: number, type: string, keyword: string) => {
    const params = {
      limit: 10,
      page: page,
    };

    const searchKeyword = {
      limit: 10,
      page: page,
      search: {
        keyword: keyword,
      },
    };

    const searchType = {
      limit: 10,
      page: page,
      search: {
        type: type === "Quản lý" ? "Manager" : "Staff",
      },
    };

    const resp: any = await dispatch(
      AdminList(keyword ? searchKeyword : type ? searchType : params)
    );
    console.log("resp", resp);
    setData(resp?.payload?.users);
  };

  const onChange = (page: number) => {
    getData(page, "", "");
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(value);
    getData(1, "", value);
  };

  const provinceData = ["Nhân viên", "Quản lý"];

  const handleProvinceChange = (value: any) => {
    console.log("value", value);
    getData(1, value, "");
  };

  const onResetPassword = async () => {
    const resp: any = await dispatch(AdminResetPassword({ id: chooseItem }));
    // console.log("resp reset pass----", resp);
    if (resp?.payload?.status === true) {
      toast.success(resp?.payload?.message);
    }
  };

  const onLockAcc = async () => {
    const resp: any = await dispatch(AdminLock({ id: chooseItem }));
    console.log("resp lock----", resp);
    if (resp?.payload?.status === true) {
      toast.success(resp?.payload?.message);
      getData(1, "", "");
    }
  };

  const onUnLockAcc = async () => {
    const resp: any = await dispatch(AdminUnlock({ id: chooseItem }));
    console.log("resp unLock----", resp);
    if (resp?.payload?.status === true) {
      toast.success(resp?.payload?.message);
      getData(1, "", "");
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "0",
      render: (text: any, index: any, e) => {
        return <div className="fw-semibold">{e + 1}</div>;
      },
    },
    {
      title: "Nhân viên",
      dataIndex: "",
      key: "1",
      render: (item: any) => {
        return (
          <div>
            <div className="fw-bolder">{item?.fullName}</div>
            <div className="text-primary">{item?.email}</div>
          </div>
        );
      },
    },
    {
      title: "Loại tài khoản",
      dataIndex: "type",
      key: "2",
      render: (text: any) => {
        return (
          <div className="fw-bolder">
            {text == "Manager" ? "Quản lý" : "Nhân viên"}
          </div>
        );
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "isLock",
      key: "3",
      render: (text: any) => {
        return (
          <div>
            {text == false ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <LockOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Đăng nhập",
      dataIndex: "lastLogin",
      key: "2",
      render: (text: any) => {
        return <div>{moment(text).format("DD/MM/YYYY hh:mm:ss")}</div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "2",
      render: (text: any) => {
        return <div>{moment(text).format("DD/MM/YYYY hh:mm:ss")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "_id",
      key: "9",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/manager/edit", { state: { id: chooseItem } })
          }
          className="btn border border-0 text-primary"
        >
          Chi tiết & cập nhật
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => onResetPassword()}
          className="btn border border-0 text-warning"
        >
          Đặt lại mật khẩu
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button onClick={onLockAcc} className="btn border border-0 text-danger">
          Khoá
        </button>
      ),
      key: "2",
    },
    {
      label: (
        <button
          onClick={onUnLockAcc}
          className="btn border border-0 text-success"
        >
          Mở khoá
        </button>
      ),
      key: "3",
    },
  ];

  return (
    <div className="p-4">
      <div className="fw-semibold fs-5">Danh sách nhân viên</div>
      <div className="border border-1 w-100 my-3" />
      <button
        className="btn border border-1 rounded-3 border-primary text-primary"
        onClick={() => navigate("/manager/add")}
      >
        + Thêm mới
      </button>
      <div className="d-flex justify-content-between align-items-center py-3">
        <div className="fw-semibold fs-5 d-none d-md-block">Bộ lọc</div>
        <div className="d-flex align-items-center gap-3">
          <Select
            style={{ width: 140 }}
            placeholder="Loại tài khoản"
            onChange={handleProvinceChange}
            options={provinceData.map((province) => ({
              label: province,
              value: province,
            }))}
          />
          <Search
            placeholder="Tìm kiếm nhân viên"
            onSearch={onSearch}
            enterButton
          />
        </div>
      </div>

      <div className="d-none d-md-block">
        <Table columns={columns} dataSource={data} pagination={false} />
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Pagination defaultCurrent={1} onChange={onChange} total={1000} />
        </div>
      </div>
      <div className="d-block d-md-none">
        {data.map((item: any, index: number) => {
          return (
            <div
              key={item?._id}
              className="p-3 d-grid gap-3 border-2 border-bottom"
            >
              <div className="fw-semibold">#{index + 1}</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Nhân viên</div>
                <div>
                  <div className="fw-bolder">{item?.fullName}</div>
                  <div className="text-primary">{item?.email}</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Loại tài khoản</div>
                <div className="fw-bolder">
                  {item?.type === "Manager" ? "Quản lý" : "Nhân viên"}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Tình trạng</div>
                <div>
                  {!item?.isLock ? (
                    <CheckOutlined style={{ color: "green" }} />
                  ) : (
                    <LockOutlined style={{ color: "red" }} />
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Đăng nhập</div>
                <div>
                  {moment(item?.lastLogin).format("DD/MM/YYYY hh:mm:ss")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Ngày tạo</div>
                <div>
                  {moment(item?.createdAt).format("DD/MM/YYYY hh:mm:ss")}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Thao tác</div>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <button
                    className="btn border border-0 fw-bolder text-primary"
                    onClick={() => setChooseItem(item?._id)}
                  >
                    <MoreOutlined />
                  </button>
                </Dropdown>
              </div>
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Pagination defaultCurrent={1} onChange={onChange} total={1000} />
        </div>
      </div>
    </div>
  );
}
