import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Customers from "src/Controller/Customers";

const CustomerInitialState: any = {

};

export const customerList = createAsyncThunk(
    "customer/customerList",
    async (params: any) => {
        const resp = await Customers.customerList(params);
        return resp;
    }
);

export const customerUpdate = createAsyncThunk(
    "customer/customerUpdate",
    async (params: any) => {
        const resp = await Customers.customerUpdate(params);
        return resp;
    }
);

export const customerUpdateAmount = createAsyncThunk(
    "customer/customerUpdateAmount",
    async (params: any) => {
        const resp = await Customers.customerUpdateAmount(params);
        return resp;
    }
);

export const customerChangeEmail = createAsyncThunk(
    "customer/customerChangeEmail",
    async (params: any) => {
        const resp = await Customers.customerChangeEmail(params);
        return resp;
    }
);

export const customerChangePassword = createAsyncThunk(
    "customer/customerChangePassword",
    async (params: any) => {
        const resp = await Customers.customerChangePassword(params);
        return resp;
    }
);

export const customerDetail = createAsyncThunk(
    "customer/customerDetail",
    async (params: any) => {
        const resp = await Customers.customerDetail(params);
        return resp;
    }
);

export const customerLock = createAsyncThunk(
    "customer/customerLock",
    async (params: any) => {
        const resp = await Customers.customerLock(params);
        return resp;
    }
);

export const customerUnLock = createAsyncThunk(
    "customer/customerUnLock",
    async (params: any) => {
        const resp = await Customers.customerUnLock(params);
        return resp;
    }
);

export const customerOpenAccount = createAsyncThunk(
    "customer/customerOpenAccount",
    async (params: any) => {
        const resp = await Customers.customerOpenAccount(params);
        return resp;
    }
);

export const customerTeamModel = createAsyncThunk(
    "customer/customerTeamModel",
    async (params: any) => {
        const resp = await Customers.customerTeamModel(params);
        return resp;
    }
);

export const customerTeamModelSearch = createAsyncThunk(
    "customer/customerTeamModelSearch",
    async (params: any) => {
        const resp = await Customers.customerTeamModelSearch(params);
        return resp;
    }
);

export const customerUpdateAffiLevel = createAsyncThunk(
    "customer/customerUpdateAffiLevel",
    async (params: any) => {
        const resp = await Customers.customerUpdateAffiLevel(params);
        return resp;
    }
);

export const customerHistoryUpdate = createAsyncThunk(
    "customer/customerHistoryUpdate",
    async (params: any) => {
        const resp = await Customers.customerHistoryUpdate(params);
        return resp;
    }
);

const customer = createSlice({
    name: "customer",
    initialState: CustomerInitialState,
    reducers: {

    },
});

const { actions, reducer } = customer;
export default reducer;