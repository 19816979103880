import client from "./Client";

const Wallet = {
  async getWalletAll() {
    try {
      const res = await client.get(`/wallet/create-all`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async transactionList(params: any) {
    try {
      const res = await client.post(`/wallet/get-list-transaction`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async exportExcel(params: any) {
    try {
      const res = await client.post(`/wallet/export-excel`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async transactionAccepted(params: any) {
    try {
      const res = await client.post(`/wallet/transaction-accepted`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async transactionCancel(params: any) {
    try {
      const res = await client.post(`/wallet/transaction-cancel`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async transactionDetail(id: string) {
    try {
      const res = await client.get(`/wallet/transaction-detail/${id}`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async createBank(params: any) {
    try {
      const res = await client.post(`/wallet/bank-create`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async updateBank(params: any) {
    try {
      const res = await client.post(`/wallet/bank-update`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async deleteBank(params: any) {
    try {
      const res = await client.post(`/wallet/bank-delete`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async listBank() {
    try {
      const res = await client.get(`/wallet/bank-list`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async walletExportExcel(params: any) {
    try {
      const res = await client.post(`/wallet/report-transaction`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },
};

export default Wallet;
