import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Order from "src/Controller/Order";

const OrderInitialState: any = {

};

export const orderList = createAsyncThunk(
    "order/orderList",
    async (params: any) => {
        const resp = await Order.orderList(params);
        return resp;
    }
);

export const orderExportExcel = createAsyncThunk(
    "order/orderExportExcel",
    async (params: any) => {
        const resp = await Order.orderExportExcel(params);
        return resp;
    }
);

export const orderDetail = createAsyncThunk(
    "order/orderDetail",
    async (id: string) => {
        const resp = await Order.orderDetail(id);
        return resp;
    }
);

export const orderUpdate = createAsyncThunk(
    "order/orderUpdate",
    async (params: any) => {
        const resp = await Order.orderUpdate(params);
        return resp;
    }
);

export const orderDelete = createAsyncThunk(
    "order/orderDelete",
    async (params: any) => {
        const resp = await Order.orderDelete(params);
        return resp;
    }
);

export const orderCalcel = createAsyncThunk(
    "order/orderCalcel",
    async (params: any) => {
        const resp = await Order.orderCalcel(params);
        return resp;
    }
);

export const orderPaymentConfirmation = createAsyncThunk(
    "order/orderPaymentConfirmation",
    async (params: any) => {
        const resp = await Order.orderPaymentConfirmation(params);
        return resp;
    }
);

export const orderConfirm = createAsyncThunk(
    "order/orderConfirm",
    async (params: any) => {
        const resp = await Order.orderConfirm(params);
        return resp;
    }
);

export const orderPaymentCancel = createAsyncThunk(
    "order/orderPaymentCancel",
    async (params: any) => {
        const resp = await Order.orderPaymentCancel(params);
        return resp;
    }
);

export const orderDelivetyConfirm = createAsyncThunk(
    "order/orderDelivetyConfirm",
    async (params: any) => {
        const resp = await Order.orderDelivetyConfirm(params);
        return resp;
    }
);

export const orderDelivetySuccess = createAsyncThunk(
    "order/orderDelivetySuccess",
    async (params: any) => {
        const resp = await Order.orderDelivetySuccess(params);
        return resp;
    }
);

export const orderDelivetyCancel = createAsyncThunk(
    "order/orderDelivetyCancel",
    async (params: any) => {
        const resp = await Order.orderDelivetyCancel(params);
        return resp;
    }
);

const order = createSlice({
    name: "order",
    initialState: OrderInitialState,
    reducers: {

    },
});

const { actions, reducer } = order;
export default reducer;