import client from "./Client";

const Accumulation = {

    async createAccumulative(params: any) {
        try {
            const res = await client.post(`/accumulative/investment-create`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async listAccumulative(params: any) {
        try {
            const res = await client.post(`/accumulative/investment-list`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async updateAccumulative(params: any) {
        try {
            const res = await client.post(`/accumulative/investment-update`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async deleteAccumulative(params: any) {
        try {
            const res = await client.post(`/accumulative/investment-delete`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async allListAccumulative(params: any) {
        try {
            const res = await client.post(`/accumulative/accumulative-list`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async createAllAccumulative(params: any) {
        try {
            const res = await client.post(`/accumulative/create-all`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },



}

export default Accumulation