import client from "./Client";

const Manager = {
  async adminList(params: any) {
    try {
      const res = await client.post(`/admin/list`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async adminUpdate(params: any) {
    try {
      const res = await client.post(`/admin/update`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async adminCreate(params: any) {
    try {
      const res = await client.post(`/admin/create`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async adminDetail(id: string) {
    try {
      const res = await client.get(`/admin/detail${id}`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async adminLock(params: any) {
    try {
      const res = await client.post(`/admin/lock`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async adminResetPassword(params: any) {
    try {
      const res = await client.post(`/admin/reset-pasword`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async adminUnLock(params: any) {
    try {
      const res = await client.post(`/admin/un-lock`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async configDetail() {
    try {
      const res = await client.get(`/config/detail`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async configUpdateFeeWithDraw(params: any) {
    try {
      const res = await client.post(`/config/update-fee-width-draw`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async privateListUser(params: any) {
    try {
      const res = await client.post(`/api/private/list-user`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async exportDataDeputy() {
    try {
      const res = await client.get(
        `/customers/export-data-deputy-general-manager`
      );
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },
};

export default Manager;
