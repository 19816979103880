import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Colors from "src/Commom/Colors";
import formatNumber from "src/Commom/FormatNumber";
import {
  customerDetail,
  customerHistoryUpdate,
} from "src/Components/Features/customer/CustomerSlice";

export default function CustomerDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>(null);
  const [dataHistoryUpdate, setDataHistoryUpdate] = useState([]);

  useEffect(() => {
    getDetail();
    getHistoryUpdate();
  }, []);

  const getDetail = async () => {
    const resp: any = await dispatch(customerDetail(state?.id));
    console.log("resp", resp);
    setData(resp?.payload?.data);
  };

  const getHistoryUpdate = async () => {
    const resp: any = await dispatch(customerHistoryUpdate(state?.id));
    console.log("resp", resp);
    setDataHistoryUpdate(resp?.payload?.data);
  };

  const getText = (key: string) => {
    switch (key) {
      case "member":
        return "Thành viên";
      case "collaborator":
        return "Cộng tác viên";
      case "start_up":
        return "Khởi nghiệp";
      case "deputy_business":
        return "Phó phòng kinh doanh";
      case "sales_manager":
        return "Trưởng phòng kinh doanh";
      case "business_director":
        return "Giám đốc kinh doanh";
      case "deputy_general_manager":
        return "Phó tổng giám đốc";
      default:
        return "Chưa xác định";
    }
  };

  const getColor = (key: string) => {
    switch (key) {
      case "member":
        return Colors.BLACK;
      case "collaborator":
        return "brown";
      case "start_up":
        return "purple";
      case "deputy_business":
        return Colors.C_BLUE;
      case "sales_manager":
        return "orange";
      case "business_director":
        return "gold";
      case "deputy_general_manager":
        return Colors.RED;
      default:
        return Colors.GREY;
    }
  };
  return (
    <div className="px-4">
      <div className="fw-semibold fs-4">Thông tin tài khoản</div>
      <div className="row">
        <div className="col-md-6 col-12 bg-white">
          <div className="fw-bolder fs-4 pb-0">Thông tin cá nhân</div>
          <div className="d-flex justify-content-between pb-2">
            <div>Họ tên:</div>
            <div className="fw-bolder ps-2">{data?.fullName}</div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Email:</div>
            <div className="fw-bolder ps-2">{data?.email}</div>
          </div>

          <div className="fw-bolder fs-4 pb-0">Trạng thái</div>
          <div className="d-flex justify-content-between pb-2">
            <div>Xác thực:</div>
            <div className="fw-bolder ps-2">
              {data?.isVerified ? "Đã xác thực" : "Chưa xác thực"}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2">
            <div>Tình trạng:</div>
            <div className="fw-bolder ps-2">Đang hoạt động</div>
          </div>

          <div className="fw-bolder fs-4 pb-0">Thông tin khác</div>
          <div className="d-flex justify-content-between pb-2">
            <div>Cập bậc:</div>
            <div
              className="fw-bolder ps-2"
              style={{ color: getColor(data?.affiliateLevel) }}
            >
              {getText(data?.affiliateLevel)}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2">
            <div>Người giới thiệu:</div>
            <div>
              <div className="fw-bolder ps-2">{data?.parentInfo?.fullName}</div>
              <div className="fw-bolder ps-2">{data?.parentInfo?.email}</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2">
            <div>Đăng nhập gần nhất:</div>
            <div className="fw-bolder ps-2">
              {moment(data?.lastLogin).format("hh:mm DD/MM/YYYY")}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2">
            <div>Ngày tạo:</div>
            <div className="fw-bolder ps-2">
              {moment(data?.createdAt).format("hh:mm DD/MM/YYYY")}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2">
            <div>Ngày cập nhật:</div>
            <div className="fw-bolder ps-2">
              {moment(data?.updatedAt).format("hh:mm DD/MM/YYYY")}
            </div>
          </div>
          {dataHistoryUpdate?.length ? (
            dataHistoryUpdate.map((item: any) => {
              return (
                <div className="fw-semibold">
                  <div>{item?.content}</div>
                  <div>
                    {moment(item?.updatedAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <div>
                    {item?.updatedBy?.email} - {item?.updatedBy?.fullName}
                  </div>
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>

        <div className="col-md-6 col-12 bg-white">
          <div className="fw-bolder fs-4 pb-0">Thông tin mua hàng</div>
          <div className="d-flex justify-content-between pb-2">
            <div>Đơn hàng đã đặt:</div>
            <div className="fw-bolder ps-2">{data?.totalOrder}</div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Đơn hàng đã mua:</div>
            <div className="fw-bolder ps-2">{data?.totalPurchase}</div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Tổng tiền mua hàng:</div>
            <div className="fw-bolder ps-2">{data?.totalMoney}</div>
          </div>

          <div className="fw-bolder fs-4 pb-0">Ví cá nhân</div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Điểm điểm danh:</div>
            <div className="fw-bolder ps-2">{data?.walletInfo?.pointVIC}</div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Số dư ví:</div>
            <div className="fw-bolder ps-2">
              {formatNumber(data?.walletInfo?.accountBalance)}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Ví voucher:</div>
            <div className="fw-bolder ps-2">
              {formatNumber(data?.walletInfo?.amountVoucher)}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Ví mua cổ phần:</div>
            <div className="fw-bolder ps-2">
              {formatNumber(data?.walletInfo?.amountShare)}
            </div>
          </div>

          <div className="fw-bolder fs-4 pb-0">Doanh thu & lợi nhuận</div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Số tiền nạp (thành công/tổng số):</div>
            <div className="fw-bolder ps-2">
              {formatNumber(data?.walletReport[0]?.totalDepositSuccess)}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Số tiền rút (thành công/tổng số):</div>
            <div className="fw-bolder ps-2">
              {formatNumber(data?.walletReport[0]?.totalWithDrawSuccess)}
            </div>
          </div>
          <div className="d-flex justify-content-between pb-2 ">
            <div>Tổng hoa hồng:</div>
            <div className="fw-bolder ps-2">
              {formatNumber(data?.walletReport[0]?.totalPayCommission)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
