import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Accumulation from "src/Controller/Accumulation";

const AccumulationInitialState: any = {

};

export const allListAccumulative = createAsyncThunk(
    "user/allListAccumulative",
    async (params: any) => {
        const resp = await Accumulation.allListAccumulative(params);
        return resp;
    }
);

export const createAccumulative = createAsyncThunk(
    "user/createAccumulative",
    async (params: any) => {
        const resp = await Accumulation.createAccumulative(params);
        return resp;
    }
);

export const createAllAccumulative = createAsyncThunk(
    "user/createAllAccumulative",
    async (params: any) => {
        const resp = await Accumulation.createAllAccumulative(params);
        return resp;
    }
);

export const deleteAccumulative = createAsyncThunk(
    "user/deleteAccumulative",
    async (params: any) => {
        const resp = await Accumulation.deleteAccumulative(params);
        return resp;
    }
);

export const listAccumulative = createAsyncThunk(
    "user/listAccumulative",
    async (params: any) => {
        const resp = await Accumulation.listAccumulative(params);
        return resp;
    }
);

export const updateAccumulative = createAsyncThunk(
    "user/updateAccumulative",
    async (params: any) => {
        const resp = await Accumulation.updateAccumulative(params);
        return resp;
    }
);

const accumulation = createSlice({
    name: "accumulation",
    initialState: AccumulationInitialState,
    reducers: {

    },
});

const { actions, reducer } = accumulation;
export default reducer;