import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import formatNumber from "src/Commom/FormatNumber";
import { orderDetail } from "src/Components/Features/order/OrderSlice";

export default function OrderDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const resp: any = await dispatch(orderDetail(state?.id));
    console.log("resp", resp);
    setData(resp?.payload?.order);
  };

  const renderColor: any = {
    New: "#FFA500",
    Pending: "#EE4D2D",
    Success: "#5EBB25",
    Cancel: "#CA2D35",
  };

  return (
    <div className="p-4">
      <div className="fw-bolder fs-4">Chi tiết đơn hàng</div>
      <div className="row">
        <div className="col-md-6 col-12 bg-white rounded shadow p-4">
          <div className="fw-bolder fs-4 pb-0">Thông tin đơn hàng</div>
          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Mã hóa đơn:</div>
            <div className="fw-bolder">{data?.customerID}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Email người đặt:</div>
            <div className="fw-bolder">{data?.customerEmail}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Phương thức thanh toán:</div>
            <div className="fw-bolder">
              {data?.paymentMethod === "Wallet"
                ? "TT qua ví cá nhân"
                : "TT khi nhận hàng"}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tình trạng đơn hàng:</div>
            <div style={{ color: renderColor[data?.shippingStatus] }}>
              {data?.shippingStatus === "Pending"
                ? "Chờ xác nhận"
                : data?.shippingStatus === "Success"
                ? "Thành công"
                : ""}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Ngày tạo:</div>
            <div className="fw-bolder">
              {moment(data?.createdAt).format("DD/MM/YYYY hh:mm")}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Ngày tạo:</div>
            <div className="fw-bolder">
              {moment(data?.updateAt).format("DD/MM/YYYY hh:mm")}
            </div>
          </div>

          <div className="fw-bolder fs-4 pb-0">Thông tin giao hàng</div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tên người nhận:</div>
            <div className="fw-bolder">{data?.shippingName}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Số điện thoại:</div>
            <div className="fw-bolder">{data?.customerPhoneNumber}</div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Địa chỉ:</div>
            <div className="fw-bolder">
              {data?.shippingAddress} {data?.shippingWardName}{" "}
              {data?.shippingDistrictName} {data?.shippingProvinceName}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Lưu ý:</div>
            <div className="fw-bolder">{data?.shippingNote}</div>
          </div>
        </div>

        <div className="col-md-6 col-12 bg-white rounded shadow p-4">
          <div className="fw-bolder fs-4 pb-0">Chi tiết đơn hàng</div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Sản phẩm:</div>
            <div>
              {data?.orderDetails?.map((item: any, index: number) => {
                return (
                  <div>
                    <div className="fw-bolder">
                      {item?.productName} x{item?.quantity}
                    </div>
                    <div className="fw-bolder">
                      {formatNumber(item?.price)}đ
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-2 mt-2">
            <div>Tổng tiền hàng:</div>
            <div className="fw-bolder">{formatNumber(data?.total)}đ</div>
          </div>
        </div>
      </div>
    </div>
  );
}
