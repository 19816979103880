import client from "./Client";

const Order = {
  async orderList(params: any) {
    try {
      const res = await client.post(`/orders/list`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderExportExcel(params: any) {
    try {
      const res = await client.post(`/orders/export-excel`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderDetail(id: string) {
    try {
      const res = await client.get(`/orders/detail/${id}`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderUpdate(params: any) {
    try {
      const res = await client.post(`/orders/update`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderDelete(params: any) {
    try {
      const res = await client.post(`/orders/delete`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderCalcel(params: any) {
    try {
      const res = await client.post(`/orders/calcel`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderPaymentConfirmation(params: any) {
    try {
      const res = await client.post(`/orders/payment-confirmation`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderConfirm(params: any) {
    try {
      const res = await client.post(`/orders/confirm`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderPaymentCancel(params: any) {
    try {
      const res = await client.post(`/orders/payment-cancel`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderDelivetyConfirm(params: any) {
    try {
      const res = await client.post(`/orders/delivety-confirmation`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderDelivetySuccess(params: any) {
    try {
      const res = await client.post(`/orders/delivety-success`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async orderDelivetyCancel(params: any) {
    try {
      const res = await client.post(`/orders/delivety-cancel`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },
};

export default Order;
