import { userInterface } from "src/Constant/Interface";
import client from "./Client";
import axios from "axios";

const Users = {
  async login(params: userInterface) {
    try {
      const res = await client.post(`/users/login`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async forgotPassword(params: userInterface) {
    try {
      const res = await client.post(`/users/rest-password`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async register(params: userInterface) {
    try {
      const res = await client.post(`/users/sign`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async verifyEmail(params: userInterface) {
    try {
      const res = await client.post(`/users/verify-email`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async updateProfile(params: userInterface) {
    try {
      const res = await client.post(`/users/update`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async changePassword(params: userInterface) {
    try {
      const res = await client.post(`/users/change-password`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async gennerateRef() {
    try {
      const res = await client.post(`/users/gennerate-ref`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async detailAdmin() {
    try {
      const res = await client.get(`/users/detail`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async refreshToken(params: userInterface) {
    try {
      const res = await client.post(`/users/refresh-token`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async reportGeneral(params: any) {
    try {
      const res = await client.post(`/report/general`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async uploadImage(params: userInterface) {
    try {
      const res = await client.post(`/upload/image`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      );
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async listBank() {
    try {
      const res = await axios.get('https://api.vietqr.io/v2/banks')
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },
};

export default Users;
