import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Checkbox, Input, Select } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminCreate } from "src/Components/Features/manager/ManagerSlice";
import { useAppDispatch } from "src/Components/Redux/store";

const ManagerAdd = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [openTichLuy, setOpenTichLuy] = useState(false);
  const [openCheckAllTichLuy, setOpenCheckAllTichLuy] = useState(false);
  const [investment_create, setInvestment_create] = useState(false);
  const [investment_list, setInvestment_list] = useState(false);
  const [investment_update, setInvestment_update] = useState(false);
  const [investment_delete, setInvestment_delete] = useState(false);
  const [accumulative_list, setAccumulative_list] = useState(false);
  const [openDanhMucSanPham, setOpenDanhMucSanPham] = useState(false);
  const [openCheckAllDanhMucSanPham, setOpenCheckAllDanhMucSanPham] =
    useState(false);
  const [danhMucCreate, setDanhMucCreate] = useState(false);
  const [danhMucDelete, setDanhMucDelete] = useState(false);
  const [danhMucDetail, setDanhMucDetail] = useState(false);
  const [danhMucList, setDanhMucList] = useState(false);
  const [danhMucUpdate, setDanhMucUpdate] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openCheckAllProduct, setOpenCheckAllProduct] = useState(false);
  const [productList, setProductList] = useState(false);
  const [productDetail, setProductDetail] = useState(false);
  const [productAdd, setProductAdd] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [productDelete, setProductDelete] = useState(false);
  const [openCheckAllPromo, setOpenCheckAllPromo] = useState(false);
  const [openPromo, setOpenPromo] = useState(false);
  const [promoCreate, setPromoCreate] = useState(false);
  const [promoDelete, setPromoDelete] = useState(false);
  const [promoDetail, setPromoDetail] = useState(false);
  const [promoList, setPromoList] = useState(false);
  const [promoUpdate, setPromoUpdate] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [openCheckAllWallet, setOpenCheckAllWallet] = useState(false);
  const [AddBank, setAddBank] = useState(false);
  const [ListBank, setListBank] = useState(false);
  const [UpdateBank, setUpdateBank] = useState(false);
  const [DeleteBank, setDeleteBank] = useState(false);
  const [HistoryTrans, setHistoryTrans] = useState(false);
  const [XacNhanThanhCong, setXacNhanThanhCong] = useState(false);
  const [XacNhanThatBai, setXacNhanThatBai] = useState(false);
  const [ChiTietGiaoDich, setChiTietGiaoDich] = useState(false);
  const [XuatFileLichSuGD, setXuatFileLichSuGD] = useState(false);
  const [OpenOrders, setOpenOrders] = useState(false);
  const [OpenCheckAllOrders, setOpenCheckAllOrders] = useState(false);
  const [OrderCancel, setOrderCancel] = useState(false);
  const [OrderConfirm, setOrderConfirm] = useState(false);
  const [OrderDelete, setOrderDelete] = useState(false);
  const [OrderDelivety_cancel, setOrderDelivety_cancel] = useState(false);
  const [OrderDelivety_confirm, setOrderDelivety_confirm] = useState(false);
  const [OrderDelivety_success, setOrderDelivety_success] = useState(false);
  const [OrderDetail, setOrderDetail] = useState(false);
  const [OrderExport_excel, setOrderExport_excel] = useState(false);
  const [OrderList, setOrderList] = useState(false);
  const [OrderPayment_cancel, setOrderPayment_cancel] = useState(false);
  const [OrderPayment_confirm, setOrderPayment_confirm] = useState(false);
  const [OrderUpdate, setOrderUpdate] = useState(false);

  const [openCustomer, setOpenCustomer] = useState(false);
  const [openCheckAllCustomer, setOpenCheckAllCustomer] = useState(false);
  const [CustomerChange_email, setCustomerChange_email] = useState(false);
  const [CustomerChange_password, setCustomerChange_password] = useState(false);
  const [CustomerDetail, setCustomerDetail] = useState(false);
  const [CustomerList, setCustomerList] = useState(false);
  const [CustomerLock, setCustomerLock] = useState(false);
  const [CustomerOpen_account, setCustomerOpen_account] = useState(false);
  const [CustomerTeam_model, setCustomerTeam_model] = useState(false);
  const [CustomerTeam_model_search, setCustomerTeam_model_search] =
    useState(false);
  const [CustomerUn_lock, setCustomerUn_lock] = useState(false);
  const [CustomerUpdate, setCustomerUpdate] = useState(false);
  const [CustomerUpdate_amount_customer, setCustomerUpdate_amount_customer] =
    useState(false);
  const [CustomerUpgrade_affiliate_level, setCustomerUpgrade_affiliate_level] =
    useState(false);

  const [openNews, setOpenNews] = useState(false);
  const [openCheckAllNews, setOpenCheckAllNews] = useState(false);
  const [NewList, setNewList] = useState(false);
  const [NewDetail, setNewDetail] = useState(false);
  const [NewCreate, setNewCreate] = useState(false);
  const [NewUpdate, setNewUpdate] = useState(false);
  const [NewDelete, setNewDelete] = useState(false);
  const [OpenReportAndHome, setOpenReportAndHome] = useState(false);
  const [OpenCheckAllReportAndHome, setOpenCheckAllReportAndHome] =
    useState(false);
  const [ReportAll, setReportAll] = useState(false);

  const [OpenAdmin, setOpenAdmin] = useState(false);
  const [checkAllAdmin, setCheckAllAdmin] = useState(false);
  const [AdminList, setAdminList] = useState(false);
  const [_AdminDetail, setAdminDetail] = useState(false);
  const [AdminCreate, setAdminCreate] = useState(false);
  const [AdminUpdate, setAdminUpdate] = useState(false);
  const [AdminReset_pasword, setAdminReset_pasword] = useState(false);
  const [AdminLock, setAdminLock] = useState(false);
  const [AdminUn_lock, setAdminUn_lock] = useState(false);

  const [OpenConfig, setOpenConfig] = useState(false);
  const [CheckAllConfig, setCheckAllConfig] = useState(false);
  const [ConfigDetail, setConfigDetail] = useState(false);
  const [ConfigUpdateFee, setConfigUpdateFee] = useState(false);
  const [type, setType] = useState("");

  const onCheckAccumulativeAll = (e: any) => {
    setOpenCheckAllTichLuy(e.target.checked);
    setInvestment_create(e.target.checked);
    setInvestment_list(e.target.checked);
    setInvestment_update(e.target.checked);
    setInvestment_delete(e.target.checked);
    setAccumulative_list(e.target.checked);
  };

  const onCheckDanhMucSanPhamAll = (e: any) => {
    setOpenCheckAllDanhMucSanPham(e.target.checked);
    setDanhMucCreate(e.target.checked);
    setDanhMucDelete(e.target.checked);
    setDanhMucDetail(e.target.checked);
    setDanhMucList(e.target.checked);
    setDanhMucUpdate(e.target.checked);
  };

  const onCheckProductAll = (e: any) => {
    setOpenCheckAllProduct(e.target.checked);
    setProductList(e.target.checked);
    setProductDetail(e.target.checked);
    setProductAdd(e.target.checked);
    setProductUpdate(e.target.checked);
    setProductDelete(e.target.checked);
  };

  const onCheckPromoAll = (e: any) => {
    setOpenCheckAllPromo(e.target.checked);
    setPromoList(e.target.checked);
    setPromoDetail(e.target.checked);
    setPromoCreate(e.target.checked);
    setPromoUpdate(e.target.checked);
    setPromoDelete(e.target.checked);
  };

  const onCheckAllWallet = (e: any) => {
    setOpenCheckAllWallet(e.target.checked);
    setAddBank(e.target.checked);
    setUpdateBank(e.target.checked);
    setListBank(e.target.checked);
    setDeleteBank(e.target.checked);
    setXuatFileLichSuGD(e.target.checked);
    setHistoryTrans(e.target.checked);
    setXacNhanThanhCong(e.target.checked);
    setXacNhanThatBai(e.target.checked);
    setChiTietGiaoDich(e.target.checked);
  };

  const onCheckAllOrders = (e: any) => {
    setOpenCheckAllOrders(e.target.checked);
    setOrderCancel(e.target.checked);
    setOrderConfirm(e.target.checked);
    setOrderDelete(e.target.checked);
    setOrderDelivety_cancel(e.target.checked);
    setOrderDelivety_confirm(e.target.checked);
    setOrderDelivety_success(e.target.checked);
    setOrderDetail(e.target.checked);
    setOrderExport_excel(e.target.checked);
    setOrderList(e.target.checked);
    setOrderPayment_cancel(e.target.checked);
    setOrderPayment_confirm(e.target.checked);
    setOrderUpdate(e.target.checked);
  };

  const onCheckAllCustomers = (e: any) => {
    setOpenCheckAllCustomer(e.target.checked);
    setCustomerChange_email(e.target.checked);
    setCustomerChange_password(e.target.checked);
    setCustomerDetail(e.target.checked);
    setCustomerList(e.target.checked);
    setCustomerLock(e.target.checked);
    setCustomerOpen_account(e.target.checked);
    setCustomerTeam_model(e.target.checked);
    setCustomerTeam_model_search(e.target.checked);
    setCustomerUn_lock(e.target.checked);
    setCustomerUpdate(e.target.checked);
    setCustomerUpdate_amount_customer(e.target.checked);
    setCustomerUpgrade_affiliate_level(e.target.checked);
  };

  const onCheckAllNew = (e: any) => {
    setOpenCheckAllNews(e.target.checked);
    setNewList(e.target.checked);
    setNewCreate(e.target.checked);
    setNewDelete(e.target.checked);
    setNewDetail(e.target.checked);
    setNewUpdate(e.target.checked);
  };

  const onCheckAllReport = (e: any) => {
    setOpenCheckAllReportAndHome(e.target.checked);
    setReportAll(e.target.checked);
  };

  const onCheckAllAdmin = (e: any) => {
    setCheckAllAdmin(e.target.checked);
    setAdminList(e.target.checked);
    setAdminDetail(e.target.checked);
    setAdminCreate(e.target.checked);
    setAdminUpdate(e.target.checked);
    setAdminReset_pasword(e.target.checked);
    setAdminLock(e.target.checked);
    setAdminUn_lock(e.target.checked);
  };

  const onCheckAllConfig = (e: any) => {
    setCheckAllConfig(e.target.checked);
    setConfigDetail(e.target.checked);
    setConfigUpdateFee(e.target.checked);
  };

  const onCreate = async () => {
    const params = {
      email: email,
      password: password,
      repeatPassword: rePassword,
      fullName: username,
      type: type === "Quản lý" ? "Manager" : "Staff",
      scopes: {
        accumulative: {
          isFull: openCheckAllTichLuy,
          investment_create: investment_create,
          investment_list: investment_list,
          investment_update: investment_update,
          investment_delete: investment_delete,
          accumulative_list: accumulative_list,
        },
        categoris: {
          isFull: openCheckAllDanhMucSanPham,
          list: danhMucList,
          detail: danhMucDetail,
          create: danhMucCreate,
          update: danhMucUpdate,
        },
        products: {
          isFull: openCheckAllProduct,
          list: productList,
          detail: productDetail,
          create: productAdd,
          update: productUpdate,
          delete: productDelete,
        },
        promo: {
          isFull: openCheckAllPromo,
          list: promoList,
          detail: promoDetail,
          create: promoCreate,
          update: promoUpdate,
          delete: promoDelete,
        },
        wallet: {
          isFull: openCheckAllWallet,
          bank_create: AddBank,
          bank_list: ListBank,
          bank_update: UpdateBank,
          bank_delete: DeleteBank,
          transaction_list: HistoryTrans,
          transaction_accepted: XacNhanThanhCong,
          transaction_cancel: XacNhanThatBai,
          transaction_detail: ChiTietGiaoDich,
        },
        users: {
          isFull: true,
        },
        orders: {
          isFull: OpenCheckAllOrders,
          list: OrderList,
          detail: OrderDetail,
          update: OrderUpdate,
          delete: OrderDelete,
          calcel: OrderCancel,
          confirm: OrderConfirm,
          payment_confirmation: OrderPayment_confirm,
          payment_cancel: OrderPayment_cancel,
          delivety_confirmation: OrderDelivety_confirm,
          delivety_success: OrderDelivety_success,
          delivety_cancel: OrderDelivety_cancel,
        },
        customers: {
          isFull: openCheckAllCustomer,
          list: CustomerList,
          detail: CustomerDetail,
          update: CustomerUpdate,
          reset_pasword: true,
          lock: CustomerLock,
          un_lock: CustomerUn_lock,
          change_email: CustomerChange_email,
          change_password: CustomerChange_password,
          open_account: CustomerOpen_account,
        },
        news: {
          isFull: openCheckAllNews,
          list: NewList,
          detail: NewDetail,
          create: NewCreate,
          update: NewUpdate,
          delete: NewDelete,
        },
        report: {
          isFull: OpenCheckAllReportAndHome,
          general: ReportAll,
        },
        admin: {
          isFull: checkAllAdmin,
          create: AdminCreate,
        },
      },
    };
    const resp: any = await dispatch(adminCreate(params));
    if (resp?.payload?.status === true) {
      toast.success("Thêm mới nhân viên thành công !");
    }
  };

  const provinceData = ["Nhân viên", "Quản lý"];

  const handleProvinceChange = (value: any) => {
    console.log("value", value);
    setType(value);
  };

  return (
    <div
      className="col row px-2 pb-2"
      style={{
        backgroundColor: "#F5F5F5",
      }}
    >
      <div className="fw-semibold fs-5 py-3">Danh sách nhân viên</div>
      <div className="col-lg-5 bg-white">
        <div className="fw-semibold fs-5 py-3">Thông tin tài khoản</div>
        <Select
          style={{ width: "100%" }}
          placeholder="Loại tài khoản"
          className="mb-3"
          onChange={handleProvinceChange}
          options={provinceData.map((province) => ({
            label: province,
            value: province,
          }))}
        />
        <Input
          placeholder={"Email*"}
          value={email}
          onChange={(text) => setEmail(text.target.value)}
        />
        <Input
          placeholder={"Tên tài khoản*"}
          className="my-3"
          value={username}
          onChange={(text) => setUsername(text.target.value)}
        />
        <Input
          placeholder={"Mật khẩu*"}
          value={password}
          onChange={(text) => setPassword(text.target.value)}
        />
        <Input
          placeholder={"Nhập lại mật khẩu*"}
          className="my-3"
          value={rePassword}
          onChange={(text) => setRePassword(text.target.value)}
        />
      </div>
      <div className="col-lg-5 bg-white ms-3">
        <div className="fw-semibold fs-5 py-3">Quyền hạn</div>
        <div className="">
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openTichLuy ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenTichLuy(!openTichLuy)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenTichLuy(!openTichLuy)}
              />
            )}{" "}
            <Checkbox
              checked={openCheckAllTichLuy}
              className="me-2"
              onChange={(e) => onCheckAccumulativeAll(e)}
            />{" "}
            {"Ví tích luỹ"}
          </button>
          {openTichLuy && (
            <div>
              <div>
                <Checkbox
                  checked={investment_create}
                  onChange={(e) => setInvestment_create(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới trả thưởng"}
              </div>
              <div>
                <Checkbox
                  checked={investment_list}
                  onChange={(e) => setInvestment_list(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách trả thưởng"}
              </div>
              <div>
                <Checkbox
                  checked={investment_update}
                  onChange={(e) => setInvestment_update(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật trả thưởng"}
              </div>
              <div>
                <Checkbox
                  checked={investment_delete}
                  onChange={(e) => setInvestment_delete(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá trả thưởng"}
              </div>
              <div>
                <Checkbox
                  checked={accumulative_list}
                  onChange={(e) => setAccumulative_list(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách tích luỹ"}
              </div>
            </div>
          )}
        </div>
        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openDanhMucSanPham ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenDanhMucSanPham(!openDanhMucSanPham)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenDanhMucSanPham(!openDanhMucSanPham)}
              />
            )}
            <Checkbox
              checked={openCheckAllDanhMucSanPham}
              className="me-2"
              onChange={(e) => onCheckDanhMucSanPhamAll(e)}
            />{" "}
            {"Danh mục sản phẩm"}
          </button>
          {openDanhMucSanPham && (
            <div>
              <div>
                <Checkbox
                  checked={danhMucCreate}
                  onChange={(e) => setDanhMucCreate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới danh mục"}
              </div>
              <div>
                <Checkbox
                  checked={danhMucDelete}
                  onChange={(e) => setDanhMucDelete(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá danh mục"}
              </div>
              <div>
                <Checkbox
                  checked={danhMucDetail}
                  onChange={(e) => setDanhMucDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết danh mục"}
              </div>
              <div>
                <Checkbox
                  checked={danhMucList}
                  onChange={(e) => setDanhMucList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách danh mục"}
              </div>
              <div>
                <Checkbox
                  checked={danhMucUpdate}
                  onChange={(e) => setDanhMucUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật danh mục"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openProduct ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenProduct(!openProduct)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenProduct(!openProduct)}
              />
            )}
            <Checkbox
              checked={openCheckAllProduct}
              className="me-2"
              onChange={(e) => onCheckProductAll(e)}
            />{" "}
            {"Sản phẩm"}
          </button>
          {openProduct && (
            <div>
              <div>
                <Checkbox
                  checked={productList}
                  onChange={(e) => setProductList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách sản phẩm"}
              </div>
              <div>
                <Checkbox
                  checked={productDetail}
                  onChange={(e) => setProductDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết sản phẩm"}
              </div>
              <div>
                <Checkbox
                  checked={productAdd}
                  onChange={(e) => setProductAdd(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới sản phẩm"}
              </div>
              <div>
                <Checkbox
                  checked={productUpdate}
                  onChange={(e) => setProductUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật sản phẩm"}
              </div>
              <div>
                <Checkbox
                  checked={productDelete}
                  onChange={(e) => setProductDelete(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá sản phẩm"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openPromo ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenPromo(!openPromo)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenPromo(!openPromo)}
              />
            )}
            <Checkbox
              checked={openCheckAllPromo}
              className="me-2"
              onChange={(e) => onCheckPromoAll(e)}
            />{" "}
            {"Khuyễn mãi"}
          </button>
          {openPromo && (
            <div>
              <div>
                <Checkbox
                  checked={promoList}
                  onChange={(e) => setPromoList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách khuyến mãi"}
              </div>
              <div>
                <Checkbox
                  checked={promoDetail}
                  onChange={(e) => setPromoDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết khuyến mãi"}
              </div>
              <div>
                <Checkbox
                  checked={promoCreate}
                  onChange={(e) => setPromoCreate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới khuyễn mãi"}
              </div>
              <div>
                <Checkbox
                  checked={promoUpdate}
                  onChange={(e) => setPromoUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật khuyễn mãi"}
              </div>
              <div>
                <Checkbox
                  checked={promoDelete}
                  onChange={(e) => setPromoDelete(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá khuyễn mãi"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openWallet ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenWallet(!openWallet)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenWallet(!openWallet)}
              />
            )}
            <Checkbox
              checked={openCheckAllWallet}
              className="me-2"
              onChange={(e) => onCheckAllWallet(e)}
            />{" "}
            {"Ví chính"}
          </button>
          {openWallet && (
            <div>
              <div>
                <Checkbox
                  checked={AddBank}
                  onChange={(e) => setAddBank(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới ngân hàng"}
              </div>
              <div>
                <Checkbox
                  checked={ListBank}
                  onChange={(e) => setListBank(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách ngân hàng"}
              </div>
              <div>
                <Checkbox
                  checked={UpdateBank}
                  onChange={(e) => setUpdateBank(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật ngân hàng"}
              </div>
              <div>
                <Checkbox
                  checked={DeleteBank}
                  onChange={(e) => setDeleteBank(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá ngân hàng"}
              </div>
              <div>
                <Checkbox
                  checked={HistoryTrans}
                  onChange={(e) => setHistoryTrans(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Lịch sử giao dịch"}
              </div>
              <div>
                <Checkbox
                  checked={XacNhanThanhCong}
                  onChange={(e) => setXacNhanThanhCong(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xác nhận thành công"}
              </div>
              <div>
                <Checkbox
                  checked={XacNhanThatBai}
                  onChange={(e) => setXacNhanThatBai(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xác nhận thât bại"}
              </div>
              <div>
                <Checkbox
                  checked={ChiTietGiaoDich}
                  onChange={(e) => setChiTietGiaoDich(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết giao dịch"}
              </div>
              <div>
                <Checkbox
                  checked={XuatFileLichSuGD}
                  onChange={(e) => setXuatFileLichSuGD(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xuất file lịch sử giao dịch"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {OpenOrders ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenOrders(!OpenOrders)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenOrders(!OpenOrders)}
              />
            )}
            <Checkbox
              checked={OpenCheckAllOrders}
              className="me-2"
              onChange={(e) => onCheckAllOrders(e)}
            />{" "}
            {"Hoá đơn"}
          </button>
          {OpenOrders && (
            <div>
              <div>
                <Checkbox
                  checked={OrderList}
                  onChange={(e) => setOrderList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách hoá đơn"}
              </div>
              <div>
                <Checkbox
                  checked={OrderDetail}
                  onChange={(e) => setOrderDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết hoá đơn"}
              </div>
              <div>
                <Checkbox
                  checked={OrderUpdate}
                  onChange={(e) => setOrderUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật hoá đơn"}
              </div>
              <div>
                <Checkbox
                  checked={OrderDelete}
                  onChange={(e) => setOrderDelete(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá hoá đơn"}
              </div>
              <div>
                <Checkbox
                  checked={OrderCancel}
                  onChange={(e) => setOrderCancel(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Huỷ hoá đơn"}
              </div>
              <div>
                <Checkbox
                  checked={OrderConfirm}
                  onChange={(e) => setOrderConfirm(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xác nhận thành công"}
              </div>
              <div>
                <Checkbox
                  checked={OrderPayment_confirm}
                  onChange={(e) => setOrderPayment_confirm(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thanh toán thành công"}
              </div>
              <div>
                <Checkbox
                  checked={OrderPayment_cancel}
                  onChange={(e) => setOrderPayment_cancel(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thanh toán thất bại"}
              </div>
              <div>
                <Checkbox
                  checked={OrderDelivety_confirm}
                  onChange={(e) => setOrderDelivety_confirm(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xác nhận giao hàng"}
              </div>
              <div>
                <Checkbox
                  checked={OrderDelivety_success}
                  onChange={(e) => setOrderDelivety_success(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Giao hàng thành công"}
              </div>
              <div>
                <Checkbox
                  checked={OrderDelivety_cancel}
                  onChange={(e) => setOrderDelivety_cancel(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Giao hàng thất bại"}
              </div>
              <div>
                <Checkbox
                  checked={OrderExport_excel}
                  onChange={(e) => setOrderExport_excel(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xuất file danh sách hoá đơn"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openCustomer ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenCustomer(!openCustomer)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenCustomer(!openCustomer)}
              />
            )}
            <Checkbox
              checked={openCheckAllCustomer}
              className="me-2"
              onChange={(e) => onCheckAllCustomers(e)}
            />{" "}
            {"Tài khoản khách hàng"}
          </button>
          {openCustomer && (
            <div>
              <div>
                <Checkbox
                  checked={CustomerList}
                  onChange={(e) => setCustomerList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách khách hàng"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerDetail}
                  onChange={(e) => setCustomerDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết khách hàng"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerUpdate}
                  onChange={(e) => setCustomerUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật khách hàng"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerLock}
                  onChange={(e) => setCustomerLock(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Khoá tài khoản"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerUn_lock}
                  onChange={(e) => setCustomerUn_lock(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Mở khoá tài khoản"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerChange_email}
                  onChange={(e) => setCustomerChange_email(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thay đổi email"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerChange_password}
                  onChange={(e) => setCustomerChange_password(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thay đổi mật khẩu"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerOpen_account}
                  onChange={(e) => setCustomerOpen_account(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Khôi phục tài khoản"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerTeam_model}
                  onChange={(e) => setCustomerTeam_model(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xem cây giới thiệu"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerTeam_model_search}
                  onChange={(e) =>
                    setCustomerTeam_model_search(e.target.checked)
                  }
                  className="me-2"
                />{" "}
                {"Tìm kiếm cây giới thiệu"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerUpgrade_affiliate_level}
                  onChange={(e) =>
                    setCustomerUpgrade_affiliate_level(e.target.checked)
                  }
                  className="me-2"
                />{" "}
                {"Thay đổi cấp bậc"}
              </div>
              <div>
                <Checkbox
                  checked={CustomerUpdate_amount_customer}
                  onChange={(e) =>
                    setCustomerUpdate_amount_customer(e.target.checked)
                  }
                  className="me-2"
                />{" "}
                {"Thay đổi số dư ví"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {openNews ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenNews(!openNews)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenNews(!openNews)}
              />
            )}
            <Checkbox
              checked={openCheckAllNews}
              className="me-2"
              onChange={(e) => onCheckAllNew(e)}
            />{" "}
            {"Thông báo"}
          </button>
          {openNews && (
            <div>
              <div>
                <Checkbox
                  checked={NewList}
                  onChange={(e) => setNewList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách thông báo"}
              </div>
              <div>
                <Checkbox
                  checked={NewDetail}
                  onChange={(e) => setNewDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết thông báo"}
              </div>
              <div>
                <Checkbox
                  checked={NewCreate}
                  onChange={(e) => setNewCreate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới thông báo"}
              </div>
              <div>
                <Checkbox
                  checked={NewUpdate}
                  onChange={(e) => setNewUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật thông báo"}
              </div>
              <div>
                <Checkbox
                  checked={NewDelete}
                  onChange={(e) => setNewDelete(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Xoá thông báo"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {OpenReportAndHome ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenReportAndHome(!OpenReportAndHome)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenReportAndHome(!OpenReportAndHome)}
              />
            )}
            <Checkbox
              checked={OpenCheckAllReportAndHome}
              className="me-2"
              onChange={(e) => onCheckAllReport(e)}
            />{" "}
            {"Báo cáo & Trang chủ"}
          </button>
          {OpenReportAndHome && (
            <div>
              <div>
                <Checkbox
                  checked={ReportAll}
                  onChange={(e) => setReportAll(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Toán bộ báo cáo"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {OpenAdmin ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenAdmin(!OpenAdmin)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenAdmin(!OpenAdmin)}
              />
            )}
            <Checkbox
              checked={checkAllAdmin}
              className="me-2"
              onChange={(e) => onCheckAllAdmin(e)}
            />{" "}
            {"Tài khoản nhân viên"}
          </button>
          {OpenAdmin && (
            <div>
              <div>
                <Checkbox
                  checked={AdminList}
                  onChange={(e) => setAdminList(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Danh sách nhân viên"}
              </div>
              <div>
                <Checkbox
                  checked={_AdminDetail}
                  onChange={(e) => setAdminDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết nhân viên"}
              </div>
              <div>
                <Checkbox
                  checked={AdminCreate}
                  onChange={(e) => setAdminCreate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Thêm mới nhân viên"}
              </div>
              <div>
                <Checkbox
                  checked={AdminUpdate}
                  onChange={(e) => setAdminUpdate(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật nhân viên"}
              </div>
              <div>
                <Checkbox
                  checked={AdminReset_pasword}
                  onChange={(e) => setAdminReset_pasword(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Đặt lại mật khẩu"}
              </div>
              <div>
                <Checkbox
                  checked={AdminLock}
                  onChange={(e) => setAdminLock(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Khoá tài khoản"}
              </div>
              <div>
                <Checkbox
                  checked={AdminUn_lock}
                  onChange={(e) => setAdminUn_lock(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Mở khoá tài khoản"}
              </div>
            </div>
          )}
        </div>

        <div>
          <button className="border border-0 p-0 mb-2 bg-white d-flex align-items-start">
            {OpenConfig ? (
              <CaretDownOutlined
                className="me-2"
                onClick={() => setOpenConfig(!OpenConfig)}
              />
            ) : (
              <CaretRightOutlined
                className="me-2"
                onClick={() => setOpenConfig(!OpenConfig)}
              />
            )}
            <Checkbox
              checked={CheckAllConfig}
              className="me-2"
              onChange={(e) => onCheckAllConfig(e)}
            />{" "}
            {"Cấu hình"}
          </button>
          {OpenConfig && (
            <div>
              <div>
                <Checkbox
                  checked={ConfigUpdateFee}
                  onChange={(e) => setConfigUpdateFee(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Cập nhật cấu hình rút tiền"}
              </div>
              <div>
                <Checkbox
                  checked={ConfigDetail}
                  onChange={(e) => setConfigDetail(e.target.checked)}
                  className="me-2"
                />{" "}
                {"Chi tiết cấu hình rút tiền"}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bg-white mt-3 rounded">
        <div className="fw-semibold fs-5 py-3">LƯU Ý</div>
        <div className="d-flex">
          1. Chọn các quyền khác (ngoài quyền{" "}
          <div className="fw-semibold">"Thêm mới..."</div> ) cần phải chọn thêm
          quyền <div className="fw-semibold me-1">"Danh sách..." (nếu có) </div>{" "}
          trong các nhóm quyền tương ứng để được hiển thị trong giao diện trang
          quản trị.
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center">
            2. <div className="text-warning">Sản phẩm:</div>
          </div>
          <div className="d-flex">
            ➤ Chọn quyền <div className="fw-semibold">'Thêm mới sản phẩm'</div>{" "}
            và/hoặc
            <div className="fw-semibold">"Cập nhật sản phẩm"</div> cần chọn thêm
            quyền <div className="fw-semibold">"Danh sách danh mục"</div> nếu
            muốn Thêm mới/cập nhật{" "}
            <div className="fw-semibold">"Danh mục sản phẩm"</div> cho sản phẩm.
          </div>
          <div className="d-flex">
            ➤ Chọn quyền <div className="fw-semibold">'Cập nhật sản phẩm'</div>{" "}
            cần phải chọn thêm quyền{" "}
            <div className="fw-semibold">"Chi tiết sản phẩm"</div> để hiển thị
            thông tin sản phẩm trước khi cập nhật.
          </div>
        </div>
        <div className="my-2">
          <div className="d-flex align-items-center">
            3. <div className="text-warning">Khuyến mãi:</div>
          </div>
          <div className="d-flex">
            Khi chọn quyền{" "}
            <div className="fw-semibold">"Thêm mới khuyến mãi"</div> và/hoặc
            <div className="fw-semibold">"Cập nhật khuyến mãi"</div> cần chọn
            thêm một/những quyền sau:
          </div>
          <div className="d-flex">
            ➤ Quyền <div className="fw-semibold">'Danh sách danh mục'</div> nếu
            muốn sử dụng chức năng{" "}
            <div className="fw-semibold">'Danh mục được chọn'</div> của{" "}
            <div className="fw-semibold">"Mặt hàng áp dụng"</div>.
          </div>
          <div className="d-flex my-1">
            ➤ Quyền <div className="fw-semibold">'Danh sách sản phẩm'</div> nếu
            muốn sử dụng chức năng{" "}
            <div className="fw-semibold">'Sản phẩm được chọn'</div> của{" "}
            <div className="fw-semibold">"Mặt hàng áp dụng"</div>.
          </div>
          <div className="d-flex">
            ➤ Quyền <div className="fw-semibold">'Danh sách khách hàng'</div>{" "}
            nếu muốn sử dụng chức năng{" "}
            <div className="fw-semibold">'Khách hàng cụ thể'</div> của{" "}
            <div className="fw-semibold">"Đối tượng áp dụng"</div>.
          </div>
        </div>
        <div className="mb-2">
          <div className="d-flex align-items-center">
            4. <div className="text-warning">Thông báo:</div>
          </div>
          <div className="d-flex">
            ➤ Khi chọn quyền{" "}
            <div className="fw-semibold">'Cập nhật thông báo'</div> cần phải
            chọn thêm quyền{" "}
            <div className="fw-semibold">'Chi tiết thông báo'</div> để hiển thị
            thông tin thông báo trước khi cập nhật.
          </div>
        </div>
        <div className="mb-2">
          <div className="d-flex align-items-center">
            5. <div className="text-warning">Ví chính:</div>
          </div>
          <div className="d-flex">
            ➤ Cần chọn thêm quyền{" "}
            <div className="fw-semibold">"Lịch sử giao dịch"</div> cần {"<=>"}{" "}
            <div className="fw-semibold">"Danh sách giao dịch"</div> khi chọn
            các quyền khác liên quan đến giao dịch.
          </div>
        </div>
        <div className="mb-2">
          <div className="d-flex align-items-center">
            6. <div className="text-warning">Tài khoản khách hàng:</div>
          </div>
          <div className="d-flex">
            ➤ Nếu muốn <div className="fw-semibold">"Xem cây giới thiệu"</div>{" "}
            và/hoặc
            <div className="fw-semibold">"Tìm kiếm cây giới thiệu"</div> thì chỉ
            cần chọn quyền này mà không cần phải chọn thêm quyền nào khác.
          </div>
        </div>
        <div className="mb-2">
          <div className="d-flex align-items-center">
            7. <div className="text-warning">Tài khoản nhân viên:</div>
          </div>
          <div className="d-flex">
            ➤ Khi chọn quyền{" "}
            <div className="fw-semibold">"Cập nhật nhân viên"</div> cần phải
            chọn thêm quyền
            <div className="fw-semibold">"Chi tiết nhân viên"</div> để hiển thị
            thông tin nhân viên trước khi cập nhật.
          </div>
        </div>
        <div className="mb-2">
          <div className="d-flex align-items-center">
            8. <div className="text-warning">Cấu hình:</div>
          </div>
          <div className="d-flex">
            ➤ Chọn quyền{" "}
            <div className="fw-semibold">"Cập nhật cấu hình rút tiền"</div> cần
            phải chọn thêm quyền
            <div className="fw-semibold">"Chi tiết cấu hình rút tiền"</div> để
            hiển thị thông tin cấu hình rút tiền trước khi cập nhật.
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 my-3 justify-content-center">
        <button
          className="text-success border border-1 rounded border-success p-2"
          onClick={onCreate}
        >
          Thêm mới
        </button>
        <button
          className=" border border-1 rounded p-2"
          onClick={() => navigate(-1)}
        >
          Danh sách nhân viên
        </button>
      </div>
    </div>
  );
};

export default ManagerAdd;
