import {
  CopyFilled,
  DownOutlined,
  LeftOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { Input, Collapse } from "antd";
import type { CollapseProps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "src/Commom/Colors";
import Loading from "src/Commom/Loading";
import {
  customerTeamModel,
  customerTeamModelSearch,
} from "src/Components/Features/customer/CustomerSlice";
import { selectLoading } from "src/Components/Redux/selector";

const level = [
  {
    id: 0,
    text: " Thành viên",
    color: Colors.BLACK,
  },
  {
    id: 1,
    text: "Cộng tác viên",
    color: "brown",
  },
  {
    id: 2,
    text: "Khởi nghiệp",
    color: "purple",
  },
  {
    id: 3,
    text: "Phó phòng kinh doanh",
    color: Colors.C_BLUE,
  },
  {
    id: 5,
    text: "Trưởng phòng kinh doanh",
    color: Colors.ORANGE,
  },
  {
    id: 7,
    text: "Phó tổng giám đốc",
    color: Colors.RED,
  },
  {
    id: 6,
    text: "Giám đốc kinh doanh",
    color: "#FFCC33",
  },
  {
    id: 8,
    text: "Chưa xác định",
    color: Colors.GREY,
  },
];

export default function TeamModel() {
  const { Search } = Input;
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const [data, setData] = useState<any>([]);
  const [dataSearch, setDataSearch] = useState<any>(null);
  const [chooseUser, setChooseUser] = useState<any>(null);

  const onSearch = (value: any, _e: any, info: any) => {
    console.log(value);
    getSearchTree(value);
  };

  useEffect(() => {
    getTree("");
  }, []);

  const getTree = async (value: string | string[]) => {
    const params = { parentID: value };
    const resp: any = await dispatch(customerTeamModel(value ? params : {}));
    console.log("resp tree--", resp);
    setData(resp?.payload?.data);
  };

  const getSearchTree = async (value: string) => {
    const resp: any = await dispatch(customerTeamModelSearch({ email: value }));
    console.log("resp", resp);
    setDataSearch(resp?.payload?.data);
  };

  const getColor = (key: string) => {
    switch (key) {
      case "member":
        return Colors.BLACK;
      case "collaborator":
        return "brown";
      case "start_up":
        return "purple";
      case "deputy_business":
        return Colors.C_BLUE;
      case "sales_manager":
        return "orange";
      case "business_director":
        return "gold";
      case "deputy_general_manager":
        return Colors.RED;
      default:
        return Colors.GREY;
    }
  };

  const itemsSearch: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div style={{ color: getColor(dataSearch?.affiliateLevel) }}>
          {dataSearch?.email}
        </div>
      ),
      children: dataSearch?.childs?.map((i: any) => (
        <div>
          <div className="mb-2" style={{ color: getColor(i?.affiliateLevel) }}>
            {i?.childs?.length ? (
              <DownOutlined color={getColor(i?.affiliateLevel)} />
            ) : (
              <div />
            )}{" "}
            {i?.email + " " + i?.fullName}
          </div>
          {i?.childs?.length ? (
            <>
              {i?.childs?.map((i1: any) => (
                <div className="ps-4">
                  <div
                    className="mb-2"
                    style={{
                      color: getColor(i1?.affiliateLevel),
                    }}
                  >
                    {i1?.childs?.length ? (
                      <DownOutlined color={getColor(i1?.affiliateLevel)} />
                    ) : (
                      <div />
                    )}{" "}
                    {i1?.email + " " + i1?.fullName}
                  </div>
                  {i1?.childs?.length ? (
                    <>
                      {i1?.childs?.map((i2: any) => (
                        <div className="ps-4">
                          <div
                            className="mb-2"
                            style={{
                              color: getColor(i2?.affiliateLevel),
                            }}
                          >
                            {i2?.childs?.length ? (
                              <DownOutlined
                                color={getColor(i2?.affiliateLevel)}
                              />
                            ) : (
                              <div />
                            )}{" "}
                            {i2?.email + " " + i2?.fullName}
                          </div>
                          {i2?.childs?.length ? (
                            <>
                              {i2?.childs?.map((i3: any) => (
                                <div className="ps-4">
                                  <div
                                    className="mb-2"
                                    style={{
                                      color: getColor(i3?.affiliateLevel),
                                    }}
                                  >
                                    {i3?.childs?.length ? (
                                      <DownOutlined
                                        color={getColor(i3?.affiliateLevel)}
                                      />
                                    ) : (
                                      <div />
                                    )}{" "}
                                    {i3?.email + " " + i3?.fullName}
                                  </div>
                                  {i3?.childs?.length ? (
                                    <>
                                      {i3?.childs?.map((i4: any) => (
                                        <div className="ps-4">
                                          <div
                                            className="mb-2"
                                            style={{
                                              color: getColor(
                                                i4?.affiliateLevel
                                              ),
                                            }}
                                          >
                                            {i4?.childs?.length ? (
                                              <DownOutlined
                                                color={getColor(
                                                  i4?.affiliateLevel
                                                )}
                                              />
                                            ) : (
                                              <div />
                                            )}{" "}
                                            {i4?.email + " " + i4?.fullName}
                                          </div>
                                          {i4?.childs?.length ? (
                                            <>
                                              {i4?.childs?.map((i5: any) => (
                                                <div className="ps-4">
                                                  <div
                                                    className="mb-2"
                                                    style={{
                                                      color: getColor(
                                                        i5?.affiliateLevel
                                                      ),
                                                    }}
                                                  >
                                                    {i5?.childs?.length ? (
                                                      <DownOutlined
                                                        color={getColor(
                                                          i5?.affiliateLevel
                                                        )}
                                                      />
                                                    ) : (
                                                      <div />
                                                    )}{" "}
                                                    {i5?.email +
                                                      " " +
                                                      i5?.fullName}
                                                  </div>
                                                  {i5?.childs?.length ? (
                                                    <>
                                                      {i5?.childs?.map(
                                                        (i6: any) => (
                                                          <div className="ps-4">
                                                            <div
                                                              className="mb-2"
                                                              style={{
                                                                color: getColor(
                                                                  i6?.affiliateLevel
                                                                ),
                                                              }}
                                                            >
                                                              {i6?.childs
                                                                ?.length ? (
                                                                <DownOutlined
                                                                  color={getColor(
                                                                    i6?.affiliateLevel
                                                                  )}
                                                                />
                                                              ) : (
                                                                <div />
                                                              )}{" "}
                                                              {i6?.email +
                                                                " " +
                                                                i6?.fullName}
                                                            </div>
                                                            {i6?.childs
                                                              ?.length ? (
                                                              <>
                                                                {i6?.childs?.map(
                                                                  (i7: any) => (
                                                                    <div className="ps-4">
                                                                      <div
                                                                        className="mb-2"
                                                                        style={{
                                                                          color:
                                                                            getColor(
                                                                              i7?.affiliateLevel
                                                                            ),
                                                                        }}
                                                                      >
                                                                        {i7
                                                                          ?.childs
                                                                          ?.length ? (
                                                                          <DownOutlined
                                                                            color={getColor(
                                                                              i7?.affiliateLevel
                                                                            )}
                                                                          />
                                                                        ) : (
                                                                          <div />
                                                                        )}{" "}
                                                                        {i7?.email +
                                                                          " " +
                                                                          i7?.fullName}
                                                                      </div>
                                                                      {i7
                                                                        ?.childs
                                                                        ?.length ? (
                                                                        <>
                                                                          {i7?.childs?.map(
                                                                            (
                                                                              i8: any
                                                                            ) => (
                                                                              <div className="ps-4">
                                                                                <div
                                                                                  className="mb-2"
                                                                                  style={{
                                                                                    color:
                                                                                      getColor(
                                                                                        i8?.affiliateLevel
                                                                                      ),
                                                                                  }}
                                                                                >
                                                                                  {i8
                                                                                    ?.childs
                                                                                    ?.length ? (
                                                                                    <DownOutlined
                                                                                      color={getColor(
                                                                                        i8?.affiliateLevel
                                                                                      )}
                                                                                    />
                                                                                  ) : (
                                                                                    <div />
                                                                                  )}{" "}
                                                                                  {i8?.email +
                                                                                    " " +
                                                                                    i8?.fullName}
                                                                                </div>
                                                                                {i8
                                                                                  ?.childs
                                                                                  ?.length ? (
                                                                                  <>
                                                                                    {i8?.childs?.map(
                                                                                      (
                                                                                        i9: any
                                                                                      ) => (
                                                                                        <div className="ps-4">
                                                                                          <div
                                                                                            className="mb-2"
                                                                                            style={{
                                                                                              color:
                                                                                                getColor(
                                                                                                  i9?.affiliateLevel
                                                                                                ),
                                                                                            }}
                                                                                          >
                                                                                            {i9
                                                                                              ?.childs
                                                                                              ?.length ? (
                                                                                              <DownOutlined
                                                                                                color={getColor(
                                                                                                  i9?.affiliateLevel
                                                                                                )}
                                                                                              />
                                                                                            ) : (
                                                                                              <div />
                                                                                            )}{" "}
                                                                                            {i9?.email +
                                                                                              " " +
                                                                                              i9?.fullName}
                                                                                          </div>
                                                                                        </div>
                                                                                      )
                                                                                    )}
                                                                                  </>
                                                                                ) : (
                                                                                  <div />
                                                                                )}
                                                                              </div>
                                                                            )
                                                                          )}
                                                                        </>
                                                                      ) : (
                                                                        <div />
                                                                      )}
                                                                    </div>
                                                                  )
                                                                )}
                                                              </>
                                                            ) : (
                                                              <div />
                                                            )}
                                                          </div>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </div>
                                              ))}
                                            </>
                                          ) : (
                                            <div />
                                          )}
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            <div />
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </>
          ) : (
            <div />
          )}
        </div>
      )),
    },
  ];

  const items: CollapseProps["items"] = data?.map((i: any) => {
    return {
      key: i?._id,
      label: (
        <div
          style={{ color: getColor(i?.affiliateLevel) }}
          className="btn border border-0 p-0"
          onClick={() => {
            setChooseUser(i);
            getTree(i?._id);
          }}
        >
          {i?.email + " " + i?.fullName}
        </div>
      ),
      children: i?.childs?.map((i1: any) => {
        return (
          <div>
            <div
              className="mb-2"
              style={{ color: getColor(i1?.affiliateLevel) }}
            >
              {i1?.childs?.length ? (
                <DownOutlined color={getColor(i1?.affiliateLevel)} />
              ) : (
                <div />
              )}{" "}
              {i1?.email + " " + i1?.fullName}
            </div>
            {i1?.childs?.length ? (
              <>
                {i1?.childs?.map((i2: any) => {
                  return (
                    <div className="ps-4">
                      <div
                        className="mb-2"
                        style={{ color: getColor(i2?.affiliateLevel) }}
                      >
                        {i2?.childs?.length ? (
                          <DownOutlined color={getColor(i2?.affiliateLevel)} />
                        ) : (
                          <div />
                        )}{" "}
                        {i2?.email + " " + i2?.fullName}
                      </div>
                      {i2?.childs?.length ? (
                        <>
                          {i2?.childs?.map((i3: any) => (
                            <div className="ps-4">
                              <div
                                className="mb-2"
                                style={{ color: getColor(i3?.affiliateLevel) }}
                              >
                                {i3?.childs?.length ? (
                                  <DownOutlined
                                    color={getColor(i3?.affiliateLevel)}
                                  />
                                ) : (
                                  <div />
                                )}{" "}
                                {i3?.email + " " + i3?.fullName}
                              </div>
                              {i3?.childs?.length ? (
                                <>
                                  {i3?.childs?.map((i4: any) => (
                                    <div className="ps-4">
                                      <div
                                        className="mb-2"
                                        style={{
                                          color: getColor(i4?.affiliateLevel),
                                        }}
                                      >
                                        {i4?.childs?.length ? (
                                          <DownOutlined
                                            color={getColor(i4?.affiliateLevel)}
                                          />
                                        ) : (
                                          <div />
                                        )}{" "}
                                        {i4?.email + " " + i4?.fullName}
                                      </div>
                                      {i4?.childs?.length ? (
                                        <>
                                          {i4?.childs?.map((i5: any) => (
                                            <div className="ps-4">
                                              <div
                                                className="mb-2"
                                                style={{
                                                  color: getColor(
                                                    i5?.affiliateLevel
                                                  ),
                                                }}
                                              >
                                                {i5?.childs?.length ? (
                                                  <DownOutlined
                                                    color={getColor(
                                                      i5?.affiliateLevel
                                                    )}
                                                  />
                                                ) : (
                                                  <div />
                                                )}{" "}
                                                {i5?.email + " " + i5?.fullName}
                                              </div>
                                              {i5?.childs?.length ? (
                                                <>
                                                  {i5?.childs?.map(
                                                    (i6: any) => (
                                                      <div className="ps-4">
                                                        <div
                                                          className="mb-2"
                                                          style={{
                                                            color: getColor(
                                                              i6?.affiliateLevel
                                                            ),
                                                          }}
                                                        >
                                                          {i6?.childs
                                                            ?.length ? (
                                                            <DownOutlined
                                                              color={getColor(
                                                                i6?.affiliateLevel
                                                              )}
                                                            />
                                                          ) : (
                                                            <div />
                                                          )}{" "}
                                                          {i6?.email +
                                                            " " +
                                                            i6?.fullName}
                                                        </div>
                                                        {i6?.childs?.length ? (
                                                          <>
                                                            {i6?.childs?.map(
                                                              (i7: any) => (
                                                                <div className="ps-4">
                                                                  <div
                                                                    className="mb-2"
                                                                    style={{
                                                                      color:
                                                                        getColor(
                                                                          i7?.affiliateLevel
                                                                        ),
                                                                    }}
                                                                  >
                                                                    {i7?.childs
                                                                      ?.length ? (
                                                                      <DownOutlined
                                                                        color={getColor(
                                                                          i7?.affiliateLevel
                                                                        )}
                                                                      />
                                                                    ) : (
                                                                      <div />
                                                                    )}{" "}
                                                                    {i7?.email +
                                                                      " " +
                                                                      i7?.fullName}
                                                                  </div>
                                                                  {i7?.childs
                                                                    ?.length ? (
                                                                    <>
                                                                      {i7?.childs?.map(
                                                                        (
                                                                          i8: any
                                                                        ) => (
                                                                          <div className="ps-4">
                                                                            <div
                                                                              className="mb-2"
                                                                              style={{
                                                                                color:
                                                                                  getColor(
                                                                                    i8?.affiliateLevel
                                                                                  ),
                                                                              }}
                                                                            >
                                                                              {i8
                                                                                ?.childs
                                                                                ?.length ? (
                                                                                <DownOutlined
                                                                                  color={getColor(
                                                                                    i8?.affiliateLevel
                                                                                  )}
                                                                                />
                                                                              ) : (
                                                                                <div />
                                                                              )}{" "}
                                                                              {i8?.email +
                                                                                " " +
                                                                                i8?.fullName}
                                                                            </div>
                                                                            {i8
                                                                              ?.childs
                                                                              ?.length ? (
                                                                              <>
                                                                                {i8?.childs?.map(
                                                                                  (
                                                                                    i9: any
                                                                                  ) => (
                                                                                    <div className="ps-4">
                                                                                      <div
                                                                                        className="mb-2"
                                                                                        style={{
                                                                                          color:
                                                                                            getColor(
                                                                                              i9?.affiliateLevel
                                                                                            ),
                                                                                        }}
                                                                                      >
                                                                                        {i9
                                                                                          ?.childs
                                                                                          ?.length ? (
                                                                                          <DownOutlined
                                                                                            color={getColor(
                                                                                              i9?.affiliateLevel
                                                                                            )}
                                                                                          />
                                                                                        ) : (
                                                                                          <div />
                                                                                        )}{" "}
                                                                                        {i9?.email +
                                                                                          " " +
                                                                                          i9?.fullName}
                                                                                      </div>
                                                                                    </div>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            ) : (
                                                                              <div />
                                                                            )}
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <div />
                                                                  )}
                                                                </div>
                                                              )
                                                            )}
                                                          </>
                                                        ) : (
                                                          <div />
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <div />
                                              )}
                                            </div>
                                          ))}
                                        </>
                                      ) : (
                                        <div />
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div />
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div />
            )}
          </div>
        );
      }),
    };
  });

  const onChange = (key: string | string[]) => {
    console.log(key);
    // getTree(key);
  };

  return (
    <div className="p-4">
      <div className="fw-semibold fs-4">Cây giới thiệu</div>
      <div className="d-flex justify-content-between align-items-center py-4">
        <div
          onClick={() => {
            getTree("");
            setDataSearch(null);
          }}
          className="btn border border-0 text-primary"
        >
          Làm mới
          <RetweetOutlined />
        </div>
        <Search
          placeholder="Tìm kiếm tài khoản"
          onSearch={onSearch}
          style={{ width: 300 }}
        />
      </div>
      <div className="fw-semibold fs-5">Cấp bậc</div>
      <div className="row pb-4">
        {level.map((item, index) => {
          return (
            <div
              key={item.id}
              className="d-flex align-items-center"
              style={{
                width: 200,
                margin: 8,
              }}
            >
              <div
                style={{
                  backgroundColor: item.color,
                  width: 30,
                  height: 30,
                  minWidth: 30,
                  marginRight: 8,
                }}
              />
              <div
                style={{
                  color: item.color,
                }}
              >
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div
            className="fw-semibold fs-5 pb-3"
            style={{
              color: getColor(chooseUser?.affiliateLevel),
            }}
          >
            {chooseUser?.email}
          </div>
          {dataSearch ? (
            <Collapse onChange={onChange} items={itemsSearch} />
          ) : (
            <Collapse onChange={onChange} items={items} />
          )}
        </div>
      )}
    </div>
  );
}
