import client from "./Client";

const Product = {
  async createProduct(params: any) {
    try {
      const res = await client.post(`/products/create`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async updateProduct(params: any) {
    try {
      const res = await client.post(`/products/update`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async deleteProduct(params: any) {
    try {
      const res = await client.post(`/products/delete`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async listProduct(params: any) {
    try {
      const res = await client.post(`/products/list`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async detailProduct(id: any) {
    try {
      const res = await client.get(`/products/detail/${id}`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },
};

export default Product;
