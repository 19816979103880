import {
  CloseCircleFilled,
  FallOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Modal, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteNew, listNew } from "src/Components/Features/news/NewSlice";
import { DataType } from "src/Constant/Interface";
import { API_URL } from "src/Controller/Client";

export default function News() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [dataNews, setDataNews] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openDeleteNew, setOpenDeleteNew] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    getNew();
  }, []);

  const getNew = async () => {
    const res: any = await dispatch(listNew({}));
    console.log("res news----", res);

    if (res.payload.status == true) {
      setDataNews(res?.payload?.categoris);
    }
  };

  const onDeleteNew = async () => {
    const params = {
      newID: chooseItem?.newID,
    };
    const resp: any = await dispatch(deleteNew(params));
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      getNew();
      setOpenDeleteNew(false);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "1",
      render: (t, _: any) => (
        <div>
          <img
            src={`${API_URL}${_?.urlImage}`}
            style={{ width: 50, height: 50 }}
          />
          <div className="mt-1 fw-semibold text-primary">{t}</div>
        </div>
      ),
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "createdAt",
      key: "2",
      render: (_: any) => (
        <div className="fw-semibold">
          {moment(_).format("DD/MM/YYYY hh:mm")}
        </div>
      ),
    },
    {
      title: "Thời gian",
      dataIndex: "updatedAt",
      key: "3",
      render: (_: any) => (
        <div className="fw-semibold">
          {moment(_).format("DD/MM/YYYY hh:mm")}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "4",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/news/update", { state: { item: chooseItem } })
          }
          className="btn border border-0 text-primary"
        >
          Cập nhật
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => setOpenDeleteNew(true)}
          className="btn border border-0 text-danger"
        >
          Xoá
        </button>
      ),
      key: "1",
    },
  ];

  return (
    <div className="p-4">
      <>{contextHolder}</>
      <div className="fw-semibold fs-4">Danh sách thông báo</div>
      <Button
        onClick={() => navigate("/news/add")}
        className="border border-1 border-primary text-primary my-2"
      >
        + Thêm mới
      </Button>

      <Table columns={columns} dataSource={dataNews} />
      <Modal
        title="Bạn có chắc chắn muốn xoá sản phẩm này"
        open={openDeleteNew}
        onOk={onDeleteNew}
        onCancel={() => setOpenDeleteNew(false)}
      ></Modal>
    </div>
  );
}
