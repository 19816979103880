import Colors from "../Commom/Colors";

export const HomeShow = [
  {
    id: 0,
    text: "Điểm điểm danh",
    color: Colors.YELLOW_1,
  },
  {
    id: 1,
    text: "Số khách hàng",
    color: Colors.RED_4,
  },
  {
    id: 2,
    text: "Khách đăng nhập tháng",
    color: Colors.GREEN_1,
  },
  {
    id: 3,
    text: "Khách đăng ký tháng",
    color: Colors.BLUE,
  },
];


export const level = [
  {
    id: 0,
    label: 'Thành viên',
    value: 'member',
    color: Colors.BLACK,

  },
  {
    id: 1,
    label: 'Cộng tác viên',
    value: 'collaborator',
    color: 'brown',

  },
  {
    id: 2,
    label: 'Khởi nghiệp',
    value: 'start_up',
    color: 'purple',

  },
  {
    id: 3,
    label: 'Phó phòng kinh doanh',
    value: 'deputy_business',
    color: Colors.C_BLUE,

  },
  {
    id: 4,
    label: 'Trưởng phòng kinh doanh',
    value: 'sales_manager',
    color: Colors.PRIMARY,

  },
  {
    id: 5,
    label: 'Giám đốc kinh doanh',
    value: 'business_director',
    color: '#FFCC33',

  },
  {
    id: 6,
    label: 'Phó tổng giám đốc',
    value: 'deputy_general_manager',
    color: Colors.RED,

  },
]

export const userPermissions = [
  {
    id: 0,
    text: 'Ví tích luỹ',
    option: [
      {
        id: 0,
        text: 'Thêm mới trả thưởng'
      },
      {
        id: 1,
        text: 'Danh sách trả thưởng'
      },
      {
        id: 2,
        text: 'Cập nhật trả thưởng'
      },
      {
        id: 3,
        text: 'Xoá trả thưởng'
      },
      {
        id: 4,
        text: 'Danh sách tích luỹ'
      },
    ]
  },
  {
    id: 1,
    text: 'Danh mục sản phẩm',
    option: [
      {
        id: 0,
        text: 'Danh sách danh mục'
      },
      {
        id: 1,
        text: 'Chi tiết danh mục'
      },
      {
        id: 2,
        text: 'Thêm mới danh mục'
      },
      {
        id: 3,
        text: 'Cập nhật danh mục'
      },
      {
        id: 4,
        text: 'Xoá danh mục'
      },
    ]
  },
  {
    id: 2,
    text: 'Sản phẩm',
    option: [
      {
        id: 0,
        text: 'Danh sách sản phẩm'
      },
      {
        id: 1,
        text: 'Chi tiết sản phẩm'
      },
      {
        id: 2,
        text: 'Thêm mới sản phẩm'
      },
      {
        id: 3,
        text: 'Cập nhật sản phẩm'
      },
      {
        id: 4,
        text: 'Xoá sản phẩm'
      },
    ]
  },
  {
    id: 2,
    text: 'Khuyến mãi',
    option: [
      {
        id: 0,
        text: 'Danh sách khuyến mãi'
      },
      {
        id: 1,
        text: 'Chi tiết khuyến mãi'
      },
      {
        id: 2,
        text: 'Thêm mới khuyến mãi'
      },
      {
        id: 3,
        text: 'Cập nhật khuyến mãi'
      },
      {
        id: 4,
        text: 'Xoá khuyến mãi'
      },
    ]
  },
  {
    id: 3,
    text: 'Ví chính',
    option: [
      {
        id: 0,
        text: 'Thêm mới ngân hàng'
      },
      {
        id: 1,
        text: 'Danh sách ngân hàng'
      },
      {
        id: 2,
        text: 'Cập nhật ngân hàng'
      },
      {
        id: 3,
        text: 'Xoá ngân hàng'
      },
      {
        id: 4,
        text: 'Lịch sử giao dịch'
      },
      {
        id: 5,
        text: 'Xác nhận thành công'
      },
      {
        id: 6,
        text: 'Xác nhận thất bại'
      },
      {
        id: 7,
        text: 'Chi tiết giao dịch'
      },
      {
        id: 8,
        text: 'Xuất file lịch sử giao dịch'
      },
    ]
  },
  {
    id: 4,
    text: 'Hoá đơn',
    option: [
      {
        id: 0,
        text: 'Danh sách hoá đơn'
      },
      {
        id: 1,
        text: 'Chi tiết hoá đơn'
      },
      {
        id: 2,
        text: 'Cập nhật hoá đơn'
      },
      {
        id: 3,
        text: 'Xoá hoá đơn'
      },
      {
        id: 4,
        text: 'Huỷ đơn hàng'
      },
      {
        id: 5,
        text: 'Xác nhận thành công'
      },
      {
        id: 6,
        text: 'Thanh toán thành công'
      },
      {
        id: 7,
        text: 'Thanh toán thất bại'
      },
      {
        id: 8,
        text: 'Xác nhận giao hàng'
      },
      {
        id: 9,
        text: 'Giao hàng thành công'
      },
      {
        id: 10,
        text: 'Giao hàng thất bại'
      },
      {
        id: 11,
        text: 'Xuất file danh sách hoá đơn'
      },
    ]
  },
  {
    id: 5,
    text: 'Tài khoản khách hàng',
    option: [
      {
        id: 0,
        text: 'Danh sách khách hàng'
      },
      {
        id: 1,
        text: 'Chi tiết khách hàng'
      },
      {
        id: 2,
        text: 'Cập nhật khách hàng'
      },
      {
        id: 3,
        text: 'Khoá tài khoản'
      },
      {
        id: 4,
        text: 'Mở khoá tài khoản'
      },
      {
        id: 5,
        text: 'Thay đổi email'
      },
      {
        id: 6,
        text: 'Thay đổi mật khẩu'
      },
      {
        id: 7,
        text: 'Khôi phục mật khẩu'
      },
      {
        id: 8,
        text: 'Khôi phục tài khoản'
      },
      {
        id: 9,
        text: 'Xem cấy giới thiệu'
      },
      {
        id: 10,
        text: 'Tìm kiếm cây giới thiệu'
      },
      {
        id: 11,
        text: 'Thay đổi cấp bậc'
      },
      {
        id: 12,
        text: 'Thay đổi số dư ví'
      },
    ]
  },
  {
    id: 6,
    text: 'Thồng báo',
    option: [
      {
        id: 0,
        text: 'Danh sách thông báo'
      },
      {
        id: 1,
        text: 'Chi tiết thông báo'
      },
      {
        id: 2,
        text: 'Thêm mới thông báo'
      },
      {
        id: 3,
        text: 'Cập nhật thông báo'
      },
      {
        id: 4,
        text: 'Xoá thông báo'
      },
    ]
  },
  {
    id: 7,
    text: 'Báo cáo & Trang chủ',
    option: [
      {
        id: 0,
        text: 'Toàn bộ báo cáo'
      },
    ]
  },
  {
    id: 8,
    text: 'Tài khoản nhân viên',
    option: [
      {
        id: 0,
        text: 'Danh sách nhân viên'
      },
      {
        id: 1,
        text: 'Chi tiết nhân viên'
      },
      {
        id: 2,
        text: 'Thêm mới nhân viên'
      },
      {
        id: 3,
        text: 'Cập nhật nhân viên'
      },
      {
        id: 4,
        text: 'Đặt lại mật khẩu'
      },
      {
        id: 5,
        text: 'Khoá tài khoản'
      },
      {
        id: 6,
        text: 'Mở khoá tài khoản'
      },
    ]
  },
  {
    id: 9,
    text: 'Cấu hình',
    option: [
      {
        id: 0,
        text: 'Cập nhật cấu hình rút tiền'
      },
      {
        id: 1,
        text: 'Chi tiết cấu hình rút tiền'
      },
    ]
  },
]

export const listStatus = [
  {
    value: 'Success',
    label: 'Thành công'
  },
  {
    value: 'Pending',
    label: 'Đang xử lý'
  },
  {
    value: 'Cancel',
    label: 'Thất bại'
  },
]

export const list_product = [
  {
    value: 'Affiliate',
    label: 'Sản phẩm liên kết'
  },
  {
    value: 'Monopoly',
    label: 'Sản phẩm độc quyền'
  },
  {
    value: 'Sponsor',
    label: 'Sản phẩm tài trợ'
  },
  {
    value: 'Agency',
    label: 'Gói đại lý'
  },
]

export const mapListProduct: any = {
  "Affiliate": 'Sản phẩm liên kết',
  "Monopoly": "Sản phẩm độc quyền",
  "Sponsor": "Sản phẩm tài trợ",
  "Agency": "Gói đại lý",
}