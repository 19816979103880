import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Product from "src/Controller/Product";


const ProductInitialState: any = {

};

export const createProduct = createAsyncThunk(
    "product/createProduct",
    async (params: any) => {
        const resp = await Product.createProduct(params);
        return resp;
    }
);

export const updateProduct = createAsyncThunk(
    "product/updateProduct",
    async (params: any) => {
        const resp = await Product.updateProduct(params);
        return resp;
    }
);

export const deleteProduct = createAsyncThunk(
    "product/deleteProduct",
    async (params: any) => {
        const resp = await Product.deleteProduct(params);
        return resp;
    }
);

export const listProduct = createAsyncThunk(
    "product/listProduct",
    async (params: any) => {
        const resp = await Product.listProduct(params);
        return resp;
    }
);

export const detailProduct = createAsyncThunk(
    "product/detailProduct",
    async (params: any) => {
        const resp = await Product.detailProduct(params);
        return resp;
    }
);

const product = createSlice({
    name: "product",
    initialState: ProductInitialState,
    reducers: {

    },
});

const { actions, reducer } = product;
export default reducer;