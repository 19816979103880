import {
  CheckOutlined,
  CloseOutlined,
  MoreOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import formatNumber from "src/Commom/FormatNumber";
import Loading from "src/Commom/Loading";
import {
  deleteProduct,
  listProduct,
} from "src/Components/Features/product/ProductSlice";
import { selectLoading } from "src/Components/Redux/selector";
import { list_product } from "src/Constant/Array";
import { DataType } from "src/Constant/Interface";

export default function Product() {
  const { Search } = Input;
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch<any>();
  const [dataProduct, setDataProduct] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [typeProduct, setTypeProduct] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
  });

  const onSearch = (value: any, _e: any, info: any) => {
    console.log(value);
    setParams((prev: any) => ({ ...prev, search: { keyword: value } }));
  };

  useEffect(() => {
    getProduct();
  }, [params]);

  const getProduct = async () => {
    const resp: any = await dispatch(listProduct(params));
    if (resp.payload.status === true) {
      setDataProduct(resp?.payload?.products);
    }
    console.log("resp", resp);
  };

  const onChange = (page: number) => {
    setParams((prev: any) => ({ ...prev, page: page }));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "",
      key: "0",
      render: (_, index: any, e) => <div>{e + 1}</div>,
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "1",
    },
    {
      title: "Loại sản phẩm",
      dataIndex: "productType",
      key: "2",
      render: (_: any) => (
        <div>
          {_ === "Affiliate"
            ? "Sản phẩm liên kết"
            : _ === "Monopoly"
            ? "Sản phẩm độc quyền"
            : _ === "Sponsor"
            ? "Sản phẩm tài trợ"
            : _ === "Agency"
            ? "Gói đại lý"
            : ""}
        </div>
      ),
    },
    {
      title: "Sản phẩm hot",
      dataIndex: "showHot",
      key: "11",
      render: (_: any) => (
        <div>
          {_ === true ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : (
            <CloseOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
    },
    {
      title: "Giá bán",
      dataIndex: "price",
      key: "3",
      render: (_: any) => <div className="fw-bold">{formatNumber(_)}đ</div>,
    },
    {
      title: "Đã bán",
      dataIndex: "sold",
      key: "4",
      render: (_: any) => <div className="fw-bold">{_}</div>,
    },
    {
      title: "Danh mục sản phẩm",
      dataIndex: "danhmuc",
      key: "5",
    },
    {
      title: "Ngày phát hành",
      dataIndex: "releaseDate",
      key: "6",
      render: (_: any) => <div>{moment(_).format("DD/MM/YYYY")}</div>,
    },
    {
      title: "",
      dataIndex: "productID",
      key: "7",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/product/update", { state: { productId: chooseItem } })
          }
          className="btn border border-0 text-primary"
        >
          Cập nhật
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => setOpenDeleteProduct(true)}
          className="btn border border-0 text-danger"
        >
          Xoá
        </button>
      ),
      key: "1",
    },
  ];

  const onDeleteProduct = async () => {
    const params = {
      productID: chooseItem,
    };
    const resp: any = await dispatch(deleteProduct(params));
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      getProduct();
      setOpenDeleteProduct(false);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  return (
    <div className="p-4">
      <>{contextHolder}</>
      <Button
        onClick={() => navigate("/product/add")}
        className="border border-1 border-primary text-primary"
      >
        + Tạo sản phẩm
      </Button>
      <div className="py-2">
        <div>Bộ lọc</div>
        <Select
          showSearch={false}
          style={{ width: 200 }}
          className="mt-1"
          placeholder={"Chọn loại sản phẩm"}
          optionFilterProp="children"
          onChange={(value, opt: any) =>
            setParams((prev: any) => ({
              ...prev,
              search: { productType: opt.value },
            }))
          }
          filterOption={(input, option: any) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA: any, optionB: any) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={list_product}
        />
        <Search
          placeholder="Tìm kiếm sản phẩm"
          onSearch={onSearch}
          style={{ width: 200 }}
          className="mt-1 ms-2"
        />
      </div>
      <button
        onClick={() => {
          setParams({
            page: 1,
            limit: 10,
          });
        }}
        className="btn border border-1 p-0 rounded border-warning p-1 mb-2 text-warning"
      >
        Làm mới <RetweetOutlined />{" "}
      </button>
      <div className="d-none d-md-block">
        {isLoading ? (
          <Loading />
        ) : (
          <Table
            columns={columns}
            dataSource={dataProduct}
            pagination={false}
          />
        )}
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Pagination defaultCurrent={1} onChange={onChange} total={1000} />
        </div>
      </div>
      <div className="d-block d-md-none">
        {dataProduct.map((item: any) => {
          return (
            <div
              key={item?._id}
              className="p-3 d-grid gap-3 border-2 border-bottom"
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Tên sản phẩm</div>
                <div className="fw-semibold">{item?.productName}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Loại sản phẩm</div>
                <div>
                  {item?.productType === "Affiliate"
                    ? "Sản phẩm liên kết"
                    : item?.productType === "Monopoly"
                    ? "Sản phẩm độc quyền"
                    : item?.productType === "Sponsor"
                    ? "Sản phẩm tài trợ"
                    : item?.productType === "Agency"
                    ? "Gói đại lý"
                    : ""}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Sản phẩm hot</div>
                <div>
                  {item?.showHot ? (
                    <CheckOutlined style={{ color: "green" }} />
                  ) : (
                    <CloseOutlined style={{ color: "red" }} />
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Giá bán</div>
                <div className="fw-bold">{formatNumber(item?.price)}đ</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Đã bán</div>
                <div className="fw-bold">{item?.sold}</div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Danh mục sản phẩm</div>
                <div className="fw-bolder">{item?.productType}</div>
              </div> */}
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Ngày phát hành</div>
                <div>{moment(item?.releaseDate).format("DD/MM/YYYY")}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Thao tác</div>
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <button
                    className="btn border border-0 fw-bolder text-primary"
                    onClick={() => setChooseItem(item?.productID)}
                  >
                    <MoreOutlined />
                  </button>
                </Dropdown>
              </div>
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Pagination defaultCurrent={1} onChange={onChange} total={1000} />
        </div>
      </div>
      <Modal
        title="Bạn có chắc chắn muốn xoá sản phẩm này"
        open={openDeleteProduct}
        onOk={onDeleteProduct}
        onCancel={() => setOpenDeleteProduct(false)}
      ></Modal>
    </div>
  );
}
