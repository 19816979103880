import React from "react";
import {
  AlertOutlined,
  AreaChartOutlined,
  BankFilled,
  HomeFilled,
  NotificationOutlined,
  SettingFilled,
  ShoppingCartOutlined,
  UserSwitchOutlined,
  WalletFilled,
  WalletOutlined,
  AppstoreOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import type { MenuProps } from "antd/es/menu";
import { useNavigate } from "react-router-dom";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export default function MenuLeft() {
  const navigate = useNavigate();
  const items: MenuItem[] = [
    getItem(
      <div onClick={() => navigate("/home")}>Trang chủ</div>,
      "1",
      <HomeFilled />
    ),
    getItem("Tài khoản", "sub0", <UserSwitchOutlined />, [
      getItem(<div onClick={() => navigate("/customer")}>Khách hàng</div>, "2"),
      getItem(
        <div onClick={() => navigate("/team-model")}>Cây giới thiệu</div>,
        "3"
      ),
      getItem(<div onClick={() => navigate("/manager")}>Nhân viên</div>, "4"),
    ]),
    getItem("Sản phẩm", "sub1", <AppstoreOutlined />, [
      // getItem(
      //   <div onClick={() => navigate("/category")}>Danh mục sản phẩm</div>,
      //   "5"
      // ),
      getItem(
        <div onClick={() => navigate("/product")}>Tất cả sản phẩm</div>,
        "6"
      ),
    ]),
    getItem(
      <div onClick={() => navigate("/order")}>Hóa đơn</div>,
      "7",
      <ShoppingCartOutlined />
    ),
    getItem(
      <div onClick={() => navigate("/promo")}>Khuyến mãi</div>,
      "8",
      <AlertOutlined />
    ),
    getItem(
      <div onClick={() => navigate("/news")}>Thông báo</div>,
      "9",
      <NotificationOutlined />
    ),
    getItem("Ví tích lũy", "sub2", <WalletOutlined />, [
      getItem(
        <div onClick={() => navigate("/investment")}>Chính sách tích lũy</div>,
        "10"
      ),
      getItem(
        <div onClick={() => navigate("/accumulative")}>Danh sách tích lũy</div>,
        "11"
      ),
    ]),
    getItem(
      <div onClick={() => navigate("/bank")}>Ngân hàng</div>,
      "12",
      <BankFilled />
    ),
    getItem(
      <div onClick={() => navigate("/wallet")}>Ví chính</div>,
      "13",
      <WalletFilled />
    ),
    getItem("Báo cáo", "sub3", <AreaChartOutlined />, [
      getItem(
        <div onClick={() => navigate("/report/order")}>Hóa đơn</div>,
        "14"
      ),
      getItem(
        <div onClick={() => navigate("/report/product")}>Sản phẩm</div>,
        "15"
      ),
      getItem(
        <div onClick={() => navigate("/report/customer")}>Khách hàng</div>,
        "16"
      ),
      getItem(
        <div onClick={() => navigate("/report/accumulative")}>Ví tích lũy</div>,
        "17"
      ),
      getItem(
        <div onClick={() => navigate("/report/wallet")}>Ví chính</div>,
        "18"
      ),
    ]),
    getItem(
      <div onClick={() => navigate("/config")}>Cấu hình</div>,
      "19",
      <SettingFilled />
    ),
  ];

  return (
    <div className="col col-md-2">
      <div className="d-none d-md-block">
        <Menu
          style={{ marginTop: 8 }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["1"]}
          mode={"inline"}
          theme={"light"}
          items={items}
        />
      </div>
    </div>
  );
}
