import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  DatePickerProps,
  Input,
  Modal,
  Upload,
  UploadFile,
} from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNew } from "src/Components/Features/news/NewSlice";
import { uploadImage } from "src/Components/Features/user/userSlice";
import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

export default function AddNews() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [titleName, setTitleName] = useState("");
  const [content, setContent] = useState("");
  const [startDay, setStartDay] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [value, setValue] = useState("");
  const editor = useRef<any>(null);

  const onAdd = async () => {
    const formData: any = new FormData();
    formData.append("image", fileList[0]?.originFileObj);
    const res: any = await dispatch(uploadImage(formData));
    // console.log("res", res);
    const images =
      res?.payload?.data?.find((i: { path: any }) => i?.path) || {};

    const params = {
      title: titleName,
      description: content,
      urlImage: images?.path,
      content: value,
      publicTime: startDay,
      isShowHome: true,
    };

    const resp: any = await dispatch(createNew(params));
    if (resp.payload.status == true) {
      navigate(-1);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    setStartDay(moment(date?.$d).format("YYYY-MM-DD"));
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  return (
    <div className="px-4">
      <div className="row">
        <div className="col-md-6 col-12 bg-white rounded shadow p-4">
          <div className="fw-bolder fs-4 pb-0">Thông tin thông báo</div>
          <div className="d-flex justify-content-between pt-2">
            <div>Tiêu đề (*):</div>
            <Input
              value={titleName}
              style={{
                width: "70%",
              }}
              onChange={(text) => setTitleName(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pt-2">
            <div>Mô tả:</div>
            <Input
              value={content}
              multiple
              style={{
                width: "70%",
              }}
              onChange={(text) => setContent(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pt-2">
            <div>Ngày xuất bản (*):</div>
            <DatePicker style={{ width: "70%" }} onChange={onChange} />
          </div>

          <div className="fw-bolder fs-4 pb-0 pt-3">
            D/s hình ảnh sản phẩm đã chọn
          </div>
          <>
            <Upload
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              maxCount={1}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </div>
      </div>
      <div className="my-4 fw-bolder fs-5">Nội dung</div>
      <JoditEditor
        ref={editor}
        value={value}
        onChange={(value) => setValue(value)}
      />

      <button
        onClick={onAdd}
        className="btn border border-0 text-white bg-primary w-100 mt-4"
      >
        Thêm mới
      </button>
    </div>
  );
}
