import React, { useEffect, useState } from "react";
import { reportGeneral } from "src/Components/Features/user/userSlice";
import { useAppDispatch } from "src/Components/Redux/store";
import type { DatePickerProps } from "antd";
import { DatePicker, Table } from "antd";
import formatNumber from "src/Commom/FormatNumber";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { selectLoading } from "src/Components/Redux/selector";
import Loading from "src/Commom/Loading";

const Order = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [totalReportOrder, setTotalReportOrder] = useState<any>([]);
  const [reportOrderDetail, setReportOrderDetail] = useState([]);
  const [params, setParams] = useState<any>({});
  const [dateFrom, setDateFrom] = useState("");

  useEffect(() => {
    getGeneral();
  }, [params]);

  const getGeneral = async () => {
    const resp: any = await dispatch(reportGeneral(params));
    console.log("resp report-----", resp);
    setTotalReportOrder(resp?.payload?.reportOrder);
    setReportOrderDetail(resp?.payload?.reportOrderDetail);
  };

  const onChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    console.log(dateString);
    setDateFrom(dateString);
  };
  const onChange2: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    setParams((prev: any) => ({
      ...prev,
      search: { dateFrom: dateFrom, dateTo: dateString },
    }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (item, r, index) => <div>{index + 1}</div>,
    },
    {
      title: "Ngày",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{`${item?._id?.day}/${item?._id?.month}/${item?._id?.year}`}</div>
      ),
    },
    {
      title: "Tổng đơn",
      dataIndex: "",
      key: "2",
      render: (item) => <div className="fw-semibold">{item?.totalOrder}</div>,
    },
    {
      title: "Đơn hoàn thành",
      dataIndex: "",
      key: "3",
      render: (item) => (
        <div className="fw-semibold text-success">
          {item?.totalOrderPayment}
        </div>
      ),
    },
    {
      title: "Tổng thanh toán",
      dataIndex: "",
      key: "4",
      render: (item) => (
        <div className="fw-semibold text-primary">
          {formatNumber(item?.totalRevenuePayment)}đ
        </div>
      ),
    },
  ];

  return (
    <div className="p-3">
      <div className="fw-semibold fs-4">Báo cáo đơn hàng</div>
      <div className="d-flex gap-2 justify-content-end">
        <DatePicker
          onChange={onChange}
          placeholder="Từ ngày"
          format={"YYYY/MM/DD"}
        />
        <DatePicker
          onChange={onChange2}
          placeholder="Đến ngày"
          format={"YYYY/MM/DD"}
        />
      </div>
      <div className="row col-lg-11 gap-2 py-5">
        <div className="col-lg-3 d-flex justify-content-between align-items-center">
          <div className="fw-semibold">Tổng đơn</div>
          <div className="fw-semibold text-white py-1 px-2 rounded-3 bg-primary">
            {formatNumber(totalReportOrder[0]?.totalOrder)}
          </div>
        </div>
        <div className="col-lg-3 d-flex justify-content-between align-items-center">
          <div className="fw-semibold">Đơn hoàn thành</div>
          <div className="fw-semibold text-white py-1 px-2 rounded-3 bg-success">
            {formatNumber(totalReportOrder[0]?.totalOrderPayment)}
          </div>
        </div>
        <div className="col-lg-3 d-flex justify-content-between align-items-center">
          <div className="fw-semibold">Đã thanh toán</div>
          <div className="fw-semibold text-white py-1 px-2 rounded-3 bg-success">
            {formatNumber(totalReportOrder[0]?.totalRevenuePayment)}đ
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table columns={columns} dataSource={reportOrderDetail} />
      )}
    </div>
  );
};

export default Order;
