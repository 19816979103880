function formatNumber(x: any) {
  if (x) {
    x = Math.round(x);
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "0";
  }
}

export default formatNumber;
