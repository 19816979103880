import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  message,
  Select,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listBankVN } from "src/Components/Features/user/userSlice";
import {
  createBank,
  deleteBank,
  listBank,
  updateBank,
} from "src/Components/Features/wallet/WalletSlice";
import { DataType } from "src/Constant/Interface";

export default function Bank() {
  const dispatch = useDispatch<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [openAdd, setOpenAdd] = useState(false);
  const [bankNumber, setBankNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [dataListBank, setDataListBank] = useState([]);
  const [chooseBank, setChooseBank] = useState<any>(null);
  const [data, setData] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    getListBank();
    getWalletListBank();
  }, []);

  const getWalletListBank = async () => {
    const resp: any = await dispatch(listBank());
    console.log("resp", resp);
    setData(resp?.payload?.banks);
  };

  const getListBank = async () => {
    const resp: any = await dispatch(listBankVN());
    const data = resp?.payload?.data?.data;
    const mapData = data.map((item: any) => {
      return {
        key: item.id,
        value: item.id,
        label: item.shortName,
        logo: item?.logo,
        bankName: item?.name,
        bankCode: item?.code,
      };
    });
    setDataListBank(mapData);
  };

  const onAdd = async () => {
    const params = {
      bankName: chooseBank?.label,
      bankShortName: chooseBank?.bankName,
      bankCode: chooseBank?.bankCode,
      bankNumber: bankNumber,
      bankAccountName: bankName,
      logo: chooseBank?.logo,
    };
    const resp: any = await dispatch(createBank(params));
    if (resp.payload.status === true) {
      getWalletListBank();
      showSuccess("Thành công!");
      setOpenAdd(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Ngân hàng",
      dataIndex: "bankName",
      key: "0",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Số tài khoản",
      dataIndex: "bankNumber",
      key: "1",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Chủ tài khoản",
      dataIndex: "bankAccountName",
      key: "2",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "3",
      render: (text: any) => {
        return <div>{moment(text).format("DD/MM/YYYY hh:mm")}</div>;
      },
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updatedAt",
      key: "4",
      render: (text: any) => {
        return <div>{moment(text).format("DD/MM/YYYY hh:mm")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "bankConfigID",
      key: "5",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() => setOpenUpdate(true)}
          className="btn border border-0 text-primary"
        >
          Cập nhật
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => setOpenDelete(true)}
          className="btn border border-0 text-danger"
        >
          Xoá
        </button>
      ),
      key: "1",
    },
  ];

  const onUpdate = async () => {
    const params = {
      bankConfigID: chooseItem,
      bankName: chooseBank?.label,
      bankShortName: chooseBank?.bankName,
      bankCode: chooseBank?.bankCode,
      bankNumber: bankNumber,
      bankAccountName: bankName,
      logo: chooseBank?.logo,
      isDelete: false,
    };
    const resp: any = await dispatch(updateBank(params));
    if (resp.payload.status === true) {
      getWalletListBank();
      showSuccess("Thành công!");
      setOpenUpdate(false);
    }
  };

  const onDelete = async () => {
    const params = {
      bankConfigID: chooseItem,
    };
    const resp: any = await dispatch(deleteBank(params));
    if (resp.payload.status === true) {
      getWalletListBank();
      showSuccess("Thành công!");
      setOpenDelete(false);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  return (
    <div className="p-4">
      <div className="fw-bolder fs-4">Danh sách tài khoản ngân hàng</div>
      <>{contextHolder}</>
      <Button
        onClick={() => setOpenAdd(true)}
        className="border border-1 border-primary text-primary my-3"
      >
        + Thêm mới
      </Button>

      <Table columns={columns} dataSource={data} />

      <Modal
        title="Thêm chính sách trả thưởng"
        open={openAdd}
        onOk={onAdd}
        onCancel={() => setOpenAdd(false)}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Tìm kiếm ngân hàng"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={dataListBank}
          onSelect={(i, option) => setChooseBank(option)}
        />
        <Input
          value={bankNumber}
          placeholder={"Số tài khoản"}
          className="my-2"
          onChange={(text) => setBankNumber(text.target.value)}
        />
        <Input
          value={bankName}
          placeholder={"Chủ tài khoản"}
          onChange={(text) => setBankName(text.target.value)}
        />
      </Modal>

      <Modal
        title="Cập nhật tài khoản ngân hàng"
        open={openUpdate}
        onOk={onUpdate}
        onCancel={() => setOpenUpdate(false)}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Tìm kiếm ngân hàng"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={dataListBank}
          onSelect={(i, option) => setChooseBank(option)}
        />
        <Input
          value={bankNumber}
          placeholder={"Số tài khoản"}
          className="my-2"
          onChange={(text) => setBankNumber(text.target.value)}
        />
        <Input
          value={bankName}
          placeholder={"Chủ tài khoản"}
          onChange={(text) => setBankName(text.target.value)}
        />
      </Modal>

      <Modal
        title="Bạn có chắc chắn muốn xoá?"
        open={openDelete}
        onOk={onDelete}
        onCancel={() => setOpenDelete(false)}
      ></Modal>
    </div>
  );
}
