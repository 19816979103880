import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import News from "src/Controller/News";


const NewsInitialState: any = {

};

export const createNew = createAsyncThunk(
    "news/createNew",
    async (params: any) => {
        const resp = await News.createNew(params);
        return resp;
    }
);

export const updateNew = createAsyncThunk(
    "news/updateNew",
    async (params: any) => {
        const resp = await News.updateNew(params);
        return resp;
    }
);

export const deleteNew = createAsyncThunk(
    "news/deleteNew",
    async (params: any) => {
        const resp = await News.deleteNew(params);
        return resp;
    }
);

export const listNew = createAsyncThunk(
    "news/listNew",
    async (params: any) => {
        const resp = await News.listNew(params);
        return resp;
    }
);

export const detailNew = createAsyncThunk(
    "news/detailNew",
    async (params: any) => {
        const resp = await News.detailNew(params);
        return resp;
    }
);

const news = createSlice({
    name: "news",
    initialState: NewsInitialState,
    reducers: {

    },
});

const { actions, reducer } = news;
export default reducer;