import { Input, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { configUpdateFeeWithDraw } from "src/Components/Features/manager/ManagerSlice";

export default function Config() {
  const dispatch = useDispatch<any>();
  const [fee, setFee] = useState("");
  const [withdrawMin, setWithdrawMin] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const addConfig = async () => {
    const params = {
      widthDrawFee: +fee,
      widthDrawMin: +withdrawMin,
    };
    const resp: any = await dispatch(configUpdateFeeWithDraw(params));
    console.log("resp", resp);
    if (resp.payload.status == true) {
      showSuccess("Thành công!");
    } else {
      alert(resp?.payload?.message);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };
  return (
    <div className="p-4">
      <>{contextHolder}</>
      <div className="fw-bolder fs-4">Cấu hình rút tiền</div>
      <div>
        <div className="row mt-4">
          <div className="col-md-2">Số tiền rút tối thiểu (₫):</div>
          <Input
            className="w-50 ms-4"
            value={fee}
            onChange={(text) => setFee(text.target.value)}
          />
        </div>
        <div className="row mt-4">
          <div className="col-md-2">Phí rút tiền (%):</div>
          <Input
            className="w-50 ms-4"
            value={withdrawMin}
            onChange={(text) => setWithdrawMin(text.target.value)}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn border border-0 bg-primary text-white"
            onClick={() => addConfig()}
          >
            Cập nhật
          </button>
        </div>
      </div>
    </div>
  );
}
