import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Admin from "src/Controller/Admin";


const AdminInitialState: any = {

};

export const AdminList = createAsyncThunk(
    "admin/list",
    async (params: any) => {
        const resp = await Admin.list(params);
        return resp;
    }
);

export const AdminUpdate = createAsyncThunk(
    "admin/update",
    async (params: any) => {
        const resp = await Admin.update(params);
        return resp;
    }
);

export const AdminCreate = createAsyncThunk(
    "admin/create",
    async (params: any) => {
        const resp = await Admin.create(params);
        return resp;
    }
);

export const AdminDetail = createAsyncThunk(
    "admin/detail",
    async (id: string) => {
        const resp = await Admin.detail(id);
        return resp;
    }
);

export const AdminLock = createAsyncThunk(
    "admin/lock",
    async (params: any) => {
        const resp = await Admin.lock(params);
        return resp;
    }
);

export const AdminUnlock = createAsyncThunk(
    "admin/unLock",
    async (params: any) => {
        const resp = await Admin.unLock(params);
        return resp;
    }
);

export const AdminResetPassword = createAsyncThunk(
    "admin/resetPassword",
    async (params: any) => {
        const resp = await Admin.resetPassword(params);
        return resp;
    }
);

const admin = createSlice({
    name: "admin",
    initialState: AdminInitialState,
    reducers: {

    },
});

const { actions, reducer } = admin;
export default reducer;