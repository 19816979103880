import { Pagination, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "src/Commom/Colors";
import formatNumber from "src/Commom/FormatNumber";
import Loading from "src/Commom/Loading";
import { allListAccumulative } from "src/Components/Features/accumulation/AccumulationSlice";
import { selectLoading } from "src/Components/Redux/selector";
import { DataType } from "src/Constant/Interface";

export default function Accumulative() {
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAccu(1);
  }, []);

  const getAccu = async (page: number) => {
    const params = {
      page: page,
      limit: 10,
    };
    const resp: any = await dispatch(allListAccumulative(params));
    console.log("resp", resp);
    setData(resp?.payload?.accumulative);
  };

  const onChange = (page: number) => {
    getAccu(page);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "",
      key: "0",
      render: (text: any, index: any, e) => {
        return <div className="fw-semibold">{e + 1}</div>;
      },
    },
    {
      title: "Tài khoản",
      dataIndex: "userInfo",
      key: "1",
      render: (text: any, record: any) => {
        return (
          <div>
            <div className="fw-semibold">{text?.fullName}</div>
            <div className="fw-semibold">{text?.email}</div>
          </div>
        );
      },
    },
    {
      title: "Gói tích luỹ",
      dataIndex: "timeDeposit",
      key: "2",
      render: (text: any, record: any) => {
        return <div className="fw-semibold">{text} tháng</div>;
      },
    },
    {
      title: "Lãi suất",
      dataIndex: "interestRate",
      key: "3",
      render: (text: any, record: any) => {
        return <div className="fw-semibold">{text}%/tháng</div>;
      },
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "4",
      render: (text: any, record: any) => {
        return <div className="fw-semibold">{formatNumber(text)}đ</div>;
      },
    },
    {
      title: "Tiền lãi/ngày",
      dataIndex: "payDaily",
      key: "5",
      render: (text: any, record: any) => {
        return <div className="fw-semibold">{formatNumber(text)}đ</div>;
      },
    },
    {
      title: "Tổng lợi nhuận",
      dataIndex: "profit",
      key: "6",
      render: (text: any, record: any) => {
        return <div className="fw-semibold">{formatNumber(text)}đ</div>;
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "7",
      render: (text: any, record: any) => {
        return (
          <div
            className="fw-semibold rounded text-white"
            style={{
              padding: 4,
              backgroundColor: Colors.GREEN_1,
              fontSize: 10,
            }}
          >
            {text === "Open" ? "Đang thực hiện" : ""}
          </div>
        );
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "createdDate",
      key: "8",
      render: (text: any, record: any) => {
        return (
          <div className="fw-semibold">
            {moment(text).format("DD/MM/YYYY hh:mm")}
          </div>
        );
      },
    },
    {
      title: "Ngày đáo hạn",
      dataIndex: "maturityDate",
      key: "9",
      render: (text: any, record: any) => {
        return (
          <div className="fw-semibold">
            {moment(text).format("DD/MM/YYYY hh:mm")}
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-4">
      <div className="fw-bolder fs-4">Danh sách tích lũy</div>
      <div className="d-none d-md-block">
        {isLoading ? (
          <Loading />
        ) : (
          <Table columns={columns} dataSource={data} pagination={false} />
        )}
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Pagination defaultCurrent={1} onChange={onChange} total={1000} />
        </div>
      </div>
      <div className="d-block d-md-none">
        {data.map((item: any, index: number) => {
          return (
            <div
              key={item?._id}
              className="p-3 d-grid gap-3 border-2 border-bottom"
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">#</div>
                <div className="fw-semibold text-primary">{index + 1}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Tài khoản</div>
                <div>
                  <div className="fw-semibold">{item?.userInfo?.fullName}</div>
                  <div className="fw-semibold">{item?.userInfo?.email}</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Gói tích luỹ</div>
                <div className="fw-semibold">{item?.timeDeposit} tháng</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Lãi suất</div>
                <div className="fw-semibold">{item?.interestRate}%/tháng</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Số tiền</div>
                <div className="fw-semibold">{formatNumber(item?.amount)}đ</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Tiền lãi/ngày</div>
                <div className="fw-semibold">
                  {formatNumber(item?.payDaily)}đ
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Tổng lợi nhuận</div>
                <div className="fw-semibold">{formatNumber(item?.profit)}đ</div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Tình trạng</div>
                <div
                  className="fw-semibold rounded text-white"
                  style={{
                    padding: 4,
                    backgroundColor: Colors.GREEN_1,
                    fontSize: 10,
                  }}
                >
                  {item?.status === "Open" ? "Đang thực hiện" : ""}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Ngày bắt đầu</div>
                <div className="fw-bolder">
                  <div className="fw-semibold">
                    {moment(item?.createdDate).format("DD/MM/YYYY hh:mm")}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-semibold">Ngày đáo hạn</div>
                <div className="fw-semibold">
                  {moment(item?.maturityDate).format("DD/MM/YYYY hh:mm")}
                </div>
              </div>
            </div>
          );
        })}
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Pagination defaultCurrent={1} onChange={onChange} total={1000} />
        </div>
      </div>
    </div>
  );
}
