import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import formatNumber from "src/Commom/FormatNumber";
import {
  createProduct,
  detailProduct,
  updateProduct,
} from "src/Components/Features/product/ProductSlice";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import {
  DatePicker,
  DatePickerProps,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Switch,
  Upload,
  message,
} from "antd";
import {
  CloseCircleFilled,
  CloseCircleOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { uploadImage } from "src/Components/Features/user/userSlice";
import { API_URL } from "src/Controller/Client";
import formatMoneyText from "src/Commom/FormatMoneyText";
import JoditEditor from "jodit-react";
import { mapListProduct } from "src/Constant/Array";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function ProductEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>(null);
  const [aliasName, setAliasName] = useState("");
  const [enterprice, setEnterPrice] = useState("");
  const [comparativePrice, setComparativePrice] = useState("");
  const [price, setPrice] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [weight, setWeight] = useState("");
  const [sku, setSku] = useState("");
  const [barcode, setBarcode] = useState("");
  const [note, setNote] = useState("");
  const [isHot, setIsHot] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [coin, setCoin] = useState("");
  const [cophan, setCophan] = useState("");
  const [informationName, setInformationName] = useState("");
  const [unit, setUnit] = useState("");
  const [content, setContent] = useState("");
  const [startDay, setStartDay] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const editor = useRef<any>(null);
  const [chooseProductType, setChooseProductType] = useState("");
  const [priceVoucher, setPriceVoucher] = useState("");
  const [listCurrentImages, setListCurrentImages] = useState([]);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const resp: any = await dispatch(detailProduct(state.productId));
    console.log("resp", resp);

    if (resp?.payload?.status) {
      const _data = resp?.payload?.product;
      setListCurrentImages(_data?.images);
      setData(_data);
      setAliasName(_data?.productName);
      setEnterPrice(formatNumber(_data?.importPrice));
      setComparativePrice(formatNumber(_data?.priceCompare));
      setPrice(formatNumber(_data?.price));
      setWeight(_data?.weight);
      setSku(_data?.sku);
      setBarcode(_data?.barcode);
      setNote(_data?.descreptionShort);
      setVoucher(_data?.voucherAdded);
      setCoin(_data?.numberOfCoins);
      setCophan(_data?.numberOfStocks);
      setPriceVoucher(_data?.priceVocher);
    }
  };

  const onUpdate = async () => {
    let listImages: any = [];
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const image = fileList[i];
        const formData: any = new FormData();
        formData.append("image", image?.originFileObj);
        const res: any = await dispatch(uploadImage(formData));
        if (res?.payload?.status) {
          console.log("res upload", res);
          const images =
            res?.payload?.data?.map((i: { path: string }) => i?.path) || [];
          listImages.push(images[0]);
        } else {
          alert("Có lỗi xảy ra,vui lòng liên hệ IT");
        }
      }
    }
    const checkListCurrentImages = listCurrentImages.length
      ? listImages.concat(listCurrentImages)
      : listImages;
    const Sprice = price.split(".").join("");
    const params = {
      productID: state.productId,
      productName: aliasName,
      productType: chooseProductType ? chooseProductType : data?.productType,
      price: Sprice,
      priceVocher: priceVoucher,
      priceCompare: +comparativePrice.split(".").join(""),
      importPrice: +enterprice.split(".").join(""),
      voucherAdded: voucher,
      numberOfCoins: coin,
      numberOfStocks: cophan,
      descreptionShort: note,
      status: "Active",
      sku: sku,
      showHot: isHot,
      isDelete: false,
      images: checkListCurrentImages,
      videos: [],
      barcode: barcode,
      categories: [],
      releaseDate: startDay,
      weight: weight,
      info: [
        {
          key: "",
          value: "",
          unit: "",
        },
      ],
    };
    const resp: any = await dispatch(updateProduct(params));
    console.log("resp", resp);
    if (resp.payload.status === true) {
      showSuccess("Cập nhật thành công!");
      getDetail();
      navigate(-1);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    setStartDay(moment(date?.$d).format("YYYY-MM-DD"));
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => setChooseProductType("Affiliate")}
          className="btn border border-0"
        >
          Sản phẩm liên kết
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => setChooseProductType("Monopoly")}
          className="btn border border-0"
        >
          Sản phẩm độc quyền
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => setChooseProductType("Sponsor")}
          className="btn border border-0"
        >
          Sản phẩm tài trợ
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => setChooseProductType("Agency")}
          className="btn border border-0"
        >
          Gói Đại lý
        </div>
      ),
    },
  ];

  const onRemove = (index: number) => {
    const result = listCurrentImages.filter((i, k) => k !== index);
    setListCurrentImages(result);
  };

  const config = {
    uploader: { insertImageAsBase64URI: true },
    readonly: false,
  };

  return (
    <div className="px-4">
      <>{contextHolder}</>
      <div className="fw-semibold fs-4">Cập nhật sản phẩm</div>
      <div className="row">
        <div className="col-md-6 col-12 bg-white rounded shadow p-4">
          <div className="fw-bolder fs-4 pb-0">Thông tin sản phẩm</div>
          <div className="d-flex justify-content-between pb-2">
            <div>Tên sản phẩm (*):</div>
            <Input
              value={aliasName}
              placeholder={"Nhập tên sản phẩm"}
              style={{
                width: "70%",
              }}
              onChange={(text) => setAliasName(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Giá nhập (₫):</div>
            <Input
              value={enterprice}
              style={{
                width: "70%",
              }}
              onChange={(text) =>
                setEnterPrice(formatMoneyText(text.target.value))
              }
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Giá so sánh (₫):</div>
            <Input
              value={comparativePrice}
              style={{
                width: "70%",
              }}
              onChange={(text) =>
                setComparativePrice(formatMoneyText(text.target.value))
              }
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Giá bán (₫):</div>
            <Input
              value={price}
              placeholder={"Nhập giá bán"}
              style={{
                width: "70%",
              }}
              onChange={(text) => setPrice(formatMoneyText(text.target.value))}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Trọng lượng(g):</div>
            <Input
              value={weight}
              placeholder={"Nhập trọng lượng"}
              style={{
                width: "70%",
              }}
              onChange={(text) => setWeight(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>SKU:</div>
            <Input
              value={sku}
              placeholder={"SKU"}
              style={{
                width: "70%",
              }}
              onChange={(text) => setSku(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Barcode:</div>
            <Input
              value={barcode}
              placeholder={"Barcode"}
              style={{
                width: "70%",
              }}
              onChange={(text) => setBarcode(text.target.value)}
            />
          </div>

          <div className="d-flex pb-2">
            <div className="me-4">Sản phẩm hot:</div>
            <Switch onClick={() => setIsHot(!isHot)} />
          </div>
        </div>

        <div className="col-md-5 col-12 bg-white rounded shadow p-4 ms-2">
          <div className="fw-bolder fs-4 pb-0">Thông tin chi tiết</div>
          <Input
            value={informationName}
            placeholder={"Tên thông tin"}
            onChange={(text) => setInformationName(text.target.value)}
          />
          <Input
            value={unit}
            placeholder={"Đơn vị tính"}
            className="my-2"
            onChange={(text) => setUnit(text.target.value)}
          />
          <Input
            value={content}
            placeholder={"Nội dung/giá trị"}
            onChange={(text) => setContent(text.target.value)}
          />

          <div className="fw-bolder fs-4 pb-0">Hình ảnh sản phẩm</div>
          <div className="d-flex gap-3">
            {listCurrentImages[0] !== "" &&
              listCurrentImages?.map((item: any, index: number) => {
                return (
                  <div
                    className="position-relative"
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  >
                    <CloseCircleFilled
                      onClick={() => onRemove(index)}
                      className="btn border border-0"
                      style={{
                        position: "absolute",
                        color: "white",
                        top: -14,
                        right: -14,
                        fontSize: 20,
                      }}
                    />
                    <img
                      src={`${API_URL}/${item}`}
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                );
              })}
          </div>

          <div className="fw-bolder fs-4 pb-0">
            D/s hình ảnh sản phẩm đã chọn
          </div>
          <>
            <Upload
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </div>
      </div>
      <div className="my-3 col-md-11">
        <JoditEditor
          ref={editor}
          value={note}
          config={config}
          onChange={(value) => setNote(value)}
        />
      </div>
      <div className="col-md-6 col-12 bg-white rounded shadow p-4">
        <div className="fw-bolder fs-4 pb-0">Thông tin khác</div>
        <div className="d-flex justify-content-between pb-2">
          <div>Số Voucher quy đổi (₫):</div>
          <Input
            value={voucher}
            placeholder={"Cộng vào ví Voucher khi mua sản phẩm"}
            style={{
              width: "70%",
              height: 34,
            }}
            onChange={(text) => setVoucher(text.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between pb-2">
          <div>Số Coin quy đổi:</div>
          <Input
            value={coin}
            placeholder={"Số lượng Coin được cộng khi mua sản phẩm"}
            style={{
              width: "70%",
              height: 34,
            }}
            onChange={(text) => setCoin(text.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between pb-2">
          <div>Số cổ phần quy đổi:</div>
          <Input
            value={cophan}
            placeholder={"Số cổ phần được cộng khi mua sản phẩm"}
            style={{
              width: "70%",
              height: 34,
            }}
            onChange={(text) => setCophan(text.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between pb-2">
          <div>Loại sản phẩm (*):</div>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div
              className="border border-1 rounded d-flex justify-content-between px-2 align-items-center"
              style={{
                width: "70%",
                height: 34,
              }}
            >
              <div className="fw-semibold">
                {chooseProductType
                  ? mapListProduct[chooseProductType]
                  : mapListProduct[data?.productType]}
              </div>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
        {chooseProductType === "Affiliate" || chooseProductType === "Agency" ? (
          <div className="d-flex justify-content-between pb-2">
            <div>Giá voucher (đ):</div>
            <Input
              value={priceVoucher}
              placeholder={`Số tiền tương ứng`}
              style={{
                width: "70%",
                height: 34,
              }}
              onChange={(text) =>
                setPriceVoucher(formatMoneyText(text.target.value))
              }
            />
          </div>
        ) : (
          <div />
        )}
        <div className="d-flex justify-content-between pb-2">
          <div>Ngày phát hành (*):</div>
          <DatePicker style={{ width: "70%" }} onChange={onChange} />
        </div>
      </div>
      <div className="justify-content-center d-flex mt-4">
        <button
          onClick={onUpdate}
          className="border border-0 btn bg-primary text-white"
        >
          Cập nhật sản phẩm
        </button>
      </div>
    </div>
  );
}
