import {
  MoreOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Pagination,
  Select,
  Table,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import formatMoneyText from "src/Commom/FormatMoneyText";
import formatNumber from "src/Commom/FormatNumber";
import {
  transactionAccepted,
  transactionCancel,
  transactionList,
  walletExportExcel,
} from "src/Components/Features/wallet/WalletSlice";
import { DataType } from "src/Constant/Interface";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Colors from "src/Commom/Colors";
import Loading from "src/Commom/Loading";
import { selectLoading, userInfor } from "src/Components/Redux/selector";
import { API_URL } from "src/Controller/Client";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { listStatus } from "src/Constant/Array";

const arr = [
  {
    id: 0,
    text: "Nạp tiền",
    key: "Deposit",
  },
  {
    id: 1,
    text: "Rút tiền",
    key: "WithDraw",
  },
  {
    id: 2,
    text: "Mua hàng",
    key: "PaymentOrder",
  },
  {
    id: 3,
    text: "Hoa hồng",
    key: "payCommissions",
  },
];

export default function Wallet() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [money, setMoney] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [reason, setReason] = useState("");
  const [openWarningExport, setOpenWarningExport] = useState(false);
  const [emailQuery, setEmailQuery] = useState("");
  const [codeQuery, setCodeQuery] = useState("");
  const [status, setStatus] = useState("");
  const [createFrom, setCreateFrom] = useState("");
  const [createTo, setCreateTo] = useState("");

  const [querySearch, setQuerySearch] = useState<any>({
    page: 1,
    limit: 10,
    type: arr[currentIndex].key,
  });

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    getWalletList();
  }, [querySearch]);

  const getWalletList = async () => {
    const resp: any = await dispatch(transactionList(querySearch));
    console.log("resp", resp);
    setData(resp?.payload?.listTransaction);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Mã giao dịch",
      dataIndex: "transactionCode",
      key: "1",
    },
    {
      title: "Loại giao dịch",
      dataIndex: "type",
      key: "2",
      render: (text: any, record: any) => (
        <div className="fw-bolder">
          <div>
            {text === "Deposit"
              ? "Nạp tiền"
              : text === "AccumulationCreate"
              ? "Nạp tích luỹ"
              : text === "WithDraw"
              ? "Rút tiền"
              : text === "PaymentOrder"
              ? "Mua hàng"
              : text === "payCommissions"
              ? "Trả hoa hồng"
              : ""}
          </div>
        </div>
      ),
    },
    {
      title: "Tài khoản nguồn",
      dataIndex: "userInfo",
      key: "3",
      render: (text: any, record: any) => (
        <div className="fw-bolder">
          <div>{text?.fullName}</div>
          <div>{text?.email}</div>
        </div>
      ),
    },
    {
      title: "Tài khoản đích",
      dataIndex: "",
      key: "4",
      render: (item: any, record: any) => (
        <div>
          <div className="fw-semibold">{item?.bankName}</div>
          <div className="fw-semibold">{item?.bankCode}</div>
          <div className="fw-semibold">STK: {item?.bankNumber}</div>
          <div className="fw-semibold">Tên: {item?.accountName}</div>
        </div>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "",
      key: "5",
      render: (item: any, record: any) => (
        <div>
          {item?.amount === item?.actualMoneyReceived ? (
            <div className="fw-semibold">{formatNumber(item?.amount)}đ</div>
          ) : currentIndex !== 0 ? (
            <div className="fw-semibold">{formatNumber(item?.amount)}đ</div>
          ) : (
            <div>
              <div className="fw-semibold text-danger text-decoration-line-through">
                {formatNumber(item?.amount)}đ
              </div>
              <div className="fw-semibold">
                {formatNumber(item?.actualMoneyReceived)}đ
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Ảnh sản phẩm",
      dataIndex: "imagePayment",
      key: "5",
      render: (text: any, record: any) => (
        <Zoom>
          <img src={`${API_URL}${text}`} style={{ width: 50, height: 50 }} />
        </Zoom>
      ),
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "6",
      render: (text: any, record: any) => (
        <div>
          <div
            className="fw-semibold"
            style={{
              color:
                text === "Success"
                  ? "green"
                  : text === "Cancel"
                  ? "red"
                  : "blue",
            }}
          >
            {text === "Success"
              ? "Thành công"
              : text === "Cancel"
              ? "Huỷ"
              : "Chờ duyệt"}
          </div>
        </div>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "7",
      render: (text: any, record: any) => (
        <div>
          <div>{moment(text).format("DD/MM/YYYY hh:mm")}</div>
        </div>
      ),
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "8",
    },
    {
      title: "",
      dataIndex: "walletTransactionID",
      key: "5",
      render: (_: any, a: any) => {
        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <button
              className="btn border border-0"
              onClick={() => setChooseItem(a)}
            >
              <MoreOutlined />
            </button>
          </Dropdown>
        );
      },
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/wallet/detail", {
              state: { id: chooseItem?.walletTransactionID },
            })
          }
          className="btn border border-0 text-primary"
        >
          Chi tiết
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => setOpenSuccess(true)}
          className="btn border border-0 text-primary"
        >
          Xác nhận thành công
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button
          onClick={() => setOpenFail(true)}
          className="btn border border-0 text-danger"
        >
          Xác nhận thất bại
        </button>
      ),
      key: "2",
    },
  ];

  const onSuccess = async () => {
    const Smoney = money ? money.split(".").join("") : chooseItem?.amount;
    const params = {
      walletTransactionID: [chooseItem?.walletTransactionID],
      actualMoneyReceived: +Smoney,
    };

    const resp: any = await dispatch(transactionAccepted(params));
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      setQuerySearch((prev: any) => ({ ...prev, page: querySearch.page }));
      setOpenSuccess(false);
    }
  };

  const onFailed = async () => {
    const params = {
      walletTransactionIDs: [chooseItem?.walletTransactionID],
      reason: reason,
    };

    const resp: any = await dispatch(transactionCancel(params));
    console.log("resp fail---", resp);

    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      setQuerySearch((prev: any) => ({ ...prev, page: querySearch.page }));
      setOpenFail(false);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  //     search:
  // {type: "Deposit"}

  const onExport = async () => {
    setOpenWarningExport(false);

    const params: any = {
      type: arr[currentIndex]?.key,
      status,
      // transactionCode: codeQuery,
      fromDate: createFrom,
      toDate: createTo,
    };
    const result = Object.keys(params).reduce(
      (r: any, key) => (params[key] && (r[key] = params[key]), r),
      {}
    );

    const resp: any = await dispatch(walletExportExcel(result));
    console.log("resp export------", resp);
    if (resp?.payload?.status) {
      if (resp?.payload?.data?.length) {
        const _data = resp?.payload?.data;
        const mapData = _data?.map((item: any) => ({
          "Mã giao dịch": item?.walletTransactionID,
          "Ngày tạo": moment(item?.createdAt).format("DD/MM/YYYY"),
          "Code giao dịch": item?.transactionCode,
          "Mã ví giao dịch": item?.walletID,
          "Ngân hàng": item?.bankCode + " " + item?.bankName,
          "Số tài khoản": item?.bankNumber,
          "Tên tài khoản": item?.accountName,
          "Mô tả giao dịch": item?.description,
          "Tổng tiền": formatNumber(item?.total),
          "Số tiền thanh toán": formatNumber(item?.amount),
          "Phí rút": formatNumber(item?.withdrawFee),
          "Ảnh ck": `${API_URL}${item?.imagePayment}`,
          "Link QR": `${item?.url}`,
          "Loại GD":
            item?.type === "Deposit"
              ? "Nạp tiền"
              : item?.type === "AccumulationCreate"
              ? "Nạp tích luỹ"
              : item?.type === "WithDraw"
              ? "Rút tiền"
              : item?.type === "PaymentOrder"
              ? "Mua hàng"
              : item?.type === "payCommissions"
              ? "Trả hoa hồng"
              : "",
          "Trạng thái":
            item?.status === "Success"
              ? "Thành công"
              : item?.status === "Cancel"
              ? "Huỷ"
              : "Chờ duyệt",
          "Thông tin": item?.content,
          "Ngày xác nhận": moment(item?.dayApproved).format("DD/MM/YYYY"),
          "Ngày huỷ GD": moment(item?.dayCancel).format("DD/MM/YYYY"),
          "Lý do huỷ GD": item?.reason,
        }));
        const ws = XLSX.utils.json_to_sheet(mapData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "data" + fileExtension);
      } else {
        alert("Không có dữ liệu để xuất file");
      }
    } else {
      alert("Đã xảy ra lỗi,vui lòng liên hệ IT");
    }
  };

  const onChange = (page: number) => {
    setQuerySearch((prev: any) => ({ ...prev, page: page }));
  };

  return (
    <div className="p-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <>{contextHolder}</>
          <div>
            <div className="fw-bolder fs-4">Lịch sử giao dịch</div>
            <div
              onClick={() => setOpenWarningExport(true)}
              className="btn border border-1 p-2 border-primary fw-semibold text-primary mt-2"
            >
              Xuất file
            </div>
          </div>
          <div className="d-none d-md-block">
            <div>
              <div className="fw-semibold mt-3">Bộ lọc</div>
              <Select
                showSearch={false}
                style={{ width: 200 }}
                className="mt-1"
                defaultValue={status ? status : "Chọn trạng thái"}
                optionFilterProp="children"
                onChange={(value, opt: any) => setStatus(opt.value)}
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listStatus}
              />
              <Input
                style={{
                  width: 250,
                  marginLeft: 8,
                  marginRight: 8,
                  marginTop: 4,
                }}
                placeholder="Tìm kiếm theo mã giao dịch"
                onChange={(val) => setCodeQuery(val.target.value)}
                value={codeQuery}
              />
              <Input
                style={{ width: 250, marginTop: 4 }}
                placeholder="Tìm kiếm theo email người nhận"
                onChange={(val) => setEmailQuery(val.target.value)}
                value={emailQuery}
              />

              <button
                onClick={() => {
                  const obj: any = {
                    status,
                    transactionCode: codeQuery,
                    email: emailQuery,
                    type: arr[currentIndex].key,
                    fromDate: createFrom,
                    toDate: createTo,
                  };
                  const result = Object.keys(obj).reduce(
                    (r: any, key) => (obj[key] && (r[key] = obj[key]), r),
                    {}
                  );
                  setQuerySearch(result);
                }}
                className="btn border border-1 p-0 ms-2 rounded border-primary p-1 text-primary"
              >
                Tìm kiếm <SearchOutlined />{" "}
              </button>
            </div>
            <div className="d-flex align-items-center pt-2">
              <DatePicker
                // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
                format={"YYYY-MM-DD"}
                className="mt-1"
                placeholder={createFrom ? createFrom : "Từ ngày"}
                onChange={(date, dateString: any) => setCreateFrom(dateString)}
              />
              <DatePicker
                // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
                format={"YYYY-MM-DD"}
                className="mt-1 ms-2"
                placeholder={createTo ? createTo : "Đến ngày"}
                onChange={(date, dateString: any) => setCreateTo(dateString)}
              />
              <button
                onClick={() => {
                  setEmailQuery("");
                  setCodeQuery("");
                  setStatus("");
                  setQuerySearch({
                    page: 1,
                    limit: 100,
                  });
                  setCurrentIndex(0);
                  getWalletList();
                }}
                className="btn border border-1 p-0 rounded border-warning p-1 ms-2 text-warning"
              >
                Làm mới <RetweetOutlined />{" "}
              </button>
            </div>
            <div className="d-flex justify-content-center">
              {arr.map((item, index) => {
                let checked = false;
                if (index === currentIndex) {
                  checked = true;
                }
                return (
                  <button
                    className="btn border border-0"
                    key={item.id}
                    onClick={() => {
                      setCurrentIndex(index);
                      setQuerySearch((prev: any) => ({
                        ...prev,
                        type: item.key,
                      }));
                    }}
                    style={{
                      color: checked ? Colors.ORANGE : "black",
                      fontWeight: checked ? "600" : "400",
                      marginBottom: 16,
                    }}
                  >
                    {item.text}
                  </button>
                );
              })}
            </div>
            <Table pagination={false} columns={columns} dataSource={data} />
            <div className="d-flex align-items-center mt-3 justify-content-center">
              <Pagination
                current={querySearch.page}
                defaultCurrent={1}
                onChange={onChange}
                total={1000}
              />
            </div>
          </div>

          <div className="d-block d-md-none">
            <div className="d-flex flex-column d-grid gap-3">
              <div className="fw-semibold mt-3">Bộ lọc</div>
              <Select
                showSearch={false}
                className="w-100"
                defaultValue={status ? status : "Chọn trạng thái"}
                optionFilterProp="children"
                onChange={(value, opt: any) => setStatus(opt.value)}
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listStatus}
              />
              <Input
                className="w-100"
                placeholder="Tìm kiếm theo mã giao dịch"
                onChange={(val) => setCodeQuery(val.target.value)}
                value={codeQuery}
              />
              <Input
                className="w-100"
                placeholder="Tìm kiếm theo email người nhận"
                onChange={(val) => setEmailQuery(val.target.value)}
                value={emailQuery}
              />

              <DatePicker
                // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
                format={"YYYY-MM-DD"}
                className="mt-1"
                placeholder="Từ ngày"
                onChange={(date, dateString: any) => setCreateFrom(dateString)}
              />
              <DatePicker
                // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
                format={"YYYY-MM-DD"}
                className="mt-1"
                placeholder="Đến ngày"
                onChange={(date, dateString: any) => setCreateTo(dateString)}
              />

              <button
                onClick={() => {
                  const obj: any = {
                    status,
                    transactionCode: codeQuery,
                    email: emailQuery,
                    type: arr[currentIndex].key,
                    fromDate: createFrom,
                    toDate: createTo,
                  };
                  const result = Object.keys(obj).reduce(
                    (r: any, key) => (obj[key] && (r[key] = obj[key]), r),
                    {}
                  );
                  setQuerySearch(result);
                }}
                className="btn border border-1 p-0 rounded border-primary p-1 text-primary"
              >
                Tìm kiếm <SearchOutlined />{" "}
              </button>
              <button
                onClick={() => {
                  setEmailQuery("");
                  setCodeQuery("");
                  setQuerySearch({
                    page: 1,
                    limit: 100,
                  });
                  setCurrentIndex(0);
                  getWalletList();
                }}
                className="btn border border-1 p-0 rounded border-warning p-1 text-warning"
              >
                Làm mới <RetweetOutlined />{" "}
              </button>
              <div className="d-flex justify-content-center">
                {arr.map((item, index) => {
                  let checked = false;
                  if (index === currentIndex) {
                    checked = true;
                  }
                  return (
                    <button
                      className="btn border border-0"
                      key={item.id}
                      onClick={() => {
                        setCurrentIndex(index);
                        setQuerySearch((prev: any) => ({
                          ...prev,
                          type: item.key,
                        }));
                      }}
                      style={{
                        color: checked ? Colors.ORANGE : "black",
                        fontWeight: checked ? "600" : "400",
                        marginBottom: 16,
                        fontSize: 12,
                        marginTop: 16,
                      }}
                    >
                      {item.text}
                    </button>
                  );
                })}
              </div>
            </div>
            {data.map((item: any) => {
              return (
                <div
                  key={item?._id}
                  className="p-3 d-grid gap-3 border-2 border-bottom"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Mã giao dịch</div>
                    <div className="fw-semibold text-primary">
                      {item?.transactionCode}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Loại giao dịch</div>
                    <div className="fw-bolder">
                      <div>
                        {item?.type === "Deposit"
                          ? "Nạp tiền"
                          : item?.type === "AccumulationCreate"
                          ? "Nạp tích luỹ"
                          : item?.type === "WithDraw"
                          ? "Rút tiền"
                          : item?.type === "PaymentOrder"
                          ? "Mua hàng"
                          : item?.type === "payCommissions"
                          ? "Trả hoa hồng"
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Tài khoản nguồn</div>
                    <div className="fw-bolder d-flex flex-column align-items-end">
                      <div>{item?.userInfo?.fullName}</div>
                      <div>{item?.userInfo?.email}</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Tài khoản đích</div>
                    <div className="d-flex flex-column align-items-end">
                      <div className="fw-semibold">{item?.bankName}</div>
                      <div className="fw-semibold">{item?.bankCode}</div>
                      <div className="fw-semibold">STK: {item?.bankNumber}</div>
                      <div className="fw-semibold">
                        Tên: {item?.accountName}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Số tiền</div>
                    <div>
                      <div className="fw-semibold">
                        {formatNumber(item?.amount)}đ
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Ảnh sản phẩm</div>
                    <Zoom>
                      <img
                        src={`${API_URL}${item?.imagePayment}`}
                        style={{ width: 50, height: 50 }}
                      />
                    </Zoom>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Tình trạng</div>
                    <div>
                      <div
                        className="fw-semibold"
                        style={{
                          color:
                            item?.status === "Success"
                              ? "green"
                              : item?.status === "Cancel"
                              ? "red"
                              : "blue",
                        }}
                      >
                        {item?.status === "Success"
                          ? "Thành công"
                          : item?.status === "Cancel"
                          ? "Huỷ"
                          : "Chờ duyệt"}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Ngày tạo</div>
                    <div>
                      {moment(item?.createdAt).format("DD/MM/YYYY hh:mm")}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Nội dung</div>
                    <div className="fw-semibold">{item?.content}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Thao tác</div>
                    <Dropdown menu={{ items }} trigger={["click"]}>
                      <button
                        className="btn border border-0"
                        onClick={() => setChooseItem(item)}
                      >
                        <MoreOutlined />
                      </button>
                    </Dropdown>
                  </div>
                </div>
              );
            })}
            <div className="d-flex align-items-center justify-content-center mt-3">
              <Pagination
                current={querySearch.page}
                defaultCurrent={1}
                onChange={onChange}
                total={1000}
              />
            </div>
          </div>

          <Modal
            open={openSuccess}
            title={"Xác nhận thành công"}
            onOk={onSuccess}
            onCancel={() => setOpenSuccess(false)}
          >
            <Input
              placeholder={formatNumber(chooseItem?.amount)}
              value={money}
              onChange={(text) => setMoney(formatMoneyText(text.target.value))}
            />
          </Modal>

          <Modal
            open={openFail}
            title={"Xác nhận huỷ"}
            onOk={onFailed}
            onCancel={() => setOpenFail(false)}
          >
            <Input
              placeholder="Nguyên nhân"
              value={reason}
              onChange={(text) => setReason(text.target.value)}
            />
          </Modal>
        </>
      )}
      <Modal
        open={openWarningExport}
        title={`LƯU Ý: Số lượng & nội dung file trả về theo kết quả của "Bộ lọc" tại "Lịch sử giao dịch"`}
        onOk={onExport}
        onCancel={() => setOpenWarningExport(false)}
      ></Modal>
    </div>
  );
}
