import { newInterface } from "src/Constant/Interface";
import client from "./Client";

const News = {
  async createNew(params: newInterface) {
    try {
      const res = await client.post(`/news/create`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async updateNew(params: newInterface) {
    try {
      const res = await client.post(`/news/update`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async deleteNew(params: newInterface) {
    try {
      const res = await client.post(`/news/delete`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async listNew(params: newInterface) {
    try {
      const res = await client.post(`/news/list`, params);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },

  async detailNew(id: newInterface) {
    try {
      const res = await client.get(`/news/detail/${id}`);
      return res;
    } catch (err) {
      alert(err?.message);
    }
  },
};

export default News;
