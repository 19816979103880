const Colors = {
  GREY: "#A1A1A1",
  GREY_2: "#E4E8EA",
  LIGHT_2: "#D9E3E7",
  BLUE: "#3C97FF",
  C_BLUE: "#2254F6",
  BLUE_FILL: "#F5F8FA",
  DARK_4: "#757575",
  BLACK: "#111111",
  DARK: "#333333",
  DARK_1: "#1A1A1A",
  DARK_2: "#424242",
  DARK_3: "#606060",
  DARK_5: "#717D8E",
  DARK_6: "#B0B0B0",
  DARK_8: "#E0E0E0",
  WHITE: "#FFFFFF",
  WHITE_2: "#F3FAFF",
  ORANGE: "#EF924C",
  BORDERW: "#ECECEC",
  BG_PRIMARY: "#D9F9EE",
  GREEN_1: "#089340",
  GREEN_2: "#16B979",
  PRIMARY: "#CC272D",
  PRIMARY_2: "#9A2024",
  RED: "#E5000E",
  RED_1: "#CC272D",
  RED_2: "#A12126",
  RED_3: "#AF000C",
  RED_4: "#E3A9AA",
  RED_5: "#F8D9D9",
  RED_6: "#FEECEC",
  YELLOW_1: "#F8D34F",
  BLUE_6: "#D8E8FC",
  BLUE_2: "#F3F5F6",
  BLUE_4: "#E4E8EA",
  BLACK_2: "#676767",
};

export default Colors;
