import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Wallet from "src/Controller/Wallet";


const WalletInitialState: any = {

};

export const getWalletAll = createAsyncThunk(
    "wallet/getWalletAll",
    async () => {
        const resp = await Wallet.getWalletAll();
        return resp;
    }
);

export const transactionList = createAsyncThunk(
    "wallet/transactionList",
    async (params: any) => {
        const resp = await Wallet.transactionList(params);
        return resp;
    }
);

export const exportExcel = createAsyncThunk(
    "wallet/exportExcel",
    async (params: any) => {
        const resp = await Wallet.exportExcel(params);
        return resp;
    }
);

export const transactionAccepted = createAsyncThunk(
    "wallet/transactionAccepted",
    async (params: any) => {
        const resp = await Wallet.transactionAccepted(params);
        return resp;
    }
);

export const transactionCancel = createAsyncThunk(
    "wallet/transactionCancel",
    async (params: any) => {
        const resp = await Wallet.transactionCancel(params);
        return resp;
    }
);

export const transactionDetail = createAsyncThunk(
    "wallet/transactionDetail",
    async (id: string) => {
        const resp = await Wallet.transactionDetail(id);
        return resp;
    }
);

export const createBank = createAsyncThunk(
    "wallet/createBank",
    async (params: any) => {
        const resp = await Wallet.createBank(params);
        return resp;
    }
);

export const updateBank = createAsyncThunk(
    "wallet/updateBank",
    async (params: any) => {
        const resp = await Wallet.updateBank(params);
        return resp;
    }
);

export const deleteBank = createAsyncThunk(
    "wallet/deleteBank",
    async (params: any) => {
        const resp = await Wallet.deleteBank(params);
        return resp;
    }
);

export const listBank = createAsyncThunk(
    "wallet/listBank",
    async () => {
        const resp = await Wallet.listBank();
        return resp;
    }
);

export const walletExportExcel = createAsyncThunk(
    "wallet/walletExportExcel",
    async (params: any) => {
        const resp = await Wallet.walletExportExcel(params);
        return resp;
    }
);

const wallet = createSlice({
    name: "wallet",
    initialState: WalletInitialState,
    reducers: {

    },
});

const { actions, reducer } = wallet;
export default reducer;

