import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Manager from "src/Controller/Manager";

const ManagerInitialState: any = {};

export const adminList = createAsyncThunk(
  "manager/adminList",
  async (params: any) => {
    const resp = await Manager.adminList(params);
    return resp;
  }
);

export const adminUpdate = createAsyncThunk(
  "manager/adminUpdate",
  async (params: any) => {
    const resp = await Manager.adminUpdate(params);
    return resp;
  }
);

export const adminCreate = createAsyncThunk(
  "manager/adminCreate",
  async (params: any) => {
    const resp = await Manager.adminCreate(params);
    return resp;
  }
);

export const adminDetail = createAsyncThunk(
  "manager/adminDetail",
  async (params: any) => {
    const resp = await Manager.adminDetail(params);
    return resp;
  }
);

export const adminLock = createAsyncThunk(
  "manager/adminLock",
  async (params: any) => {
    const resp = await Manager.adminLock(params);
    return resp;
  }
);

export const adminResetPassword = createAsyncThunk(
  "manager/adminResetPassword",
  async (params: any) => {
    const resp = await Manager.adminResetPassword(params);
    return resp;
  }
);

export const adminUnLock = createAsyncThunk(
  "manager/adminUnLock",
  async (params: any) => {
    const resp = await Manager.adminUnLock(params);
    return resp;
  }
);

export const configDetail = createAsyncThunk(
  "manager/configDetail",
  async () => {
    const resp = await Manager.configDetail();
    return resp;
  }
);

export const configUpdateFeeWithDraw = createAsyncThunk(
  "manager/configUpdateFeeWithDraw",
  async (params: any) => {
    const resp = await Manager.configUpdateFeeWithDraw(params);
    return resp;
  }
);

export const privateListUser = createAsyncThunk(
  "manager/privateListUser",
  async (params: any) => {
    const resp = await Manager.privateListUser(params);
    return resp;
  }
);

export const exportDataDeputy = createAsyncThunk(
  "manager/exportDataDeputy",
  async () => {
    const resp = await Manager.exportDataDeputy();
    return resp;
  }
);

const manager = createSlice({
  name: "manager",
  initialState: ManagerInitialState,
  reducers: {},
});

const { actions, reducer } = manager;
export default reducer;
