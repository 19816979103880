import {combineReducers, Reducer, AnyAction} from '@reduxjs/toolkit';
import loadingReducer from '../Features/loading/loadingSlice';
import useReducer from '../Features/user/userSlice';

const combinedReducer = combineReducers({
  isLoading: loadingReducer,
  user: useReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  return combinedReducer(state, action);
};
export default rootReducer;
