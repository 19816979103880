import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { Input, message } from "antd";
import React, { useState } from "react";
import Colors from "../../Commom/Colors";
import { useNavigate } from "react-router-dom";
import { setClientToken } from "../../Controller/Client";
import { login, setUserLogin } from "src/Components/Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "src/Components/Redux/selector";
import Cookies from "js-cookie";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const onLogin = async () => {
    const params = {
      email,
      password,
    };
    const resp: any = await dispatch(login(params));
    if (resp?.payload?.status === true) {
      Cookies.set("access_token", resp.payload?.accessToken);
      dispatch(setUserLogin(resp?.payload));
      showSuccess("Đăng nhập thành công !");
      navigate("/home");
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  return (
    <div className="container-fluid d-flex justify-content-center">
      <>{contextHolder}</>
      <div>
        <div
          onClick={() => {
            setEmail("superadmin@ffe.com");
            setPassword("adminFfe@2023");
          }}
          className="btn border border-0 d-flex justify-content-center mb-2 mt-4"
        >
          <img
            src={require("../../Assets/Images/logo.png")}
            alt=""
            style={{
              width: 150,
              height: 150,
              borderRadius: 16,
            }}
          />
        </div>
        <div className="text-center mt-4 fs-1 fw-semibold"> Đăng nhập</div>
        <div className="mt-4 p-4 rounded">
          <div className="d-flex flex-column align-items-center w-100">
            <div>
              <Input
                size="large"
                className="rounded mt-2 border border-body"
                placeholder="Nhập email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // prefix={<SecurityScanOutlined />}
              />
              <Input.Password
                size="large"
                className="rounded mt-2 border border-body"
                placeholder="Nhập mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // prefix={<SecurityScanOutlined />}
                status="error"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>

            <button
              onClick={onLogin}
              disabled={!password || !email}
              className="btn border border-0 p-0 rounded-5 text-white px-5 mt-4"
              style={{
                background: password
                  ? "linear-gradient(#FFDF8C, #FF1414)"
                  : Colors.DARK_8,
                height: 40,
              }}
            >
              {isLoading ? <LoadingOutlined /> : "Xác nhận"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
