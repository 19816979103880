import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Pagination,
  Select,
  Table,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  customerChangeEmail,
  customerChangePassword,
  customerList,
  customerLock,
  customerUpdate,
  customerUpdateAffiLevel,
  customerUpdateAmount,
} from "src/Components/Features/customer/CustomerSlice";
import moment from "moment";
import {
  CheckOutlined,
  CloseOutlined,
  DotChartOutlined,
  DownOutlined,
  MoreOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { DataType } from "src/Constant/Interface";
import formatNumber from "src/Commom/FormatNumber";
import Colors from "src/Commom/Colors";
import { selectLoading } from "src/Components/Redux/selector";
import Loading from "src/Commom/Loading";
import { level } from "src/Constant/Array";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { orderExportExcel } from "src/Components/Features/order/OrderSlice";
import { exportDataDeputy } from "src/Components/Features/manager/ManagerSlice";
import { toast } from "react-toastify";
import formatMoneyText from "src/Commom/FormatMoneyText";

export default function Customer() {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [openLockAccount, setOpenLockAccount] = useState(false);
  const [openChangeAmountWallet, setOpenChangeAmountWallet] = useState(false);
  const [reasonWallet, setReasonWallet] = useState("");
  const [totalAmountWallet, setTotalAmountWallet] = useState("");
  const [totalVoucher, setTotalVoucher] = useState("");
  const [totalStock, setTotalStock] = useState("");
  const [noteLock, setNoteLock] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [openUpdateAffi, setOpenUpdateAffi] = useState(false);
  const [chooseAffiLevel, setChooseAffiLevel] = useState<any>("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [openWarningExport, setOpenWarningExport] = useState(false);
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
  });
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const { Search } = Input;
  const onSearch = (value: any, _e: any, info: any) => {
    console.log(value);
    setParams((prev: any) => ({ ...prev, search: { keyword: value } }));
  };

  useEffect(() => {
    getList();
  }, [params]);

  useEffect(() => {
    setTotalAmountWallet(formatNumber(chooseItem?.accountBalance));
    setTotalVoucher(formatNumber(chooseItem?.amountVoucher));
    setTotalStock(formatNumber(chooseItem?.amountShare));
  }, [chooseItem]);

  const getList = async () => {
    const resp: any = await dispatch(customerList(params));
    console.log("resp", resp);
    if (resp.payload.status === true) {
      setData(resp?.payload?.data);
    }
  };

  const onChange = (page: number) => {
    setParams((prev: any) => ({ ...prev, page: page }));
  };

  const onUpdateAffi = async () => {
    const params = {
      customerID: chooseItem?._id,
      affiliateLevel: chooseAffiLevel,
    };
    const resp: any = await dispatch(customerUpdateAffiLevel(params));
    console.log("resp", resp);
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      setOpenUpdateAffi(false);
    }
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setChooseAffiLevel(value);
    setParams((prev: any) => ({ ...prev, search: { affiliateLevel: value } }));
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/customer/detail", { state: { id: chooseItem?._id } })
          }
          className="btn border border-0 text-primary"
        >
          Chi tiết
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => setOpenModalUpdate(true)}
          className="btn border border-0 text-success"
        >
          Cập nhật
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button
          onClick={() => setOpenModalChangePassword(true)}
          className="btn border border-0 text-warning"
        >
          Đổi mật khẩu
        </button>
      ),
      key: "2",
    },
    {
      label: (
        <button
          onClick={() => setOpenLockAccount(true)}
          className="btn border border-0 text-danger"
        >
          Khoá
        </button>
      ),
      key: "3",
    },
    {
      label: (
        <button
          className="btn border border-0"
          onClick={() => setOpenUpdateAffi(true)}
        >
          Đổi cấp bậc
        </button>
      ),
      key: "4",
    },
    {
      label: (
        <button
          onClick={() => setOpenChangeAmountWallet(true)}
          className="btn border border-0"
        >
          Thay đổi số dư ví
        </button>
      ),
      key: "5",
    },
  ];

  const getText = (key: string) => {
    switch (key) {
      case "member":
        return "Thành viên";
      case "collaborator":
        return "Cộng tác viên";
      case "start_up":
        return "Khởi nghiệp";
      case "deputy_business":
        return "Phó phòng kinh doanh";
      case "sales_manager":
        return "Trưởng phòng kinh doanh";
      case "business_director":
        return "Giám đốc kinh doanh";
      case "deputy_general_manager":
        return "Phó tổng giám đốc";
      default:
        return "Chưa xác định";
    }
  };

  const getColor = (key: string) => {
    switch (key) {
      case "member":
        return Colors.BLACK;
      case "collaborator":
        return "brown";
      case "start_up":
        return "purple";
      case "deputy_business":
        return Colors.C_BLUE;
      case "sales_manager":
        return "orange";
      case "business_director":
        return "gold";
      case "deputy_general_manager":
        return Colors.RED;
      default:
        return Colors.GREY;
    }
  };

  const onUpdate = async () => {
    const params: any = {
      _id: chooseItem?._id,
      fullName: fullName,
      src: "",
      birthDay: birthday,
      phoneNumber: phone,
    };
    let result = Object.keys(params).reduce(
      (r: any, key) => (params[key] && (r[key] = params[key]), r),
      {}
    );
    result.src = "";
    const resp: any = await dispatch(customerUpdate(result));
    console.log("resp", resp);
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      setOpenModalUpdate(false);
      setFullName("");
      setBirthday("");
      setPhone("");
      getList();
    }
    if (email) {
      const paramsEmail = {
        emailOld: chooseItem?.email,
        emailNew: email,
      };
      const res: any = await dispatch(customerChangeEmail(paramsEmail));
      if (res?.payload?.status) {
        setEmail("");
      }
    }
  };

  const onChangePassword = async () => {
    const params = {
      _id: chooseItem?._id,
      password: password,
    };
    const resp: any = await dispatch(customerChangePassword(params));
    console.log("resp", resp);
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      setOpenModalChangePassword(false);
    }
  };

  const onLockAccount = async () => {
    const params = {
      customerID: chooseItem?._id,
      reasonLook: noteLock,
    };
    const resp: any = await dispatch(customerLock(params));
    console.log("resp", resp);
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      setOpenLockAccount(false);
    }
  };

  const onUpdateWallet = async () => {
    if (!reasonWallet) {
      toast.error("Vui lòng nhập lý do thay đổi!");
    } else {
      const params: any = {
        customerID: chooseItem?._id,
        walletUpdate: {
          accountBalance: +totalAmountWallet.split(".").join(""),
          amountVoucher: +totalVoucher.split(".").join(""),
          amountShare: +totalStock.split(".").join(""),
        },
        reason: reasonWallet,
      };
      const resp: any = await dispatch(customerUpdateAmount(params));
      console.log("resp", resp);
      if (resp.payload.status) {
        showSuccess("Thành công!");
        setOpenChangeAmountWallet(false);
        setTotalAmountWallet("");
        setTotalVoucher("");
        setTotalStock("");
        setReasonWallet("");
        getList();
        toast.success("Thành công!");
      } else {
        toast.error("Dữ liệu truyền lên không hợp lệ!");
      }
    }
  };

  const onExport = async () => {
    setOpenWarningExport(false);
    const resp: any = await dispatch(exportDataDeputy());
    console.log("resp export------", resp);
    if (resp?.payload?.status) {
      if (resp?.payload?.data?.length) {
        const ws = XLSX.utils.json_to_sheet(resp?.payload?.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "data" + fileExtension);
      } else {
        alert("Không có dữ liệu để xuất file");
      }
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "myRef",
      key: "0",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "1",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Khách hàng",
      dataIndex: "fullName",
      key: "2",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Cấp bậc",
      dataIndex: "affiliateLevel",
      key: "3",
      render: (text: any) => {
        return (
          <div className="fw-bolder" style={{ color: getColor(text) }}>
            {getText(text)}
          </div>
        );
      },
    },
    {
      title: "Ví cá nhân",
      dataIndex: "accountBalance",
      key: "4",
      render: (text: any) => {
        return <div className="fw-bolder">{formatNumber(text)}đ</div>;
      },
    },
    {
      title: "Xác thực",
      dataIndex: "isVerified",
      key: "5",
      render: (text: any) => {
        return (
          <div className="fw-bolder">
            {text == true ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "isVerified",
      key: "6",
      render: (text: any) => {
        return (
          <div className="fw-bolder">
            {text == true ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </div>
        );
      },
    },
    {
      title: "Đã bị xoá",
      dataIndex: "isDelete",
      key: "7",
      render: (text: any) => {
        return <div className="fw-bolder">{text == false && "x"}</div>;
      },
    },
    {
      title: "Thời gian",
      dataIndex: "lastLogin",
      key: "8",
      render: (text: any) => {
        return (
          <div className="fw-bolder">
            {moment(text).format("DD/MM/YYYY hh:mm")}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "9",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
  ];

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };
  const showError = (value: string) => {
    messageApi.open({
      type: "error",
      content: value,
    });
  };

  return (
    <div className="px-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <>{contextHolder}</>
          <div className="fw-semibold fs-4">Danh sách tài khoản khách hàng</div>
          <div
            onClick={() => setOpenWarningExport(true)}
            className="btn border border-1 p-2 border-primary fw-semibold text-primary"
          >
            Xuất file
          </div>
          <div className="d-flex justify-content-between pb-3 pt-2">
            <div className="fw-semibold d-none d-md-block">Bộ lọc</div>
            <div className="row">
              <Select
                defaultValue={
                  chooseAffiLevel ? chooseAffiLevel : "Chọn cấp bậc"
                }
                style={{ width: 200 }}
                onChange={handleChange}
                options={level}
                className="px-2"
              />
              <Search
                placeholder="Tìm kiếm email"
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </div>
          </div>
          <button
            onClick={() => {
              setParams({
                page: 1,
                limit: 100,
              });
            }}
            className="btn border border-1 p-0 rounded border-warning p-1 my-2 text-warning"
          >
            Làm mới <RetweetOutlined />{" "}
          </button>
          <div className="d-none d-md-block">
            {isLoading ? (
              <Loading />
            ) : (
              <Table columns={columns} dataSource={data} pagination={false} />
            )}
            <div className="d-flex align-items-center justify-content-center mt-3">
              <Pagination
                current={params.page}
                defaultCurrent={1}
                onChange={onChange}
                total={1000}
              />
            </div>
          </div>
          <div className="d-block d-md-none">
            {data.map((item: any) => {
              return (
                <div
                  key={item?._id}
                  className="p-3 d-grid gap-3 border-2 border-bottom"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">ID</div>
                    <div className="fw-semibold text-primary">
                      {item?.myRef}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Email</div>
                    <div className="fw-semibold">{item?.email}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Khách hàng</div>
                    <div className="fw-semibold">{item?.fullName}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Cấp bậc</div>
                    <div
                      className="fw-bolder"
                      style={{ color: getColor(item?.affiliateLevel) }}
                    >
                      {getText(item?.affiliateLevel)}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Ví cá nhân</div>
                    <div className="fw-semibold">
                      {formatNumber(item?.accountBalance)}đ
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Xác thực</div>
                    <div className="fw-bolder">
                      {item?.isVerified ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <CloseOutlined style={{ color: "red" }} />
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Đã bị xoá</div>
                    <div className="fw-bolder">
                      {!item?.isDelete ? (
                        <CloseOutlined style={{ color: "red" }} />
                      ) : (
                        <CheckOutlined style={{ color: "green" }} />
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Thời gian</div>
                    <div className="fw-bolder">
                      {moment(item?.lastLogin).format("DD/MM/YYYY hh:mm")}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">Thao tác</div>
                    <Dropdown menu={{ items }} trigger={["click"]}>
                      <button
                        className="btn border border-0 fw-bolder text-primary"
                        onClick={() => setChooseItem(item)}
                      >
                        <MoreOutlined />
                      </button>
                    </Dropdown>
                  </div>
                </div>
              );
            })}
            <div className="d-flex align-items-center justify-content-center mt-3">
              <Pagination
                current={params.page}
                defaultCurrent={1}
                onChange={onChange}
                total={1000}
              />
            </div>
          </div>
          <Modal
            title="Cập nhật thông tin tài khoản"
            open={openModalUpdate}
            onOk={onUpdate}
            onCancel={() => setOpenModalUpdate(false)}
          >
            <Input
              type="text"
              value={fullName}
              placeholder={"Tên"}
              onChange={(text) => setFullName(text.target.value)}
            />
            <Input
              type="text"
              value={email}
              placeholder={"Email"}
              className="my-2"
              onChange={(text) => setEmail(text.target.value)}
            />
            <Input
              type="text"
              value={phone}
              placeholder={"Số điện thoại"}
              onChange={(text) => setPhone(text.target.value)}
            />

            <DatePicker
              // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
              format={"YYYY-MM-DD"}
              className="mt-2 w-100"
              placeholder="Ngày sinh"
              onChange={(date, dateString: any) => setBirthday(dateString)}
            />
          </Modal>

          <Modal
            title="Đổi mật khẩu"
            open={openModalChangePassword}
            onOk={onChangePassword}
            onCancel={() => setOpenModalChangePassword(false)}
          >
            <Input.Password
              type="text"
              value={password}
              placeholder={"Nhập mật khẩu mới"}
              onChange={(text) => setPassword(text.target.value)}
            />
            <Input.Password
              type="text"
              value={rePassword}
              className="mt-2"
              placeholder={"Nhập lại mật khẩu mới"}
              onChange={(text) => setRePassword(text.target.value)}
            />
          </Modal>

          <Modal
            title="Khoá tài khoản"
            open={openLockAccount}
            onOk={onLockAccount}
            onCancel={() => setOpenLockAccount(false)}
          >
            <Input
              type="text"
              value={noteLock}
              className="mt-2"
              placeholder={"Nhập lý do khoá"}
              onChange={(text) => setNoteLock(text.target.value)}
            />
          </Modal>

          <Modal
            title="Thay đổi số dư ví"
            open={openChangeAmountWallet}
            onOk={onUpdateWallet}
            onCancel={() => setOpenChangeAmountWallet(false)}
          >
            <div>Số dư ví cá nhân</div>
            <Input
              type="text"
              value={totalAmountWallet}
              className="mt-2"
              placeholder={"Số dư ví cá nhân"}
              onChange={(text) =>
                setTotalAmountWallet(formatMoneyText(text.target.value))
              }
            />
            <div className="py-2">
              <div>Số dư ví cá voucher</div>
              <Input
                type="text"
                value={totalVoucher}
                className="mt-2"
                placeholder={"Số dư ví voucher"}
                onChange={(text) =>
                  setTotalVoucher(formatMoneyText(text.target.value))
                }
              />
            </div>
            <div>Số dư ví cá cổ phần</div>
            <Input
              type="text"
              value={totalStock}
              className="mt-2"
              placeholder={"Số dư ví cổ phần"}
              onChange={(text) =>
                setTotalStock(formatMoneyText(text.target.value))
              }
            />
            <div className="mt-3">Lý do thay đổi</div>
            <Input
              type="text"
              value={reasonWallet}
              className="mt-2"
              placeholder={"Lý do thay đổi"}
              onChange={(text) => setReasonWallet(text.target.value)}
            />
          </Modal>

          <Modal
            title="Cập nhật cấp bậc tài khoản"
            open={openUpdateAffi}
            onOk={onUpdateAffi}
            onCancel={() => setOpenUpdateAffi(false)}
          >
            <Select
              defaultValue="Chọn cấp bậc"
              style={{ width: 200 }}
              onChange={handleChange}
              options={level}
            />
          </Modal>
          <Modal
            open={openWarningExport}
            title={`Xuất file`}
            onOk={onExport}
            onCancel={() => setOpenWarningExport(false)}
          ></Modal>
        </>
      )}
    </div>
  );
}
