import React from "react";
import { Space, Spin } from "antd";
import Colors from "./Colors";

export default function Loading() {
  return (
    <div
      className="bg-white"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          marginLeft: "48%",
          marginTop: "18%",
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );
}
