import {
  DownCircleOutlined,
  DownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {
  DatePicker,
  DatePickerProps,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Switch,
  Upload,
  UploadFile,
} from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import formatMoneyText from "src/Commom/FormatMoneyText";
import formatNumber from "src/Commom/FormatNumber";
import { createProduct } from "src/Components/Features/product/ProductSlice";
import { uploadImage } from "src/Components/Features/user/userSlice";
import { selectLoading, userInfor } from "src/Components/Redux/selector";
import JoditEditor from "jodit-react";

export default function ProductAdd() {
  const editor = useRef<any>(null);
  const user = useSelector(userInfor);
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const [aliasName, setAliasName] = useState("");
  const [enterprice, setEnterPrice] = useState("");
  const [comparativePrice, setComparativePrice] = useState("");
  const [price, setPrice] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [weight, setWeight] = useState("");
  const [sku, setSku] = useState("");
  const [note, setNote] = useState("");
  const [isHot, setIsHot] = useState(false);
  const [informationName, setInformationName] = useState("");
  const [unit, setUnit] = useState("");
  const [content, setContent] = useState("");
  const [chooseProductType, setChooseProductType] = useState("");
  const [startDay, setStartDay] = useState("");
  const [pt, setPt] = useState("");
  const [coin, setCoin] = useState("");
  const [voucher, setVoucher] = useState("");
  const [cophan, setCophan] = useState("");
  const [priceVoucher, setPriceVoucher] = useState("");
  const [barcode, setBarcode] = useState("");

  const onCreate = async () => {
    let listImages: any = [];
    for (let i = 0; i < fileList.length; i++) {
      const image = fileList[i];
      const formData: any = new FormData();
      formData.append("image", image?.originFileObj);
      const res: any = await dispatch(uploadImage(formData));
      if (res?.payload?.status) {
        console.log("res upload", res);
        const images =
          res?.payload?.data?.map((i: { path: string }) => i?.path) || [];
        listImages.push(images[0]);
      } else {
        alert("Có lỗi xảy ra,vui lòng liên hệ IT");
      }
    }
    const params = {
      productName: aliasName,
      productType:
        chooseProductType === "Sản phẩm liên kết"
          ? "Affiliate"
          : chooseProductType === "Sản phẩm độc quyền"
          ? "Monopoly"
          : chooseProductType === "Sản phẩm tài trợ"
          ? "Sponsor"
          : chooseProductType === "Gói đại lý"
          ? "Agency"
          : "",
      price: price.split(".").join(""),
      priceVocher: +priceVoucher.split(".").join(""),
      priceCompare: +comparativePrice.split(".").join(""),
      importPrice: +enterprice.split(".").join(""),
      voucherAdded: +voucher,
      numberOfCoins: +coin,
      numberOfStocks: +cophan,
      descreptionShort: note,
      pointPT: pt,
      status: "Active",
      sku: sku,
      showHot: isHot,
      images: listImages,
      isDelete: false,
      videos: [],
      barcode: barcode,
      categories: [],
      releaseDate: startDay,
      weight: weight,
      info: [
        {
          key: "",
          value: "",
          unit: "",
        },
      ],
    };
    const resp: any = await dispatch(createProduct(params));
    console.log("resp create----", resp);

    if (resp.payload.status === true) {
      alert("Tạo sản phẩm thành công!");
      navigate(-1);
    }
  };

  const upload = async () => {
    for (let i = 0; i < fileList.length; i++) {
      const image = fileList[i];
      const formData: any = new FormData();
      formData.append("image", image?.originFileObj);
      const res: any = await dispatch(uploadImage(formData));
      if (res?.payload?.status) {
        console.log("res upload", res);
        const images =
          res?.payload?.data?.map((i: { path: string }) => i?.path) || [];
      } else {
        alert("Có lỗi xảy ra,vui lòng liên hệ IT");
      }
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    console.log("newFileList", newFileList);
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => setChooseProductType("Sản phẩm tài trợ")}
          className="btn border border-0"
        >
          Sản phẩm tài trợ
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => setChooseProductType("Sản phẩm độc quyền")}
          className="btn border border-0"
        >
          Sản phẩm độc quyền
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => setChooseProductType("Sản phẩm liên kết")}
          className="btn border border-0"
        >
          Sản phẩm liên kết
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => setChooseProductType("Gói đại lý")}
          className="btn border border-0"
        >
          Gói Đại lý
        </div>
      ),
    },
  ];

  const onChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    setStartDay(moment(date?.$d).format("YYYY-MM-DD"));
  };

  // const config = useMemo(
  //   () => ({
  //     readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  //     enableDragAndDropFileToEditor: true,
  //     placeholder: "Nhập...",
  //     uploader: {
  //       insertImageAsBase64URI: false,
  //       url: "https://api.ffe.com.vn/upload/image",
  //       imagesExtensions: ["jpg", "png", "jpeg"],
  //       headers: { Authorization: `Bearer ${user?.token}` },
  //       withCredentials: false,
  //       pathVariableName: "path",
  //       format: "json",
  //       method: "POST",
  //       prepareData: function (formData: any) {
  //         formData.append("mode", "My Files");
  //         formData.append("name", "image");
  //         formData.append("image", formData.get("files[0]"));
  //         formData.delete("files[0]");
  //         return formData;
  //       },
  //       isSuccess: function (resp: { error: any }) {
  //         console.log("resp", resp);
  //         return !resp.error;
  //       },
  //       getMessage: function (resp: { msgs: any[] }) {
  //         // console.log("resp----", resp);
  //         return resp.msgs.join("\n");
  //       },
  //       process: (resp: any) => ({
  //         files: resp.data, // {array} The names of uploaded files.
  //         path: resp?.path, // {string} Real relative path
  //         baseurl: "https://api.ffe.com.vn", // {string} Base url for filebrowser
  //         error: resp.error ? 1 : 0, // {int}
  //         // msg: resp.msg // {string}
  //       }),
  //       // defaultHandlerSuccess: (resp: any) => {
  //       //   const [tagName, attr] = ["img", "src"];
  //       //   const elm = editor.current.createInside.element(tagName); // Directly access joditEditor
  //       //   elm.setAttribute(attr, resp.baseurl);
  //       //   editor.current.selection.insertImage(elm, null, resp.files[0].width);
  //       // },
  //     },
  //   }),
  //   []
  // );

  const config = {
    uploader: { insertImageAsBase64URI: true },
    readonly: false,
  };

  return (
    <div className="px-4">
      <div className="row mt-3">
        <div className="col-md-6 bg-white rounded shadow p-4">
          <div className="fw-bolder fs-4 pb-0">Thông tin sản phẩm</div>
          <div className="d-flex justify-content-between pb-2">
            <div>Tên sản phẩm (*):</div>
            <Input
              value={aliasName}
              style={{
                width: "70%",
              }}
              onChange={(text) => setAliasName(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Giá nhập (₫):</div>
            <Input
              value={enterprice}
              style={{
                width: "70%",
              }}
              onChange={(text) =>
                setEnterPrice(formatMoneyText(text.target.value))
              }
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Giá so sánh (₫):</div>
            <Input
              value={comparativePrice}
              style={{
                width: "70%",
              }}
              onChange={(text) =>
                setComparativePrice(formatMoneyText(text.target.value))
              }
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Giá bán (₫):</div>
            <Input
              value={price}
              style={{
                width: "70%",
              }}
              onChange={(text) => setPrice(formatMoneyText(text.target.value))}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Số PT:</div>
            <Input
              value={pt}
              style={{
                width: "70%",
              }}
              onChange={(text) => setPt(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Trọng lượng(g):</div>
            <Input
              value={weight}
              style={{
                width: "70%",
              }}
              onChange={(text) => setWeight(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>SKU:</div>
            <Input
              value={sku}
              style={{
                width: "70%",
              }}
              onChange={(text) => setSku(text.target.value)}
            />
          </div>

          <div className="d-flex justify-content-between pb-2">
            <div>Barcode:</div>
            <Input
              value={barcode}
              style={{
                width: "70%",
              }}
              onChange={(text) => setBarcode(text.target.value)}
            />
          </div>

          <div className="d-flex pb-2">
            <div className="me-4">Sản phẩm hot:</div>
            <Switch onClick={() => setIsHot(!isHot)} />
          </div>
        </div>

        <div className="col-md-5 col-12 bg-white rounded shadow p-4 ms-2">
          <div className="fw-bolder fs-4 pb-0">Thông tin chi tiết</div>
          <Input
            value={informationName}
            placeholder={"Tên thông tin"}
            onChange={(text) => setInformationName(text.target.value)}
          />
          <Input
            value={unit}
            placeholder={"Đơn vị tính"}
            className="my-2"
            onChange={(text) => setUnit(text.target.value)}
          />
          <Input
            value={content}
            placeholder={"Nội dung/giá trị"}
            onChange={(text) => setContent(text.target.value)}
          />

          <div className="fw-bolder fs-4 pb-0 pt-3">
            D/s hình ảnh sản phẩm đã chọn
          </div>
          <>
            <Upload
              // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </div>
      </div>
      <div className="my-3 col-md-11">
        <JoditEditor
          ref={editor}
          value={note}
          config={config}
          onChange={(value) => setNote(value)}
        />
      </div>
      <div className="col-md-6 col-12 bg-white rounded shadow p-4">
        <div className="fw-bolder fs-4 pb-0">Thông tin khác</div>

        <div className="d-flex justify-content-between pb-2">
          <div>Số Voucher quy đổi (₫):</div>
          <Input
            value={voucher}
            placeholder={"Cộng vào ví Voucher khi mua sản phẩm"}
            style={{
              width: "70%",
              height: 34,
            }}
            onChange={(text) => setVoucher(text.target.value)}
          />
        </div>

        <div className="d-flex justify-content-between pb-2">
          <div>Số Coin quy đổi:</div>
          <Input
            value={coin}
            placeholder={"Số lượng Coin được cộng khi mua sản phẩm"}
            style={{
              width: "70%",
              height: 34,
            }}
            onChange={(text) => setCoin(text.target.value)}
          />
        </div>

        <div className="d-flex justify-content-between pb-2">
          <div>Số cổ phần quy đổi:</div>
          <Input
            value={cophan}
            placeholder={"Số cổ phần được cộng khi mua sản phẩm"}
            style={{
              width: "70%",
              height: 34,
            }}
            onChange={(text) => setCophan(text.target.value)}
          />
        </div>

        <div className="d-flex justify-content-between pb-2">
          <div>Loại sản phẩm (*):</div>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div
              className="border border-1 rounded d-flex justify-content-between px-2 align-items-center"
              style={{
                width: "70%",
                height: 34,
              }}
            >
              <div className="fw-semibold">
                {chooseProductType ? chooseProductType : "Loại sản phẩm"}
              </div>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
        {chooseProductType === "Sản phẩm liên kết" ||
        chooseProductType === "Gói đại lý" ? (
          <div className="d-flex justify-content-between pb-2">
            <div>Giá voucher (đ):</div>
            <Input
              value={priceVoucher}
              placeholder={`Số tiền tương ứng`}
              style={{
                width: "70%",
                height: 34,
              }}
              onChange={(text) =>
                setPriceVoucher(formatMoneyText(text.target.value))
              }
            />
          </div>
        ) : (
          <div />
        )}

        {/* <div className="d-flex justify-content-between pb-2">
          <div>Danh mục sản phẩm:</div>
          <Input
            value={sku}
            placeholder={""}
            style={{
              width: "70%",
            }}
            onChange={(text) => setSku(text.target.value)}
          />
        </div> */}

        <div className="d-flex justify-content-between pb-2">
          <div>Ngày phát hành (*):</div>
          <DatePicker
            style={{ width: "70%", height: 34 }}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="justify-content-center d-flex mt-4">
        <button
          onClick={onCreate}
          className="border border-0 btn bg-primary text-white"
        >
          {isLoading ? <LoadingOutlined /> : "Tạo sản phẩm"}
        </button>
      </div>
    </div>
  );
}
