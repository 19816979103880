import React, { useEffect, useState } from "react";
import { reportGeneral } from "src/Components/Features/user/userSlice";
import { useAppDispatch } from "src/Components/Redux/store";
import type { DatePickerProps } from "antd";
import { DatePicker, Table } from "antd";
import formatNumber from "src/Commom/FormatNumber";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { selectLoading } from "src/Components/Redux/selector";
import Loading from "src/Commom/Loading";

const Wallet = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [reportWallet, setReportWallet] = useState<any>([]);

  useEffect(() => {
    getGeneral();
  }, []);

  const getGeneral = async () => {
    const params = {};
    const resp: any = await dispatch(reportGeneral(params));
    console.log("resp report-----", resp);
    setReportWallet(resp?.payload?.reportWallet);
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (item, r, index) => <div>{index + 1}</div>,
    },
    {
      title: "Ngày",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{`${item?._id?.day}/${item?._id?.month}/${item?._id?.year}`}</div>
      ),
    },
    {
      title: "Số tiền nạp",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{formatNumber(item?.totalDeposit)}đ</div>
      ),
    },
    {
      title: "Nạp thành công",
      dataIndex: "",
      key: "3",
      render: (item) => (
        <div className="fw-semibold text-success">
          {formatNumber(item?.totalDepositSuccess)}đ
        </div>
      ),
    },
    {
      title: "Tổng tiền rút",
      dataIndex: "",
      key: "4",
      render: (item) => (
        <div className="fw-semibold text-primary">
          {formatNumber(item?.totalWithDraw)}đ
        </div>
      ),
    },
    {
      title: "Rút thành công",
      dataIndex: "",
      key: "4",
      render: (item) => (
        <div className="fw-semibold text-primary">
          {formatNumber(item?.totalWithDrawSuccess)}đ
        </div>
      ),
    },
  ];

  return (
    <div className="p-3">
      <div className="fw-semibold fs-4">Báo cáo ví chính</div>

      {isLoading ? (
        <Loading />
      ) : (
        <Table columns={columns} dataSource={reportWallet} />
      )}
    </div>
  );
};

export default Wallet;
