import React, { useEffect, useState } from "react";
import Header from "../Header";
import MenuLeft from "../MenuLeft";
import {
  Dropdown,
  MenuProps,
  Modal,
  Table,
  message,
  DatePicker,
  Input,
  Pagination,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  orderCalcel,
  orderConfirm,
  orderDelivetyCancel,
  orderDelivetyConfirm,
  orderDelivetySuccess,
  orderExportExcel,
  orderList,
  orderPaymentConfirmation,
} from "src/Components/Features/order/OrderSlice";
import { ColumnsType } from "antd/es/table";
import { DataType } from "src/Constant/Interface";
import moment from "moment";
import formatNumber from "src/Commom/FormatNumber";
import {
  MoreOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Colors from "src/Commom/Colors";
import { selectLoading, userInfor } from "src/Components/Redux/selector";
import Loading from "src/Commom/Loading";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

const arr = [
  {
    id: 0,
    text: "Tất cả",
    status: "",
  },
  {
    id: 1,
    text: "Chờ xác nhận",
    status: "New",
  },
  {
    id: 2,
    text: "Đã xác nhận",
    status: "Pending",
  },
  {
    id: 3,
    text: "Hoàn thành",
    status: "Success",
  },
  {
    id: 4,
    text: "Đã huỷ",
    status: "Cancel",
  },
];

export default function Order() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const [messageApi, contextHolder] = message.useMessage();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openShippingSuccess, setOpenShippingSuccess] = useState(false);
  const [openShippingFail, setOpenShippingFail] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openWarningExport, setOpenWarningExport] = useState(false);
  const [createFrom, setCreateFrom] = useState("");
  const [createTo, setCreateTo] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [phoneQuery, setPhoneQuery] = useState("");
  const [querySearch, setQuerySearch] = useState<any>({
    page: 1,
    limit: 10,
    search: null,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    getListOrder();
  }, [querySearch]);

  const getListOrder = async () => {
    const resp: any = await dispatch(orderList(querySearch));
    console.log("resp---", resp);
    setData(resp?.payload?.order);
  };

  const renderColor: any = {
    New: "#FFA500",
    Pending: "#EE4D2D",
    Success: "#5EBB25",
    Cancel: "#CA2D35",
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "",
      key: "0",
      render: (text: any, index: any, e) => {
        return <div className="fw-semibold">{e + 1}</div>;
      },
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "_id",
      key: "1",
    },
    {
      title: "Người đặt",
      dataIndex: "customerEmail",
      key: "2",
    },
    {
      title: "Ghi chú",
      dataIndex: "shippingNote",
      key: "3",
    },
    {
      title: "Người nhận",
      dataIndex: "",
      key: "4",
      render: (item: any) => {
        return (
          <div>
            <div className="fw-bolder">{item?.shippingName}</div>
            <div className="fw-semibold">{item?.shippingPhoneNumber}</div>
          </div>
        );
      },
    },
    {
      title: "Địa chỉ nhận",
      dataIndex: "",
      key: "5",
      render: (item: any) => {
        return (
          <div>
            {item?.shippingAddress} {item?.shippingWardName}{" "}
            {item?.shippingDistrictName} {item?.shippingProvinceName}
          </div>
        );
      },
    },
    {
      title: "Tổng tiền",
      dataIndex: "total",
      key: "6",
      render: (text: any) => {
        return <div className="fw-bolder">{formatNumber(text)}đ</div>;
      },
    },
    {
      title: "Thanh toán",
      dataIndex: "paymentMethod",
      key: "7",
      render: (text: any) => {
        return (
          <div className="fw-semibold">
            {text === "Wallet" ? "TT qua ví cá nhân" : "TT khi nhận hàng"}
          </div>
        );
      },
    },
    {
      title: "Giao hàng",
      dataIndex: "shippingStatus",
      key: "8",
      render: (text: any) => {
        return (
          <div style={{ fontWeight: "600", color: renderColor[text] }}>
            {text === "Pending"
              ? "Đang giao"
              : text === "Success"
              ? "Đã giao"
              : text === "3"
              ? "Hoàn trả"
              : "Chưa giao"}
          </div>
        );
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "9",
      render: (text: any) => {
        return (
          <div>
            {text === "Pending"
              ? "Xác nhận chưa hoàn thành"
              : text === "Success"
              ? "Đơn hoàn thành"
              : text === "Cancel"
              ? "Đã hủy"
              : "Đơn mới"}
          </div>
        );
      },
    },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "10",
      render: (text: any) => {
        return <div>{moment(text).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "orderID",
      key: "11",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() =>
            navigate("/order/detail", { state: { id: chooseItem } })
          }
          className="btn border border-0 text-primary"
        >
          Chi tiết
        </button>
      ),
      key: "0",
    },
    // {
    //   label: (
    //     <button
    //       onClick={() => onShipping()}
    //       className="btn border border-0 text-warning"
    //     >
    //       Xác nhận thanh toán thành công
    //     </button>
    //   ),
    //   key: "1",
    // },
    {
      label: (
        <button
          onClick={() => onShippingPending()}
          className="btn border border-0 text-warning"
        >
          Bắt đầu giao hàng
        </button>
      ),
      key: "2",
    },
    {
      label: (
        <button
          onClick={() => setOpenShippingSuccess(true)}
          className="btn border border-0 text-success"
        >
          Giao hàng thành công
        </button>
      ),
      key: "3",
    },
    {
      label: (
        <button
          onClick={() => setOpenShippingFail(true)}
          className="btn border border-0 text-danger"
        >
          Giao hàng thất bại
        </button>
      ),
      key: "4",
    },
    {
      label: (
        <button
          onClick={() => setOpenSuccess(true)}
          className="btn border border-0"
        >
          Xác nhận thành công
        </button>
      ),
      key: "5",
    },
  ];

  const onChange = (page: number) => {
    setQuerySearch((prev: any) => ({ ...prev, page: page }));
  };

  const onShipping = async () => {
    const resp: any = await dispatch(orderConfirm({ orderIDs: [chooseItem] }));
    console.log("resp success-", resp);
    if (resp.payload.status == true) {
      showSuccess(resp?.payload?.message);
      setQuerySearch((prev: any) => ({
        ...prev,
        page: querySearch.page,
      }));
    } else {
      showSuccess(resp?.payload?.message);
    }
  };

  const onShippingPending = async () => {
    const listOrderIDs = selectedRowKeys.length
      ? selectedRowKeys
      : [chooseItem];
    const resp: any = await dispatch(
      orderDelivetyConfirm({ orderIDs: listOrderIDs })
    );
    console.log("resp success-", resp);
    if (resp.payload.status == true) {
      showSuccess(resp?.payload?.message);
      setQuerySearch((prev: any) => ({
        ...prev,
        page: querySearch.page,
      }));
    } else {
      showSuccess(resp?.payload?.message);
    }
  };

  const onShippingSuccess = async () => {
    const listOrderIDs = selectedRowKeys.length
      ? selectedRowKeys
      : [chooseItem];
    const resp: any = await dispatch(
      orderDelivetySuccess({ orderIDs: listOrderIDs })
    );
    console.log("resp success-", resp);
    if (resp.payload.status == true) {
      showSuccess(resp?.payload?.message);
      setQuerySearch((prev: any) => ({
        ...prev,
        page: querySearch.page,
      }));
      setOpenShippingSuccess(false);
    } else {
      showSuccess(resp?.payload?.message);
      setOpenShippingSuccess(false);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };

  const onShippingFail = async () => {
    const listOrderIDs = selectedRowKeys.length
      ? selectedRowKeys
      : [chooseItem];
    const resp: any = await dispatch(
      orderDelivetyCancel({ orderIDs: listOrderIDs })
    );
    console.log("resp fail-", resp);
    if (resp.payload.status == true) {
      showSuccess(resp?.payload?.message);
      setQuerySearch((prev: any) => ({
        ...prev,
        page: querySearch.page,
      }));
      setOpenShippingFail(false);
    }
  };

  const onSuccess = async () => {
    const listOrderIDs = selectedRowKeys.length
      ? selectedRowKeys
      : [chooseItem];
    const resp: any = await dispatch(
      orderPaymentConfirmation({ orderIDs: listOrderIDs })
    );
    console.log("resp success-", resp);
    if (resp.payload.status == true) {
      showSuccess(resp?.payload?.message);
      setQuerySearch((prev: any) => ({
        ...prev,
        page: querySearch.page,
      }));
      setOpenSuccess(false);
    } else {
      showSuccess(resp?.payload?.message);
      setOpenSuccess(false);
    }
  };

  const onExport = async () => {
    setOpenWarningExport(false);
    let params = {};
    if (currentIndex === 0) {
      params = {
        search: {
          createFrom: createFrom,
          createTo: createTo,
        },
      };
    } else {
      params = {
        search: {
          createFrom: createFrom,
          createTo: createTo,
          status: arr[currentIndex]?.status,
        },
      };
    }
    const resp: any = await dispatch(orderExportExcel(params));
    console.log("resp export------", resp);
    if (resp?.payload?.status) {
      if (resp?.payload?.data?.length) {
        const _data = resp?.payload?.data;
        const mapData = _data?.map((item: any) => {
          return {
            "Mã đơn hàng": item?.orderID,
            "Ngày tạo": moment(item?.createdAt).format("DD/MM/YYYY"),
            "Mã sản phấm": item?.orderDetails[0]?.productID,
            "Tên sản phẩm": item?.orderDetails[0]?.productName,
            "Giá sản phẩm": formatNumber(item?.orderDetails[0]?.price),
            "Số lượng sản phẩm": item?.orderDetails[0]?.quantity,
            "Hình thức thanh toán":
              item?.paymentMethod === "Wallet"
                ? "TT qua ví cá nhân"
                : "TT khi nhận hàng",
            "Trạng thái thanh toán": item?.paymentStatus,
            "Tổng tiền": formatNumber(item?.total),
            "Mã khách hàng": item?.customerID,
            "Email khách hàng": item?.customerEmail,
            "Tên người nhận hàng": item?.shippingName,
            "SĐT nhận hàng": item?.shippingPhoneNumber,
            "Địa chỉ nhận hàng": item?.shippingAddress,
            "Tỉnh / Thành phố": item?.shippingProvinceName,
            "Quận / Huyện": item?.shippingDistrictName,
            "Xã / Phường": item?.shippingWardName,
            "Ghi chú giao hàng": item?.shippingNote,
            "Trạng thái giao hàng":
              item?.shippingStatus === "Pending"
                ? "Đang giao"
                : item?.shippingStatus === "Success"
                ? "Đã giao"
                : item?.shippingStatus === "3"
                ? "Hoàn trả"
                : "Chưa giao",
          };
        });
        const ws = XLSX.utils.json_to_sheet(mapData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "data" + fileExtension);
      } else {
        alert("Không có dữ liệu để xuất file");
      }
    } else {
      alert("Đã xảy ra lỗi,vui lòng liên hệ IT");
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.SetStateAction<never[]>,
    selectedRows: any,
    b: any
  ) => {
    console.log("selectedRowKeys changed: ", selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <div className="p-4">
        <>{contextHolder}</>
        <div>
          <div className="fw-semibold fs-4">Danh sách hóa đơn</div>
          <div
            onClick={() => setOpenWarningExport(true)}
            className="btn border border-1 p-2 border-primary fw-semibold text-primary"
          >
            Xuất file
          </div>
        </div>
        <div className="d-none d-md-block">
          <div>
            <div className="fw-semibold mt-3">Bộ lọc</div>
            <DatePicker
              format={"YYYY-MM-DD"}
              className="mt-1"
              placeholder={createFrom ? createFrom : "Từ ngày"}
              onChange={(date, dateString: any) => setCreateFrom(dateString)}
            />
            <DatePicker
              // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
              format={"YYYY-MM-DD"}
              className="mt-1 ms-2"
              placeholder={createTo ? createTo : "Đến ngày"}
              onChange={(date, dateString: any) => setCreateTo(dateString)}
            />
            <Input
              style={{
                width: 250,
                marginLeft: 8,
                marginRight: 8,
                marginTop: 4,
              }}
              placeholder="Tìm kiếm người đặt (theo email)"
              onChange={(val) => setEmailQuery(val.target.value)}
              value={emailQuery}
            />
            <Input
              style={{ width: 250, marginTop: 4 }}
              placeholder="Tìm kiếm người nhận (theo SĐT)"
              onChange={(val) => setPhoneQuery(val.target.value)}
              value={phoneQuery}
            />

            <button
              onClick={() => {
                const obj: any = {
                  status: arr[currentIndex]?.status,
                  createFrom,
                  createTo,
                  customerEmail: emailQuery,
                  shippingPhoneNumber: phoneQuery,
                };
                const result = Object.keys(obj).reduce(
                  (r: any, key) => (obj[key] && (r[key] = obj[key]), r),
                  {}
                );
                setQuerySearch((prev: any) => ({
                  ...prev,
                  search: result,
                }));
              }}
              className="btn border border-1 ms-2 rounded border-primary py-1 px-2 text-primary"
            >
              Tìm kiếm <SearchOutlined />{" "}
            </button>
          </div>
          <button
            onClick={() => {
              setCreateFrom("");
              setCreateTo("");
              setEmailQuery("");
              setPhoneQuery("");
              setQuerySearch({
                page: 1,
                limit: 100,
                search: null,
              });
              setCurrentIndex(0);
              getListOrder();
            }}
            className="btn border border-1 p-0 rounded border-warning p-1 mt-2 text-warning"
          >
            Làm mới <RetweetOutlined />{" "}
          </button>
          <div className="d-flex justify-content-center">
            {arr.map((item: any, index) => {
              let checked = false;
              if (index === currentIndex) {
                checked = true;
              }
              return (
                <button
                  className="btn border border-0"
                  key={item.id}
                  onClick={() => {
                    setCurrentIndex(index);
                    if (index === 0) {
                      setQuerySearch((prev: any) => ({
                        ...prev,
                        search: "",
                      }));
                    } else {
                      setQuerySearch((prev: any) => ({
                        ...prev,
                        search: { status: item.status },
                      }));
                    }
                  }}
                  style={{
                    color: checked ? Colors.ORANGE : "black",
                    fontWeight: checked ? "600" : "400",
                    marginBottom: 16,
                  }}
                >
                  {item.text}
                </button>
              );
            })}
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Table
                rowSelection={rowSelection}
                rowKey={(record) => record.orderID}
                columns={columns}
                pagination={false}
                expandable={{
                  expandedRowRender: (data: any) => (
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      {data?.orderDetails?.map((item: any, index: number) => {
                        return (
                          <div
                            key={item?._id}
                            className="d-flex align-items-center justify-content-around"
                          >
                            <div>
                              <div>Tên sản phẩm:</div>
                              <div className="fw-bolder">
                                {item?.productName}
                              </div>
                            </div>
                            <div>
                              <div>Đơn giá:</div>
                              <div className="fw-bolder">
                                {formatNumber(item?.price)}đ
                              </div>
                            </div>
                            <div>
                              <div>Số lượng:</div>
                              <div className="fw-bolder">{item?.quantity}</div>
                            </div>
                            <div>
                              <div>Thành tiên:</div>
                              <div className="fw-bolder">
                                {formatNumber(item?.price * item?.quantity)}đ
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </p>
                  ),
                }}
                dataSource={data}
              />
              <Pagination
                current={querySearch.page}
                defaultCurrent={1}
                onChange={onChange}
                total={1000}
              />
            </>
          )}
        </div>
        <div className="d-block d-md-none">
          <div className="d-flex flex-column d-grid gap-3">
            <div className="fw-semibold mt-3">Bộ lọc</div>
            <DatePicker
              // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
              format={"YYYY-MM-DD"}
              placeholder={createFrom ? createFrom : "Từ ngày"}
              onChange={(date, dateString: any) => setCreateFrom(dateString)}
            />
            <DatePicker
              // defaultValue={dayjs(isUserData?.data?.birthDay, "DD/MM/YYYY")}
              format={"YYYY-MM-DD"}
              placeholder={createTo ? createTo : "Đến ngày"}
              onChange={(date, dateString: any) => setCreateTo(dateString)}
            />
            <Input
              className="w-100"
              placeholder="Tìm kiếm người đặt (theo email)"
              onChange={(val) => setEmailQuery(val.target.value)}
              value={emailQuery}
            />
            <Input
              className="w-100"
              placeholder="Tìm kiếm người nhận (theo SĐT)"
              onChange={(val) => setPhoneQuery(val.target.value)}
              value={phoneQuery}
            />

            <button
              onClick={() => {
                const obj: any = {
                  status: arr[currentIndex]?.status,
                  createFrom,
                  createTo,
                  customerEmail: emailQuery,
                  shippingPhoneNumber: phoneQuery,
                };
                const result = Object.keys(obj).reduce(
                  (r: any, key) => (obj[key] && (r[key] = obj[key]), r),
                  {}
                );
                setQuerySearch((prev: any) => ({
                  ...prev,
                  search: result,
                }));
              }}
              className="btn border border-1 rounded border-primary py-1 px-2 text-primary"
            >
              Tìm kiếm <SearchOutlined />{" "}
            </button>
            <button
              onClick={() => {
                setCreateFrom("");
                setCreateTo("");
                setEmailQuery("");
                setPhoneQuery("");
                setQuerySearch({
                  page: 1,
                  limit: 100,
                  search: null,
                });
                setCurrentIndex(0);
                getListOrder();
              }}
              className="btn border border-1 p-0 rounded border-warning p-1 text-warning"
            >
              Làm mới <RetweetOutlined />{" "}
            </button>
          </div>
          <div className="d-flex justify-content-center">
            {arr.map((item: any, index) => {
              let checked = false;
              if (index === currentIndex) {
                checked = true;
              }
              return (
                <button
                  className="btn border border-0"
                  key={item.id}
                  onClick={() => {
                    setCurrentIndex(index);
                    if (index === 0) {
                      setQuerySearch((prev: any) => ({
                        ...prev,
                        search: "",
                      }));
                    } else {
                      setQuerySearch((prev: any) => ({
                        ...prev,
                        search: { status: item.status },
                      }));
                    }
                  }}
                  style={{
                    color: checked ? Colors.ORANGE : "black",
                    fontWeight: checked ? "600" : "400",
                    marginBottom: 16,
                    marginTop: 16,
                    fontSize: 12,
                  }}
                >
                  {item.text}
                </button>
              );
            })}
          </div>
          {data.map((item: any) => {
            return (
              <div
                key={item?._id}
                className="p-3 d-grid gap-3 border-2 border-bottom"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Mã đơn hàng</div>
                  <div className="fw-semibold text-primary">{item?._id}</div>
                </div>
                {item?.orderDetails?.map((i: any) => (
                  <div key={i?._id} className="d-grid gap-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fw-semibold">Tên sản phẩm</div>
                      <div className="fw-semibold">{i?.productName}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fw-semibold">Đơn giá</div>
                      <div className="fw-semibold">
                        {formatNumber(i?.price)}đ
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fw-semibold">Số lượng</div>
                      <div className="fw-semibold ">{i?.quantity}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fw-semibold">Thành tiền</div>
                      <div className="fw-semibold ">
                        {formatNumber(i?.price * i?.quantity)}đ
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Người đặt</div>
                  <div className="fw-semibold">{item?.customerEmail}</div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Người nhận</div>
                  <div>
                    <div className="fw-bolder">{item?.shippingName}</div>
                    <div className="fw-semibold">
                      {item?.shippingPhoneNumber}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Địa chỉ nhận</div>
                  <div>
                    {item?.shippingAddress} {item?.shippingWardName}{" "}
                    {item?.shippingDistrictName} {item?.shippingProvinceName}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Tổng tiền</div>
                  <div className="fw-bolder">{formatNumber(item?.total)}đ</div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Thanh toán</div>
                  <div className="fw-semibold">
                    {item?.paymentMethod === "Wallet"
                      ? "TT qua ví cá nhân"
                      : "TT khi nhận hàng"}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Giao hàng</div>
                  <div
                    style={{
                      fontWeight: "600",
                      color: renderColor[item?.shippingStatus],
                    }}
                  >
                    {item?.shippingStatus === "Pending"
                      ? "Đang giao"
                      : item?.shippingStatus === "Success"
                      ? "Đã giao"
                      : item?.shippingStatus === "3"
                      ? "Hoàn trả"
                      : "Chưa giao"}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Tình trạng</div>
                  <div className="fw-semibold">
                    {item?.status === "Pending"
                      ? "Xác nhận chưa hoàn thành"
                      : item?.status === "Success"
                      ? "Đơn hoàn thành"
                      : item?.status === "Cancel"
                      ? "Đã hủy"
                      : "Đơn mới"}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Thời gian</div>
                  <div>
                    {moment(item?.createdAt).format("YYYY-MM-DD HH:mm")}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-semibold">Thao tác</div>
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <button
                      className="btn border border-0"
                      onClick={() => setChooseItem(item?.orderID)}
                    >
                      <MoreOutlined />
                    </button>
                  </Dropdown>
                </div>
              </div>
            );
          })}
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Pagination
              current={querySearch.page}
              defaultCurrent={1}
              onChange={onChange}
              total={1000}
            />
          </div>
        </div>
        <Modal
          open={openShippingSuccess}
          title={"Xác nhận giao hàng thành công"}
          onOk={onShippingSuccess}
          onCancel={() => setOpenShippingSuccess(false)}
        ></Modal>
        <Modal
          open={openShippingFail}
          title={"Xác nhận giao hàng thất bại"}
          onOk={onShippingFail}
          onCancel={() => setOpenShippingFail(false)}
        ></Modal>

        <Modal
          open={openSuccess}
          title={"Xác nhận đơn hàng thành công"}
          onOk={onSuccess}
          onCancel={() => setOpenSuccess(false)}
        ></Modal>
        <Modal
          open={openWarningExport}
          title={`LƯU Ý: Số lượng & nội dung file trả về theo kết quả của "Bộ lọc" tại "Danh sách hoá đơn"`}
          onOk={onExport}
          onCancel={() => setOpenWarningExport(false)}
        ></Modal>
      </div>
    );
}
