import { createSlice } from "@reduxjs/toolkit";
import { login, reportGeneral } from "../user/userSlice";
import { createProduct, listProduct } from "../product/ProductSlice";
import { transactionList, walletExportExcel } from "../wallet/WalletSlice";
import {
  customerList,
  customerTeamModel,
  customerTeamModelSearch,
} from "../customer/CustomerSlice";
import { orderExportExcel, orderList } from "../order/OrderSlice";
import { allListAccumulative } from "../accumulation/AccumulationSlice";
import { exportDataDeputy } from "../manager/ManagerSlice";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    toggletLoading: (state) => !state,
    resetLoading: (state) => false,
  },
  extraReducers: {
    [login.pending.type]: () => true,
    [login.fulfilled.type]: () => false,
    [login.rejected.type]: () => false,

    [createProduct.pending.type]: () => true,
    [createProduct.fulfilled.type]: () => false,
    [createProduct.rejected.type]: () => false,

    [transactionList.pending.type]: () => true,
    [transactionList.fulfilled.type]: () => false,
    [transactionList.rejected.type]: () => false,

    [customerTeamModel.pending.type]: () => true,
    [customerTeamModel.fulfilled.type]: () => false,
    [customerTeamModel.rejected.type]: () => false,

    [customerTeamModelSearch.pending.type]: () => true,
    [customerTeamModelSearch.fulfilled.type]: () => false,
    [customerTeamModelSearch.rejected.type]: () => false,

    [customerList.pending.type]: () => true,
    [customerList.fulfilled.type]: () => false,
    [customerList.rejected.type]: () => false,

    [listProduct.pending.type]: () => true,
    [listProduct.fulfilled.type]: () => false,
    [listProduct.rejected.type]: () => false,

    [orderList.pending.type]: () => true,
    [orderList.fulfilled.type]: () => false,
    [orderList.rejected.type]: () => false,

    [allListAccumulative.pending.type]: () => true,
    [allListAccumulative.fulfilled.type]: () => false,
    [allListAccumulative.rejected.type]: () => false,

    [reportGeneral.pending.type]: () => true,
    [reportGeneral.fulfilled.type]: () => false,
    [reportGeneral.rejected.type]: () => false,

    [walletExportExcel.pending.type]: () => true,
    [walletExportExcel.fulfilled.type]: () => false,
    [walletExportExcel.rejected.type]: () => false,

    [orderExportExcel.pending.type]: () => true,
    [orderExportExcel.fulfilled.type]: () => false,
    [orderExportExcel.rejected.type]: () => false,

    [exportDataDeputy.pending.type]: () => true,
    [exportDataDeputy.fulfilled.type]: () => false,
    [exportDataDeputy.rejected.type]: () => false,
  },
});

const { actions, reducer } = loadingSlice;
export const { toggletLoading, resetLoading } = actions;
export default reducer;
