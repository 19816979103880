import React, { useEffect, useState } from "react";
import Colors from "src/Commom/Colors";
import { user_infor } from "src/Components/Storage";
import { useSelector } from "react-redux";
import { userInfor } from "src/Components/Redux/selector";
import { useNavigate } from "react-router-dom";
import {
  AlertOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  BankFilled,
  HomeFilled,
  MenuUnfoldOutlined,
  NotificationOutlined,
  SettingFilled,
  ShoppingCartOutlined,
  UserSwitchOutlined,
  WalletFilled,
  WalletOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { setClientToken } from "src/Controller/Client";
import { logOut, setUserLogin } from "src/Components/Features/user/userSlice";
import Cookies from "js-cookie";
import { useAppDispatch } from "src/Components/Redux/store";

export default function Header() {
  const userInfo = useSelector(userInfor);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Trang chủ",
      onClick: () => navigate("/home"),
    },
    {
      key: "2",
      label: "Tài khoản",
      children: [
        {
          key: "2-1",
          onClick: () => navigate("/customer"),
          label: "Khách hàng",
        },
        {
          key: "2-2",
          onClick: () => navigate("/team-model"),
          label: "Cây giới thiệu",
        },
        {
          key: "2-3",
          onClick: () => navigate("/manager"),
          label: "Nhân viên",
        },
      ],
    },

    {
      key: "3",
      onClick: () => navigate("/product"),
      label: "Tất cả sản phẩm",
    },
    {
      key: "4",
      onClick: () => navigate("/order"),
      label: "Hóa đơn",
    },
    {
      key: "5",
      onClick: () => navigate("/promo"),
      label: "Khuyến mãi",
    },
    {
      key: "6",
      onClick: () => navigate("/news"),
      label: "Thông báo",
    },
    {
      key: "7",
      label: " Ví tích lũy",
      children: [
        {
          key: "7-1",
          onClick: () => navigate("/investment"),
          label: "Chính sách tích lũy",
        },
        {
          key: "7-2",
          onClick: () => navigate("/accumulative"),
          label: "Danh sách tích lũy",
        },
      ],
    },

    {
      key: "8",
      onClick: () => navigate("/bank"),
      label: "Ngân hàng",
    },
    {
      key: "9",
      onClick: () => navigate("/wallet"),
      label: " Ví chính",
    },
    {
      key: "10",
      label: "Báo cáo",
      children: [
        {
          key: "10-1",
          onClick: () => navigate("/report/order"),
          label: "Hóa đơn",
        },
        {
          key: "10-2",
          onClick: () => navigate("/report/product"),
          label: "Sản phẩm",
        },
        {
          key: "10-3",
          onClick: () => navigate("/report/customer"),
          label: "Khách hàng",
        },
        {
          key: "10-4",
          onClick: () => navigate("/report/accumulative"),
          label: "Ví tích lũy",
        },
        {
          key: "10-5",
          onClick: () => navigate("/report/wallet"),
          label: "Ví chính",
        },
      ],
    },
    {
      key: "11",
      label: "Cấu hình",
    },
    {
      key: "12",
      onClick: () => {
        dispatch(logOut());
        Cookies.remove("access_token");
        setTimeout(() => {
          navigate("/*");
        }, 500);
      },
      label: "Đăng xuất",
    },
  ];

  return (
    <div
      className="p-3 d-flex align-items-center justify-content-between"
      style={{
        backgroundColor: "red",
      }}
    >
      <div
        onClick={() => navigate("/home")}
        className="d-flex justify-content-center align-items-center btn border border-0"
        style={{
          width: 80,
          height: 80,
          backgroundColor: Colors.WHITE,
          borderRadius: 80,
        }}
      >
        <img
          src={require("../../Assets/Images/logo.png")}
          style={{
            width: 70,
            height: 70,
            borderRadius: 70,
          }}
        />
      </div>
      <div
        className="btn border border-0 p-0 fw-semibold fs-4 text-white d-none d-md-block"
        onClick={() => {
          dispatch(logOut());
          Cookies.remove("access_token");
          setTimeout(() => {
            navigate("/*");
          }, 500);
        }}
      >
        Đăng xuất
      </div>
      <div className="d-block d-md-none">
        <Dropdown menu={{ items }} placement="bottomLeft">
          <MenuUnfoldOutlined className="text-white" style={{ fontSize: 24 }} />
        </Dropdown>
      </div>
    </div>
  );
}
