import client from "./Client";


const Admin = {
    async list(params: any) {
        try {
            const res = await client.post(`/admin/list`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async update(params: any) {
        try {
            const res = await client.post(`/admin/update`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async create(params: any) {
        try {
            const res = await client.post(`/admin/create`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async detail(id: string) {
        try {
            const res = await client.get(`/admin/detail/${id}`);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async lock(params: any) {
        try {
            const res = await client.post(`/admin/lock/`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async resetPassword(params: any) {
        try {
            const res = await client.post(`/admin/reset-pasword`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async unLock(params: any) {
        try {
            const res = await client.post(`/admin/un-lock`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },


}

export default Admin