import { MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Table,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createAccumulative,
  deleteAccumulative,
  listAccumulative,
  updateAccumulative,
} from "src/Components/Features/accumulation/AccumulationSlice";
import { DataType } from "src/Constant/Interface";

export default function Investment() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([]);
  const [chooseItem, setChooseItem] = useState<any>(null);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [numberMonth, setNumberMonth] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [interestRef, setInterestRef] = useState("");

  useEffect(() => {
    getAccumu();
  }, []);

  const getAccumu = async () => {
    const resp: any = await dispatch(listAccumulative({}));
    console.log("listt accu:", resp);
    setData(resp?.payload?.interestRateConfigs);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Số tháng",
      dataIndex: "mounth",
      key: "id",
      render: (text: any) => {
        return <div className="fw-bolder">{text}</div>;
      },
    },
    {
      title: "Lãi suất nhà đầu tư",
      dataIndex: "interestRate",
      key: "name",
      render: (text: any) => {
        return <div className="fw-bolder">{text}%/tháng</div>;
      },
    },
    // {
    //   title: "Lãi suất người kết nối",
    //   dataIndex: "interestRateForReferrer",
    //   key: "email",
    //   render: (text: any) => {
    //     return <div className="fw-bolder">{text}%/tháng</div>;
    //   },
    // },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "phone",
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      },
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updatedAt",
      key: "address",
      render: (text: any) => {
        return moment(text).format("DD/MM/YYYY");
      },
    },
    {
      title: "",
      dataIndex: "interestRateConfigID",
      key: "7",
      render: (_: any) => (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            className="btn border border-0"
            onClick={() => setChooseItem(_)}
          >
            <MoreOutlined />
          </button>
        </Dropdown>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={() => setOpenUpdate(true)}
          className="btn border border-0 text-primary"
        >
          Cập nhật
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          onClick={() => setOpenDeleteProduct(true)}
          className="btn border border-0 text-danger"
        >
          Xoá
        </button>
      ),
      key: "1",
    },
  ];

  const onAdd = async () => {
    const params = {
      mounth: +numberMonth,
      interestRate: +interestRate,
      interestRateForReferrer: +interestRef,
    };
    const resp: any = await dispatch(createAccumulative(params));
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      getAccumu();
      setOpenAdd(false);
    }
  };

  const onUpdate = async () => {
    const params = {
      interestRateConfigID: chooseItem,
      interestRate: +interestRate,
      interestRateForReferrer: +interestRef,
    };
    const resp: any = await dispatch(updateAccumulative(params));
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      getAccumu();
      setOpenUpdate(false);
    }
  };

  const onDeleteProduct = async () => {
    const params = {
      interestRateConfigID: chooseItem,
    };
    const resp: any = await dispatch(deleteAccumulative(params));
    if (resp.payload.status === true) {
      showSuccess("Thành công!");
      getAccumu();
      setOpenDeleteProduct(false);
    }
  };

  const showSuccess = (value: string) => {
    messageApi.open({
      type: "success",
      content: value,
    });
  };
  return (
    <div className="p-4">
      <div className="fw-bolder fs-4">Chính sách trả thưởng</div>
      <>{contextHolder}</>
      <Button
        onClick={() => setOpenAdd(true)}
        className="border border-1 border-primary text-primary my-3"
      >
        + Thêm mới
      </Button>
      <Table columns={columns} dataSource={data} />
      <Modal
        title="Bạn có chắc chắn muốn xoá sản phẩm này"
        open={openDeleteProduct}
        onOk={onDeleteProduct}
        onCancel={() => setOpenDeleteProduct(false)}
      ></Modal>
      <Modal
        title="Thêm chính sách trả thưởng"
        open={openAdd}
        onOk={onAdd}
        onCancel={() => setOpenAdd(false)}
      >
        <Input
          value={numberMonth}
          placeholder={"Số tháng"}
          onChange={(text) => setNumberMonth(text.target.value)}
        />
        <Input
          value={interestRate}
          placeholder={"Lãi suất cho nhà đầu tư"}
          className="my-2"
          onChange={(text) => setInterestRate(text.target.value)}
        />
        {/* <Input
          value={interestRef}
          placeholder={"Lãi suất cho người kết nối"}
          onChange={(text) => setInterestRef(text.target.value)}
        /> */}
      </Modal>
      <Modal
        title="Chỉnh sửa chính sách trả thưởng"
        open={openUpdate}
        onOk={onUpdate}
        onCancel={() => setOpenUpdate(false)}
      >
        <Input
          value={interestRate}
          placeholder={"Lãi suất cho nhà đầu tư"}
          className="my-2"
          onChange={(text) => setInterestRate(text.target.value)}
        />
        {/* <Input
          value={interestRef}
          placeholder={"Lãi suất cho người kết nối"}
          onChange={(text) => setInterestRef(text.target.value)}
        /> */}
      </Modal>
    </div>
  );
}
