import React, { useEffect, useState } from "react";
import { HomeShow } from "../../Constant/Array";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { reportGeneral } from "src/Components/Features/user/userSlice";
import formatNumber from "src/Commom/FormatNumber";
import moment from "moment";
import { selectLoading } from "src/Components/Redux/selector";
import Loading from "src/Commom/Loading";
import { toast } from "react-toastify";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(CategoryScale);

export default function Home() {
  const dispatch = useDispatch<any>();
  const isLoading = useSelector(selectLoading);
  const [dataCustomer, setDataCustomer] = useState<any>(null);
  const [totalOrder, setTotalOrder] = useState(0);
  const [dataOrder, setDataOrder] = useState([]);
  const [dataDay, setDataDay] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const new_date = moment(new Date()).format("YYYY-MM-DD");
    const params = {
      search: {
        dateFrom: "2024-05-01",
        dateTo: new_date,
      },
    };
    const resp: any = await dispatch(reportGeneral(params));
    if (resp?.payload?.status) {
      // console.log("resp", resp);
      setDataCustomer(resp?.payload);
      const totalOrderByMonth = resp?.payload?.reportOrderDetail
        .map((i: { totalOrder: number }) => i.totalOrder)
        .reduce((a: any, c: any) => a + c);
      const fillData = resp?.payload?.reportOrderDetail?.map(
        (i: { totalRevenuePayment: number }) => i?.totalRevenuePayment
      );
      const fillDay = resp?.payload?.reportOrderDetail?.map(
        (i: { _id: { day: any } }) => i?._id?.day
      );
      setDataDay(fillDay);
      setDataOrder(fillData);
      setTotalOrder(totalOrderByMonth);
    } else {
      toast.error("Lấy dữ liệu thất bại");
    }
  };

  const mapCustomer: any = {
    0: formatNumber(dataCustomer?.totalPointVIC),
    1: formatNumber(dataCustomer?.reportCustomer?.total),
    2: formatNumber(dataCustomer?.reportCustomer?.loginMonth),
    3: formatNumber(dataCustomer?.reportCustomer?.registerMonth),
  };

  const data = {
    labels: dataDay,
    datasets: [
      {
        label: "Số tiền",
        data: dataOrder,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
  };

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <div
        className="pb-5 rounded-3 col"
        style={{
          backgroundColor: "#F9FBFB",
        }}
      >
        <div className="container-xxl">
          <div className="row col-lg-12 pt-4 justify-content-md-center">
            {HomeShow.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className="col-md-2 m-2 rounded-4 p-2 border border-0 bg-white d-flex flex-column align-items-center justify-content-center"
                >
                  <UserOutlined
                    style={{
                      color: item.color,
                      fontSize: 24,
                    }}
                  />
                  <p className="mt-2 text-center">{item.text}</p>
                  <div className="fw-semibold fs-3">{mapCustomer[index]}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-4">
          <div className="fs-5 fw-semibold">Đơn hàng tháng này</div>
          <div className="d-none d-md-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div>Tổng số đơn:</div>{" "}
                <div className="fw-semibold ms-2 bg-primary text-white px-2 py-1 rounded">
                  {totalOrder}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>Đơn hoàn thành:</div>{" "}
                <div className="fw-semibold ms-2 bg-success text-white px-2 py-1 rounded">
                  {totalOrder}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>Đơn đã huỷ:</div>{" "}
                <div className="fw-semibold ms-2 bg-danger text-white p-1 rounded">
                  {"0"}
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-md-none">
            <div className="">
              <div className="d-flex align-items-center">
                <div>Tổng số đơn:</div>{" "}
                <div className="fw-semibold ms-2 bg-primary text-white px-2 py-1 rounded">
                  {totalOrder}
                </div>
              </div>
              <div className="d-flex align-items-center my-3">
                <div>Đơn hoàn thành:</div>{" "}
                <div className="fw-semibold ms-2 bg-success text-white px-2 py-1 rounded">
                  {totalOrder}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>Đơn đã huỷ:</div>{" "}
                <div className="fw-semibold ms-2 bg-danger text-white px-2 py-1 rounded">
                  {"0"}
                </div>
              </div>
            </div>
          </div>
          <Line data={data} options={options} />
        </div>
      </div>
    );
}
