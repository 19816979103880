import client from "./Client";

const Customers = {
    async customerList(params: any) {
        try {
            const res = await client.post(`/customers/list`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerUpdate(params: any) {
        try {
            const res = await client.post(`/customers/update`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerUpdateAmount(params: any) {
        try {
            const res = await client.post(`/customers/update-amount-customer`, params);
            return res;
        } catch (err) {
            console.log(err?.message);
        }
    },

    async customerChangeEmail(params: any) {
        try {
            const res = await client.post(`/customers/change-email`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerChangePassword(params: any) {
        try {
            const res = await client.post(`/customers/change-password`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerDetail(id: string) {
        try {
            const res = await client.get(`/customers/detail/${id}`);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerLock(params: any) {
        try {
            const res = await client.post(`/customers/lock`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerUnLock(params: any) {
        try {
            const res = await client.post(`/customers/un-lock`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerOpenAccount(params: any) {
        try {
            const res = await client.post(`/customers/open-account`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerTeamModel(params: any) {
        try {
            const res = await client.post(`/customers/team-model`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerTeamModelSearch(params: any) {
        try {
            const res = await client.post(`/customers/team-model-search`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerUpdateAffiLevel(params: any) {
        try {
            const res = await client.post(`/customers/upgrade-affiliate-level`, params);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },

    async customerHistoryUpdate(params: any) {
        try {
            const res = await client.get(`/customers/history-update/${params}`,);
            return res;
        } catch (err) {
            alert(err?.message);
        }
    },


}

export default Customers