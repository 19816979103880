import { DatePicker, DatePickerProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import formatNumber from "src/Commom/FormatNumber";
import Loading from "src/Commom/Loading";
import { reportGeneral } from "src/Components/Features/user/userSlice";
import { selectLoading } from "src/Components/Redux/selector";
import { useAppDispatch } from "src/Components/Redux/store";

export default function Customer() {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectLoading);
  const [topCustomer, setTopCustomer] = useState([]);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    getGeneral();
  }, []);

  const getGeneral = async () => {
    const params = {};
    const resp: any = await dispatch(reportGeneral(params));
    console.log("resp report-----", resp);
    setTopCustomer(resp?.payload?.topCustomer);
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "",
      key: "1",
      render: (item, r, index) => <div>{index + 1}</div>,
    },
    {
      title: "Email",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{item?._id?.customerEmail}</div>
      ),
    },
    {
      title: "Tổng đơn",
      dataIndex: "",
      key: "2",
      render: (item) => (
        <div className="fw-semibold">{formatNumber(item?.totalOrder)}</div>
      ),
    },
    {
      title: "Đơn hoàn thành",
      dataIndex: "",
      key: "3",
      render: (item) => (
        <div className="fw-semibold">{item?.totalOrderPayment}</div>
      ),
    },
    {
      title: "Tổng thanh toán",
      dataIndex: "",
      key: "4",
      render: (item) => (
        <div className="fw-semibold text-primary">
          {formatNumber(item?.totalRevenue)}đ
        </div>
      ),
    },
  ];

  return (
    <div className="p-3">
      <div className="fw-semibold fs-4">Danh sách khách mua hàng</div>
      <div className="d-flex gap-2 justify-content-end">
        <DatePicker
          onChange={onChange}
          placeholder="Từ ngày"
          format={"DD-MM-YYYY"}
        />
        <DatePicker
          onChange={onChange}
          placeholder="Đến ngày"
          format={"DD-MM-YYYY"}
        />
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <Table columns={columns} dataSource={topCustomer} />
      )}
    </div>
  );
}
